import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';
import FormInput from '../Form/Input.js';
import FormSelect from '../Form/Select.js';
import FormRadioColor from '../Form/RadioColor';

class Cricchetti extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['cricchetti'];

        this.state = {
            nextStep: 'tipologia_cinghie'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        // if (name.indexOf('[')>0) {
        //     let newName = name.replace(/\[(\w+)]/g, '')
        //     let key = /\[(.*?)\]/g.exec(name)[1]

        //     let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
        //     newValue[key] = value

        //     name = newName
        //     value = newValue
        // }

        if (name==='tipologia_cricchetti') {
            value = [value, '', '']
        }

        if (name==='tipologia_cricchetti_altro') {
            name = 'tipologia_cricchetti'
            value = [this.props.stepsData[this.props.currentType][this.step.id][name][0], this.props.stepsData[this.props.currentType][this.step.id][name][1], '']
        }

        if (name==='certificazione') {
            name = 'tipologia_cricchetti'
            value = [this.props.stepsData[this.props.currentType][this.step.id][name][0], value, '']
        }

        if (name==='colore_cricchetti') {
            value = [value, target.getAttribute('data-hex')]
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    showNumber = () => {
        if (this.props.stepsData[this.props.currentType][this.step.id]['tipologia_cricchetti'][0]!=='Recupera esistenti') {
            return 'item-number col-16 col-lg-8 col-xl-5'
        }
        else {
            return 'item-number col-16 col-lg-8 col-xl-5 d-none'
        }
    }

    showColor = () => {
        if (this.props.stepsData[this.props.currentType][this.step.id]['tipologia_cricchetti'][0]==='A Molla' || this.props.stepsData[this.props.currentType][this.step.id]['tipologia_cricchetti'][0]==='A Scatto') {
            return 'item-color col-8 col-lg-8 col-xl-5'
        }
        else {
            return 'item-color col-8 col-lg-8 col-xl-5 d-none'
        }
    }

    showTypeInput = () => {
        if (this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1]==='Indica tipologia') {
            return ''
        }
        else {
            return 'd-none'
        }
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__cricchetti">
                    <div className="row align-items-center justify-content-center bottom-fields">
                        <div className='item-number col-16 col-lg-8 col-xl-5'>
                            <FormInput type="number" name="numero_cricchetti_sx" id="numero_cricchetti_sx" value={this.props.stepsData[this.props.currentType].cricchetti.numero_cricchetti_sx} label="Numero Cricchetti lato SX" handleChange={this.handleInputChange} />
                        </div>
                        <div className='item-number col-16 col-lg-8 col-xl-5'>
                            <FormInput type="number" name="numero_cricchetti_dx" id="numero_cricchetti_dx" value={this.props.stepsData[this.props.currentType].cricchetti.numero_cricchetti_dx} label="Numero Cricchetti lato DX" handleChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="scrollable-options">
                        <FormRadio name="tipologia_cricchetti" id="tipologia_cricchetti1" value="Recupera esistenti" label="Recupera esistenti" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Recupera esistenti'}>
                            <div>
                                <span className="title">Recupera esistenti</span>
                                <FormSelect type="text" name="tipologia_cricchetti[1]" id="tipologia_cricchetti_esistenti" value={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1]} label="" handleChange={this.handleInputChange}>
                                    <option value="">Seleziona</option>
                                    <option value="Cricchetto Inox C/Cinghia G. Tranc">Cricchetto Inox C/Cinghia G. Tranc</option>
                                    <option value="Cricchetto Inox C/Cinghia C/Sicura">Cricchetto Inox C/Cinghia C/Sicura</option>
                                    <option value="Cricchetto Plastica Rosso C/Cinghia">Cricchetto Plastica Rosso C/Cinghia</option>
                                    <option value="Cricchetto P.Car Inox Diretto">Cricchetto P.Car Inox Diretto</option>
                                    <option value="Indica tipologia">Indica tipologia</option>
                                </FormSelect>
                                <div className={this.showTypeInput()}>
                                    <FormInput type="text" name="tipologia_cricchetti[2]" id="tipologia_cricchetti_altro" value={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[2]} label="Tipologia" handleChange={this.handleInputChange} />
                                </div>
                            </div>
                        </FormRadio>
                        <FormRadio name="tipologia_cricchetti" id="tipologia_cricchetti2" value="A Scatto" label="A Scatto" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'A Scatto'} />
                        <FormRadio name="tipologia_cricchetti" id="tipologia_cricchetti3" value="A Molla" label="A Molla" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'A Molla'}>
                            <div>
                                <span className="title">A Molla</span>
                                <p className="description">EN 12641-2 XL?</p>
                                <FormRadio name="certificazione" id="certificazione1" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'A Molla' && this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1] === 'Sì'} />
                                <FormRadio name="certificazione" id="certificazione2" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'A Molla' && this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1] === 'No'} />
                            </div>
                        </FormRadio>
                        <FormRadio name="tipologia_cricchetti" id="tipologia_cricchetti4" value="Diretto" label="Diretto" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Diretto'}>
                            <div>
                                <span className="title">Diretto</span>
                                <p className="description">EN 12641-2 XL?</p>
                                <FormRadio name="certificazione" id="certificazione3" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Diretto' && this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1] === 'Sì'} />
                                <FormRadio name="certificazione" id="certificazione4" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Diretto' && this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1] === 'No'} />
                            </div>
                        </FormRadio>
                    </div>

                    <div className="row align-items-center justify-content-center bottom-fields">
                        <div className={this.showColor()}>
                            <div className="row align-items-center">
                                <div className="col-24 col-md-auto">
                                    Selez. Colore
                                </div>
                                <div className="col-24 col-md-auto no-form-margin-mobile no-form-margin-desktop">
                                    <FormRadioColor name="colore_cricchetti" id="colore_cricchetti1" value="Nero" label="Nero" hex="#000000" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.colore_cricchetti[0] === 'Nero'} />
                                    <FormRadioColor name="colore_cricchetti" id="colore_cricchetti2" value="Grigio" label="Grigio" hex="#C2C2C2" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.colore_cricchetti[0] === 'Grigio'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateCricchetti = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].cricchetti

    if(!currentStep.tipologia_cricchetti[0]) {
        isValid = false
    }
    else if (currentStep.tipologia_cricchetti[0]==='Recupera esistenti') {
        if(currentStep.tipologia_cricchetti[1]==='Indica tipologia') {
            if(!currentStep.tipologia_cricchetti[2] || !currentStep.tipologia_cricchetti[1] || !currentStep.numero_cricchetti_sx || !currentStep.numero_cricchetti_dx) {
                isValid = false
            }
        }
        else {
            if(!currentStep.tipologia_cricchetti[1] || !currentStep.numero_cricchetti_sx || !currentStep.numero_cricchetti_dx) {
                isValid = false
            }
        }
    }
    else if (currentStep.tipologia_cricchetti[0]!=='Recupera esistenti') {
        if(!currentStep.numero_cricchetti_sx || !currentStep.numero_cricchetti_dx) {
            isValid = false
        }
        else if (currentStep.tipologia_cricchetti[0]==='A Scatto') {
            if(!currentStep.colore_cricchetti[0]) {
                isValid = false
            }
        }
        else if (currentStep.tipologia_cricchetti[0]==='A Molla') {
            if(!currentStep.colore_cricchetti[0] || !currentStep.tipologia_cricchetti[1]) {
                isValid = false
            }
        }
        else {
            if(!currentStep.tipologia_cricchetti[1]) {
                isValid = false
            }
        }
    }

    return isValid;
}

export default withRouter(Cricchetti)
