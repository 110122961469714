import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';
import FormTextarea from '../Form/Textarea';

class Archi extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['archi'];

        this.state = {
            nextStep: 'cinturini'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    printImage = () => {
        if (this.props.stepsData[this.props.currentType].tipologia_tetto.tipologia_tetto==='Tetto scorrevole') {
            return (
                <Image directory="archi" name="tipologia_tetto1" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} />
            )
        }
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__archi">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                {this.printImage()}
                            </div>
                            <div className="step__form">
                                <div className="row justify-content-center">
                                    <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                        <FormInput type="number" name="numero_archi" id="archi_numero_archi" value={this.props.stepsData[this.props.currentType].archi.numero_archi} label="N° Archi" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                        <FormInput type="number" name="passo_archi" id="archi_passo_archi" value={this.props.stepsData[this.props.currentType].archi.passo_archi} label="Passo Archi (mm)" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="w-100 d-none d-xl-block"></div>
                                    <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                        <FormInput type="number" name="distanza_a1" id="archi_distanza_g1" value={this.props.stepsData[this.props.currentType].archi.distanza_a1} label="Dist. 1° Arco (mm)" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                        <FormInput type="number" name="distanza_a2" id="archi_distanza_g2" value={this.props.stepsData[this.props.currentType].archi.distanza_a2} label="Dist. dal 1° a 2° Arco (mm)" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="w-100 d-none d-xl-block"></div>
                                    <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                        <FormInput type="number" name="distanza_a3" id="archi_distanza_g3" value={this.props.stepsData[this.props.currentType].archi.distanza_a3} label="Dist. penultimo Arco (mm)" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                        <FormInput type="number" name="distanza_a4" id="archi_distanza_g4" value={this.props.stepsData[this.props.currentType].archi.distanza_a4} label="Dist. ultimo Arco (mm)" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="w-100 d-none d-xl-block"></div>
                                    <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                        <FormInput type="number" name="rinforzo_ra" id="archi_rinforzo_ra" value={this.props.stepsData[this.props.currentType].archi.rinforzo_ra} label="Rinforzo RA" optional={true} handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                        <FormInput type="number" name="rinforzo_rp" id="archi_rinforzo_rp" value={this.props.stepsData[this.props.currentType].archi.rinforzo_rp} label="Rinforzo RP" optional={true} handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="w-100 d-none d-xl-block"></div>
                                    <div className="col-24 col-md-22 col-lg-20 col-xl-16">
                                        <FormTextarea name="note_archi" id="archi_note_archi" value={this.props.stepsData[this.props.currentType].archi.note_archi} label="Note" placeholder="Segnalare eventuali passo archi non uniformi." optional={true} handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateArchi = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].archi

    if(!currentStep.numero_archi || !currentStep.passo_archi || !currentStep.distanza_a1 || !currentStep.distanza_a2 || !currentStep.distanza_a3 || !currentStep.distanza_a4) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Archi)
