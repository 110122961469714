import React from 'react';
import { Switch, Route, useParams, Redirect, withRouter } from "react-router-dom";
import SimpleStorage from "react-simple-storage";

import Header from './Configurator/Header';
import PrevButton from './Configurator/PrevButton';
import NextButton from './Configurator/NextButton';

import TipologiaMezzo, { ValidateTipologiaMezzo } from './Configurator/TipologiaMezzo';
import DatiCliente, { ValidateDatiCliente, ValidateDatiClienteAccordion } from './Configurator/DatiCliente';
import Teli, { ValidateTeli } from './Configurator/Teli';
import TeliLaterali, { ValidateTeliLaterali } from './Configurator/TeliLaterali';
import CaratteristicheTeli, { ValidateCaratteristicheTeli, ValidateCaratteristicheTeliAccordion } from './Configurator/CaratteristicheTeli';
import MaterialeLaterale, { ValidateMaterialeLaterale, ValidateMaterialeLateraleAccordion } from './Configurator/MaterialeLaterale';
import MisureLaterale, { ValidateMisureLaterale, ValidateMisureLateraleAccordion } from './Configurator/MisureLaterale';
import Elastico, { ValidateElastico } from './Configurator/Elastico';
import Cricchetti, { ValidateCricchetti } from './Configurator/Cricchetti';
import TipologiaCinghie, { ValidateTipologiaCinghie } from './Configurator/TipologiaCinghie';
import Reflex, { ValidateReflex } from './Configurator/Reflex';
import Maniglie, { ValidateManiglie } from './Configurator/Maniglie';
import EsecuzioneIntermodale, { ValidateEsecuzioneIntermodale } from './Configurator/EsecuzioneIntermodale';
import GanciSponda, { ValidateGanciSponda } from './Configurator/GanciSponda';
import TipologiaCarrelli, { ValidateTipologiaCarrelli } from './Configurator/TipologiaCarrelli';
import Tenditelo, { ValidateTenditelo } from './Configurator/Tenditelo';
import ReteAntivandalismo, { ValidateReteAntivandalismo } from './Configurator/ReteAntivandalismo';
import TipologiaTetto, { ValidateTipologiaTetto } from './Configurator/TipologiaTetto';
import DimensioniTetto, { ValidateDimensioniTetto } from './Configurator/DimensioniTetto';
import ColoreTetto, { ValidateColoreTetto } from './Configurator/ColoreTetto';
import Nodo, { ValidateNodo } from './Configurator/Nodo';
import Traversi, { ValidateTraversi } from './Configurator/Traversi';
import Correnti, { ValidateCorrenti } from './Configurator/Correnti';
import Archi, { ValidateArchi } from './Configurator/Archi';
import Cinturini, { ValidateCinturini } from './Configurator/Cinturini';
import FronteTetto, { ValidateFronteTetto } from './Configurator/FronteTetto';
import SpallaTetto, { ValidateSpallaTetto } from './Configurator/SpallaTetto';
import Occhielli, { ValidateOcchielli } from './Configurator/Occhielli';
import Sgocciolatoio, { ValidateSgocciolatoio } from './Configurator/Sgocciolatoio';
import TipologiaPosteriore, { ValidateTipologiaPosteriore } from './Configurator/TipologiaPosteriore';
import Retrotelo, { ValidateRetrotelo } from './Configurator/Retrotelo';
import Retroporte, { ValidateRetroporte } from './Configurator/Retroporte';
import RetroporteA, { ValidateRetroporteA } from './Configurator/RetroporteA';
import RetroporteB, { ValidateRetroporteB } from './Configurator/RetroporteB';
import Opzioni, { ValidateOpzioni } from './Configurator/Opzioni';
import OpzioniProntocar, { ValidateOpzioniProntocar } from './Configurator/OpzioniProntocar';
import OpzioniRollfixPlaneroller, { ValidateOpzioniRollfixPlaneroller } from './Configurator/OpzioniRollfixPlaneroller';
import Note, { ValidateNote } from './Configurator/Note';
import Riepilogo from './Configurator/Riepilogo';
import Thankyou from './Configurator/Thankyou';

class Configurator extends React.Component {
    constructor(props) {
        super(props);

        this.jsTimeout = null

        this.state = {
            steps: {
                tipologia_mezzo: {
                    id: 'tipologia_mezzo',
                    name: 'Seleziona tipologia mezzo',
                    slug: 'tipologia-mezzo',
                    typeInSlug: false,
                    position: 1
                },
                dati_cliente: {
                    id: 'dati_cliente',
                    name: 'Dati',
                    slug: 'dati-cliente',
                    typeInSlug: false,
                    position: 2
                },
                teli: {
                    id: 'teli',
                    name: 'Teli',
                    slug: 'teli',
                    typeInSlug: true,
                    position: 3
                },
                teli_laterali: {
                    id: 'teli_laterali',
                    name: 'Scelta teli laterali',
                    slug: 'teli-laterali',
                    typeInSlug: true,
                    position: 4
                },
                caratteristiche_teli: {
                    id: 'caratteristiche_teli',
                    name: 'Caratteristiche teli',
                    slug: 'caratteristiche-teli',
                    typeInSlug: true,
                    position: 5
                },
                materiale_laterale: {
                    id: 'materiale_laterale',
                    name: 'Materiale laterale',
                    slug: 'materiale-laterale',
                    typeInSlug: true,
                    position: 6
                },
                misure_laterale: {
                    id: 'misure_laterale',
                    name: 'Misure laterale',
                    slug: 'misure-laterale',
                    typeInSlug: true,
                    position: 7
                },
                elastico: {
                    id: 'elastico',
                    name: 'Elastico',
                    slug: 'elastico',
                    typeInSlug: true,
                    position: 8
                },
                cricchetti: {
                    id: 'cricchetti',
                    name: 'Tipologia Cricchetti',
                    slug: 'cricchetti',
                    typeInSlug: true,
                    position: 9
                },
                tipologia_cinghie: {
                    id: 'tipologia_cinghie',
                    name: 'Tipologia Cinghie',
                    slug: 'tipologia-cinghie',
                    typeInSlug: true,
                    position: 10
                },
                reflex: {
                    id: 'reflex',
                    name: 'Reflex',
                    slug: 'reflex',
                    typeInSlug: true,
                    position: 11
                },
                maniglie: {
                    id: 'maniglie',
                    name: 'Maniglie',
                    slug: 'maniglie',
                    typeInSlug: true,
                    position: 12
                },
                esecuzione_intermodale: {
                    id: 'esecuzione_intermodale',
                    name: 'Esecuzione intermodale',
                    slug: 'esecuzione-intermodale',
                    typeInSlug: true,
                    position: 13
                },
                ganci_sponda: {
                    id: 'ganci_sponda',
                    name: 'Ganci sponda',
                    slug: 'ganci-sponda',
                    typeInSlug: true,
                    position: 14
                },
                tipologia_carrelli: {
                    id: 'tipologia_carrelli',
                    name: 'Tipologia e numero Carrelli',
                    slug: 'tipologia-carrelli',
                    typeInSlug: true,
                    position: 16
                },
                tenditelo: {
                    id: 'tenditelo',
                    name: 'Tenditelo',
                    slug: 'tenditelo',
                    typeInSlug: true,
                    position: 17
                },
                rete_antivandalismo: {
                    id: 'rete_antivandalismo',
                    name: 'Rete antivandalismo',
                    slug: 'rete-antivandalismo',
                    typeInSlug: true,
                    position: 18
                },
                tipologia_tetto: {
                    id: 'tipologia_tetto',
                    name: 'Tipologia Tetto',
                    slug: 'tipologia-tetto',
                    typeInSlug: true,
                    position: 19,
                    position2: 4
                },
                dimensioni_tetto: {
                    id: 'dimensioni_tetto',
                    name: 'Dimensioni Tetto',
                    slug: 'dimensioni-tetto',
                    typeInSlug: true,
                    position: 20,
                    position2: 5
                },
                colore_tetto: {
                    id: 'colore_tetto',
                    name: 'Colore Tetto/Spalla',
                    slug: 'colore-tetto',
                    typeInSlug: true,
                    position: 21,
                    position2: 6
                },
                nodo: {
                    id: 'nodo',
                    name: 'Tipologia telo Tetto',
                    slug: 'nodo',
                    typeInSlug: true,
                    position: 22,
                    position2: 7
                },
                traversi: {
                    id: 'traversi',
                    name: 'Traversi',
                    slug: 'traversi',
                    typeInSlug: true,
                    position: 23,
                    position2: 8
                },
                correnti: {
                    id: 'correnti',
                    name: 'Correnti',
                    slug: 'correnti',
                    typeInSlug: true,
                    position: 24,
                    position2: 9
                },
                archi: {
                    id: 'archi',
                    name: 'Archi',
                    slug: 'archi',
                    typeInSlug: true,
                    position: 23,
                    position2: 8
                },
                cinturini: {
                    id: 'cinturini',
                    name: 'Numero File Cinturini',
                    slug: 'cinturini',
                    typeInSlug: true,
                    position: 24,
                    position2: 9
                },
                fronte_tetto: {
                    id: 'fronte_tetto',
                    name: 'Fronte Tetto',
                    slug: 'fronte-tetto',
                    typeInSlug: true,
                    position: 25,
                    position2: 10
                },
                occhielli: {
                    id: 'occhielli',
                    name: 'Occhielli',
                    slug: 'occhielli',
                    typeInSlug: true,
                    position: 26,
                    position2: 11
                },
                spalla_tetto: {
                    id: 'spalla_tetto',
                    name: 'Spalla Tetto',
                    slug: 'spalla-tetto',
                    typeInSlug: true,
                    position: 27,
                    position2: 12
                },
                sgocciolatoio: {
                    id: 'sgocciolatoio',
                    name: 'Sgocciolatoio',
                    slug: 'sgocciolatoio',
                    typeInSlug: true,
                    position: 28,
                    position2: 13
                },
                tipologia_posteriore: {
                    id: 'tipologia_posteriore',
                    name: 'Tipologia posteriore',
                    slug: 'tipologia-posteriore',
                    typeInSlug: false,
                    position: 29,
                    position2: 14
                },
                retrotelo: {
                    id: 'retrotelo',
                    name: 'Retrotelo',
                    slug: 'retrotelo',
                    typeInSlug: false,
                    position: 30,
                    position2: 15
                },
                retroporte: {
                    id: 'retroporte',
                    name: 'Retro Porte',
                    slug: 'retroporte',
                    typeInSlug: false,
                    position: 31,
                    position2: 16
                },
                retroporte_a: {
                    id: 'retroporte_a',
                    name: 'Retro Porte A',
                    slug: 'retroporte-a',
                    typeInSlug: false,
                    position: 32,
                    position2: 17
                },
                retroporte_b: {
                    id: 'retroporte_b',
                    name: 'Retro Porte B',
                    slug: 'retroporte-b',
                    typeInSlug: false,
                    position: 32,
                    position2: 17
                },
                opzioni: {
                    id: 'opzioni',
                    name: 'Opzioni',
                    slug: 'opzioni',
                    typeInSlug: false,
                    position: 33,
                    position2: 18
                },
                opzioni_prontocar: {
                    id: 'opzioni_prontocar',
                    name: 'Opzioni Materiali Prontocar',
                    slug: 'opzioni-prontocar',
                    typeInSlug: false,
                    position: 34,
                    position2: 19
                },
                opzioni_rollfix_planeroller: {
                    id: 'opzioni_rollfix_planeroller',
                    name: 'Opzioni Materiali Rollfix e Planeroller',
                    slug: 'opzioni-rollfix-planeroller',
                    typeInSlug: false,
                    position: 35,
                    position2: 20
                },
                note: {
                    id: 'note',
                    name: 'Note Aggiuntive',
                    slug: 'note',
                    typeInSlug: false,
                    position: 36,
                    position2: 21
                }
            },
            stepsData: this._initialStepsData(),
            currentStep: {
                id: '',
                name: '',
                prev: '',
                next: '',
                substep: null,
                subSteps: 0
            },
            currentType: '',
            stepsCount: 37,
            stepsValidation: {},
            stepsAccordionValidation: {}
        };
    }

    _initialStepsData = () => {
        return {
            tipologia_mezzo: {
                tipo_mezzo: ''
            },
            dati_cliente: {
                dati_principali: {
                    azienda: '',
                    referente: '',
                    telefono: ''
                },
                dati_mezzo: {
                    targa: '',
                    marca: '',
                    modello: '',
                    anno: ''
                },
                dati_lavorazione: {
                    montaggio: '',
                    interno_ubicazione_p: '',
                    esterno: '',
                    data_consegna: null
                }
            },
            tipologia_posteriore: {
                tipologia_posteriore: ''
            },
            retrotelo: {
                la: '',
                h: ''
            },
            retroporte: {
                retroporte: ''
            },
            retroporte_a: {
                la: '',
                l1: '',
                l2: '',
                l3: '',
                h: ''
            },
            retroporte_b: {
                l1: '',
                l2: '',
                h: ''
            },
            opzioni: {
                opt_1: '',
                opt_2: '',
                opt_3: '',
                opt_4: '',
                opt_5: '',
                opt_6: '',
                opt_7: '',
                opt_8: '',
                opt_9: '',
                opt_10: '',
                opt_11: '',
                opt_12: '',
                opt_13: '',
                opt_14: '',
                opt_15: '',
                opt_16: '',
                opt_17: '',
                opt_18: '',
                opt_19: '',
                opt_20: '',
                opt_21: '',
                opt_22: '',
                opt_23: '',
                opt_24: '',
                opt_25: '',
                opt_26: '',
                opt_27: '',
                opt_28: '',
                opt_29: '',
                opt_30: '',
                opt_31: '',
                opt_32: '',
                opt_33: '',
                opt_34: '',
                opt_35: '',
                opt_36: '',
                opt_37: '',
                opt_38: '',
                opt_39: '',
                opt_40: '',
                opt_41: '',
                opt_42: '',
                opt_43: '',
                opt_44: '',
                opt_45: '',
                opt_46: ''
            },
            opzioni_prontocar: {
                opt_1: '',
                opt_2: '',
                opt_3: '',
                opt_4: '',
                opt_5: '',
                opt_6: '',
                opt_7: '',
                opt_8: '',
                opt_9: '',
                opt_10: '',
                opt_11: '',
                opt_12: '',
                opt_13: '',
                opt_14: '',
                opt_15: '',
                opt_16: '',
                opt_17: '',
                opt_18: '',
                opt_19: '',
                opt_20: '',
                opt_21: '',
                opt_22: '',
                opt_23: '',
                opt_24: '',
                opt_25: '',
                opt_26: '',
                opt_27: '',
                opt_28: '',
                opt_29: '',
                opt_30: '',
                opt_31: ''
            },
            opzioni_rollfix_planeroller: {
                opt_1: '',
                opt_2: '',
                opt_3: '',
                opt_4: '',
                opt_5: '',
                opt_6: '',
                opt_7: '',
                opt_8: '',
                opt_9: '',
                opt_10: '',
                opt_11: '',
                opt_12: '',
                opt_13: '',
                opt_14: '',
                opt_15: '',
                opt_16: ''
            },
            note: {
                note_aggiuntive: '',
                altro: ''
            },
            semirimorchio: {
                teli: {
                    teli: ''
                },
                teli_laterali: {
                    teli_laterali: ['', ''],
                },
                caratteristiche_teli: {
                    teloni: {
                        quantita_sx: '',
                        quantita_dx: ''
                    },
                    stampa: {
                        logo: '',
                        logo_note: ''
                    }
                },
                materiale_laterale: {
                    materiale: {
                        mareriale: '',
                        tipologia: ''
                    },
                    colore: {
                        colore_fascia: '',
                        colore_telo: ''
                    }
                },
                misure_laterale: {
                    altezza: {
                        h1: '',
                        h2: '',
                        h3: '',
                        lu: '',
                        altezza_variabile: '',
                        sponda_dente: '',
                        quote_scansi_sx: '',
                        quote_scansi_dx: ''
                    },
                    numero_piantoni: {
                        numero_piantoni: ''
                    },
                    interasse_piantone: ['', '', '', '', '', '', '', ''],
                    rinforzi: {
                        ra: '',
                        rp: '',
                    },
                    carrelli: {
                        cr1: '',
                        cr2: '',
                    },
                    cricchetti: {
                        passo: '',
                        distanza_c1: '',
                        distanza_c2: ''
                    }
                },
                elastico: {
                    elastico: ''
                },
                cricchetti: {
                    tipologia_cricchetti: ['', '', ''],
                    numero_cricchetti_sx: '',
                    numero_cricchetti_dx: '',
                    colore_cricchetti: ['', '']
                },
                tipologia_cinghie: {
                    numero_cinghie_orizzontali: '',
                    cinghie_verticali_en: '',
                    cinghie_oblique_xl: ''
                },
                reflex: {
                    reflex: ['', '']
                },
                maniglie: {
                    maniglie: ''
                },
                esecuzione_intermodale: {
                    esecuzione_intermodale: '',
                    ta: '',
                    tp: ''
                },
                ganci_sponda: {
                    piantone: [
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        }
                    ]
                },
                tipologia_carrelli: {
                    tipologia_carrelli: '',
                    tipologia_carrelli_esistenti: ['', ''],
                    numero_carrelli_sx: '',
                    numero_carrelli_dx: '',
                    carrelli_particolari: ''
                },
                tenditelo: {
                    tenditelo_anteriore: '',
                    tenditelo_posteriore: '',
                    tipologia_anteriore: '',
                    tipologia_posteriore: '',
                    tenditelo_anteriore_recupera: '',
                    tenditelo_posteriore_recupera: ''
                },
                rete_antivandalismo: {
                    rete_antivandalismo: '',
                    griglia: '',
                    altezza: ''
                },
                tipologia_tetto: {
                    tipologia_tetto: '',
                    fasce_spalmate: ''
                },
                dimensioni_tetto: {
                    lunghezza: '',
                    larghezza: ''
                },
                colore_tetto: {
                    colore_tetto: '',
                    colore_tetto_colore: '',
                },
                nodo: {
                    nodo: '',
                },
                traversi: {
                    numero_traversi: '',
                    passo_traversi: []
                },
                correnti: {
                    numero_correnti: '',
                    passo_correnti: []
                },
                archi: {
                    numero_archi: '',
                    passo_archi: '',
                    distanza_a1: '',
                    distanza_a2: '',
                    distanza_a3: '',
                    distanza_a4: '',
                    note_archi: '',
                    rinforzo_ra: '',
                    rinforzo_rp: ''
                },
                cinturini: {
                    file_cinturini: ''
                },
                fronte_tetto: {
                    altezza_frontale: '',
                    scansi_anteriori_doppi: '',
                    no_fronte_tetto: ''
                },
                spalla_tetto: {
                    d: '',
                    e: '',
                    f: '',
                    g: '',
                    g1g2: ''
                },
                occhielli: {
                    numero: '',
                    disposizione: '',
                    quota: '',
                    interasse: '',
                    passo_ultimo: '',
                    no_occhielli: ''
                },
                sgocciolatoio: {
                    sgocciolatoio: ''
                }
            },
            motrice: {
                teli: {
                    teli: ''
                },
                teli_laterali: {
                    teli_laterali: ['', ''],
                },
                caratteristiche_teli: {
                    teloni: {
                        quantita_sx: '',
                        quantita_dx: ''
                    },
                    stampa: {
                        logo: '',
                        logo_note: ''
                    }
                },
                materiale_laterale: {
                    materiale: {
                        mareriale: '',
                        tipologia: ''
                    },
                    colore: {
                        colore_fascia: '',
                        colore_telo: ''
                    }
                },
                misure_laterale: {
                    altezza: {
                        h1: '',
                        h2: '',
                        h3: '',
                        lu: '',
                        altezza_variabile: '',
                        sponda_dente: '',
                        quote_scansi_sx: '',
                        quote_scansi_dx: ''
                    },
                    numero_piantoni: {
                        numero_piantoni: ''
                    },
                    interasse_piantone: ['', '', '', '', '', '', '', ''],
                    rinforzi: {
                        ra: '',
                        rp: '',
                    },
                    carrelli: {
                        cr1: '',
                        cr2: '',
                    },
                    cricchetti: {
                        passo: '',
                        distanza_c1: '',
                        distanza_c2: ''
                    }
                },
                elastico: {
                    elastico: ''
                },
                cricchetti: {
                    tipologia_cricchetti: ['', '', ''],
                    numero_cricchetti_sx: '',
                    numero_cricchetti_dx: '',
                    colore_cricchetti: ['', '']
                },
                tipologia_cinghie: {
                    numero_cinghie_orizzontali: '',
                    cinghie_verticali_en: '',
                    cinghie_oblique_xl: ''
                },
                reflex: {
                    reflex: ['', '']
                },
                maniglie: {
                    maniglie: ''
                },
                esecuzione_intermodale: {
                    esecuzione_intermodale: '',
                    ta: '',
                    tp: ''
                },
                ganci_sponda: {
                    piantone: [
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        }
                    ]
                },
                tipologia_carrelli: {
                    tipologia_carrelli: '',
                    tipologia_carrelli_esistenti: ['', ''],
                    numero_carrelli_sx: '',
                    numero_carrelli_dx: '',
                    carrelli_particolari: ''
                },
                tenditelo: {
                    tenditelo_anteriore: '',
                    tenditelo_posteriore: '',
                    tipologia_anteriore: '',
                    tipologia_posteriore: '',
                    tenditelo_anteriore_recupera: '',
                    tenditelo_posteriore_recupera: ''
                },
                rete_antivandalismo: {
                    rete_antivandalismo: '',
                    griglia: '',
                    altezza: ''
                },
                tipologia_tetto: {
                    tipologia_tetto: '',
                    fasce_spalmate: ''
                },
                dimensioni_tetto: {
                    lunghezza: '',
                    larghezza: ''
                },
                colore_tetto: {
                    colore_tetto: '',
                    colore_tetto_colore: '',
                },
                nodo: {
                    nodo: '',
                },
                traversi: {
                    numero_traversi: '',
                    passo_traversi: []
                },
                correnti: {
                    numero_correnti: '',
                    passo_correnti: []
                },
                archi: {
                    numero_archi: '',
                    passo_archi: '',
                    distanza_a1: '',
                    distanza_a2: '',
                    distanza_a3: '',
                    distanza_a4: '',
                    note_archi: '',
                    rinforzo_ra: '',
                    rinforzo_rp: ''
                },
                cinturini: {
                    file_cinturini: ''
                },
                fronte_tetto: {
                    altezza_frontale: '',
                    scansi_anteriori_doppi: '',
                    no_fronte_tetto: ''
                },
                spalla_tetto: {
                    d: '',
                    e: '',
                    f: '',
                    g: '',
                    g1g2: ''
                },
                occhielli: {
                    numero: '',
                    disposizione: '',
                    quota: '',
                    interasse: '',
                    passo_ultimo: '',
                    no_occhielli: ''
                },
                sgocciolatoio: {
                    sgocciolatoio: ''
                }
            },
            rimorchio: {
                teli: {
                    teli: ''
                },
                teli_laterali: {
                    teli_laterali: ['', ''],
                },
                caratteristiche_teli: {
                    teloni: {
                        quantita_sx: '',
                        quantita_dx: ''
                    },
                    stampa: {
                        logo: '',
                        logo_note: ''
                    }
                },
                materiale_laterale: {
                    materiale: {
                        mareriale: '',
                        tipologia: ''
                    },
                    colore: {
                        colore_fascia: '',
                        colore_telo: ''
                    }
                },
                misure_laterale: {
                    altezza: {
                        h1: '',
                        h2: '',
                        h3: '',
                        lu: '',
                        altezza_variabile: '',
                        sponda_dente: '',
                        quote_scansi_sx: '',
                        quote_scansi_dx: ''
                    },
                    numero_piantoni: {
                        numero_piantoni: ''
                    },
                    interasse_piantone: ['', '', '', '', '', '', '', ''],
                    rinforzi: {
                        ra: '',
                        rp: '',
                    },
                    carrelli: {
                        cr1: '',
                        cr2: '',
                    },
                    cricchetti: {
                        passo: '',
                        distanza_c1: '',
                        distanza_c2: ''
                    }
                },
                elastico: {
                    elastico: ''
                },
                cricchetti: {
                    tipologia_cricchetti: ['', '', ''],
                    numero_cricchetti_sx: '',
                    numero_cricchetti_dx: '',
                    colore_cricchetti: ['', '']
                },
                tipologia_cinghie: {
                    numero_cinghie_orizzontali: '',
                    cinghie_verticali_en: '',
                    cinghie_oblique_xl: ''
                },
                reflex: {
                    reflex: ['', '']
                },
                maniglie: {
                    maniglie: ''
                },
                esecuzione_intermodale: {
                    esecuzione_intermodale: '',
                    ta: '',
                    tp: ''
                },
                ganci_sponda: {
                    piantone: [
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        },
                        {
                            numero_ganci: '',
                            passo_ganci: '',
                            distanza_g1: '',
                            distanza_g2: '',
                        }
                    ]
                },
                tipologia_carrelli: {
                    tipologia_carrelli: '',
                    tipologia_carrelli_esistenti: ['', ''],
                    numero_carrelli_sx: '',
                    numero_carrelli_dx: '',
                    carrelli_particolari: ''
                },
                tenditelo: {
                    tenditelo_anteriore: '',
                    tenditelo_posteriore: '',
                    tipologia_anteriore: '',
                    tipologia_posteriore: '',
                    tenditelo_anteriore_recupera: '',
                    tenditelo_posteriore_recupera: ''
                },
                rete_antivandalismo: {
                    rete_antivandalismo: '',
                    griglia: '',
                    altezza: ''
                },
                tipologia_tetto: {
                    tipologia_tetto: '',
                    fasce_spalmate: ''
                },
                dimensioni_tetto: {
                    lunghezza: '',
                    larghezza: ''
                },
                colore_tetto: {
                    colore_tetto: '',
                    colore_tetto_colore: '',
                },
                nodo: {
                    nodo: '',
                },
                traversi: {
                    numero_traversi: '',
                    passo_traversi: []
                },
                correnti: {
                    numero_correnti: '',
                    passo_correnti: []
                },
                archi: {
                    numero_archi: '',
                    passo_archi: '',
                    distanza_a1: '',
                    distanza_a2: '',
                    distanza_a3: '',
                    distanza_a4: '',
                    note_archi: '',
                    rinforzo_ra: '',
                    rinforzo_rp: ''
                },
                cinturini: {
                    file_cinturini: ''
                },
                fronte_tetto: {
                    altezza_frontale: '',
                    scansi_anteriori_doppi: '',
                    no_fronte_tetto: ''
                },
                spalla_tetto: {
                    d: '',
                    e: '',
                    f: '',
                    g: '',
                    g1g2: ''
                },
                occhielli: {
                    numero: '',
                    disposizione: '',
                    quota: '',
                    interasse: '',
                    passo_ultimo: '',
                    no_occhielli: ''
                },
                sgocciolatoio: {
                    sgocciolatoio: ''
                }
            }
        }
    }

    updateStep = (stepId, stepName, nextStep, type = null, substep = null, subSteps = 0) => {
        let prevStep = this.state.currentStep.id !== stepId ? this.state.currentStep.id : null

        if (!type) {
            type = this.state.currentType
        }

        this.setState({
            currentStep: {
                id: stepId,
                name: stepName,
                prev: prevStep,
                next: nextStep,
                substep: substep,
                subSteps: subSteps
            },
            currentType: type
        }, () => {
            this.validateStep(stepId)
        })
    };

    updateData = (type, step, name = null, value = null, object = null) => {
        let field = name

        if (name) {
            if (name.indexOf('[')>0) {
                let newName = name.replace(/\[(\w+)]/g, '')
                let newValue

                if (this.state.stepsData[step]) {
                    newValue = this.state.stepsData[step][newName]
                }
                else {
                    newValue = this.state.stepsData[type][step][newName]
                }

                newValue[/\[(.*?)\]/g.exec(name)[1]] = value

                field = name
                name = newName
                value = newValue
            }

            if (type) {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [type]: {
                            ...this.state.stepsData[type],
                            [step]:
                            {
                                ...this.state.stepsData[type][step],
                                [name]: value
                            }
                        }
                    }
                }, () => {
                    this.validateStep(step, field)
                })
            }
            else {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [step]:
                            {
                                ...this.state.stepsData[step],
                                [name]: value
                            }
                    }
                }, () => {
                    this.validateStep(step, field)
                })
            }
        }
        else if (object) {
            if (type) {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [type]: {
                            ...this.state.stepsData[type],
                            [step]:
                            {
                                ...this.state.stepsData[type][step],
                                ...object
                            }
                        }
                    }
                }, () => {
                    this.validateStep(step, field)
                })
            }
            else {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [step]:
                        {
                            ...this.state.stepsData[step],
                            ...object
                        }
                    }
                }, () => {
                    this.validateStep(step, field)
                })
            }
        }
    };

    validateStep = (step, field = null) => {
        if (step==='tipologia_mezzo') {
            let validation = ValidateTipologiaMezzo(this.state.stepsData, this.state.currentStep)
            let truckType = ''

            if (validation) {
                if (this.state.stepsData.tipologia_mezzo.tipo_mezzo==='Semirimorchio') {
                    truckType = 'semirimorchio'
                }
                else if (this.state.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice' || this.state.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
                    truckType = 'motrice'
                }

                if (truckType!==this.state.currentType) {
                    this.setState({
                        stepsData: {
                            ...this.state.stepsData,
                            semirimorchio: {
                                ...this._initialStepsData().semirimorchio,
                            },
                            motrice: {
                                ...this._initialStepsData().motrice,
                            },
                            rimorchio: {
                                ...this._initialStepsData().rimorchio,
                            }
                        }
                    })
                }
            }

            this.updateValidation(step, validation, truckType)
        }
        else if (step==='dati_cliente') {
            let validation = ValidateDatiCliente(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation, '', ValidateDatiClienteAccordion(this.state.stepsData, this.state.currentStep, this.state.currentType))

            if (validation && this.state.stepsData.dati_cliente.dati_lavorazione.montaggio !== 'Sì') {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        dati_cliente: {
                            ...this.state.stepsData.dati_cliente,
                            dati_lavorazione: {
                                ...this.state.stepsData.dati_cliente.dati_lavorazione,
                                interno_ubicazione_p: this._initialStepsData().dati_cliente.dati_lavorazione.interno_ubicazione_p,
                                esterno: this._initialStepsData().dati_cliente.dati_lavorazione.esterno
                            }
                        }
                    }
                })
            }
        }
        else if (step==='teli') {
            let validation = ValidateTeli(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation && this.state.stepsData[this.state.currentType]) {
                if (this.state.stepsData[this.state.currentType].teli.teli==='Telo tetto') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                teli_laterali: this._initialStepsData()[this.state.currentType].teli_laterali,
                                caratteristiche_teli: this._initialStepsData()[this.state.currentType].caratteristiche_teli,
                                materiale_laterale: this._initialStepsData()[this.state.currentType].materiale_laterale,
                                misure_laterale: this._initialStepsData()[this.state.currentType].misure_laterale,
                                elastico: this._initialStepsData()[this.state.currentType].elastico,
                                cricchetti: this._initialStepsData()[this.state.currentType].cricchetti,
                                tipologia_cinghie: this._initialStepsData()[this.state.currentType].tipologia_cinghie,
                                reflex: this._initialStepsData()[this.state.currentType].reflex,
                                maniglie: this._initialStepsData()[this.state.currentType].maniglie,
                                esecuzione_intermodale: this._initialStepsData()[this.state.currentType].esecuzione_intermodale,
                                ganci_sponda: this._initialStepsData()[this.state.currentType].ganci_sponda,
                                tipologia_carrelli: this._initialStepsData()[this.state.currentType].tipologia_carrelli,
                                tenditelo: this._initialStepsData()[this.state.currentType].tenditelo,
                                rete_antivandalismo: this._initialStepsData()[this.state.currentType].rete_antivandalismo
                            }
                        },
                        stepsCount: 22
                    })
                }
                else if(this.state.stepsData[this.state.currentType].teli.teli==='Teli laterali') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                tipologia_tetto: this._initialStepsData()[this.state.currentType].tipologia_tetto,
                                dimensioni_tetto: this._initialStepsData()[this.state.currentType].dimensioni_tetto,
                                colore_tetto: this._initialStepsData()[this.state.currentType].colore_tetto,
                                nodo: this._initialStepsData()[this.state.currentType].nodo,
                                traversi: this._initialStepsData()[this.state.currentType].traversi,
                                correnti: this._initialStepsData()[this.state.currentType].correnti,
                                archi: this._initialStepsData()[this.state.currentType].archi,
                                cinturini: this._initialStepsData()[this.state.currentType].cinturini,
                                fronte_tetto: this._initialStepsData()[this.state.currentType].fronte_tetto,
                                spalla_tetto: this._initialStepsData()[this.state.currentType].spalla_tetto,
                                occhielli: this._initialStepsData()[this.state.currentType].occhielli,
                                sgocciolatoio: this._initialStepsData()[this.state.currentType].sgocciolatoio
                            }
                        },
                        stepsCount: 26
                    })
                }
                else {
                    this.setState({
                        stepsCount: 37
                    })
                }
            }
        }
        else if (step==='teli_laterali') {
            let validation = ValidateTeliLaterali(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation) {
                if (this.state.stepsData[this.state.currentType].teli_laterali.teli_laterali[0]==='Francese') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                misure_laterale: this._initialStepsData()[this.state.currentType].misure_laterale,
                                elastico: this._initialStepsData()[this.state.currentType].elastico,
                                ganci_sponda: this._initialStepsData()[this.state.currentType].ganci_sponda,
                                tipologia_carrelli: this._initialStepsData()[this.state.currentType].tipologia_carrelli
                            }
                        }
                    })
                }
                else {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                misure_laterale: this._initialStepsData()[this.state.currentType].misure_laterale,
                                cricchetti: this._initialStepsData()[this.state.currentType].cricchetti,
                                tipologia_cinghie: this._initialStepsData()[this.state.currentType].tipologia_cinghie,
                                reflex: this._initialStepsData()[this.state.currentType].reflex,
                                maniglie: this._initialStepsData()[this.state.currentType].maniglie,
                                esecuzione_intermodale: this._initialStepsData()[this.state.currentType].esecuzione_intermodale,
                                tipologia_carrelli: this._initialStepsData()[this.state.currentType].tipologia_carrelli
                            }
                        }
                    })
                }
            }
        }
        else if (step==='caratteristiche_teli') {
            this.updateValidation(step, ValidateCaratteristicheTeli(this.state.stepsData, this.state.currentStep, this.state.currentType), '', ValidateCaratteristicheTeliAccordion(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='materiale_laterale') {
            this.updateValidation(step, ValidateMaterialeLaterale(this.state.stepsData, this.state.currentStep, this.state.currentType), '', ValidateMaterialeLateraleAccordion(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='misure_laterale') {
            let validation = ValidateMisureLaterale(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation, '', ValidateMisureLateraleAccordion(this.state.stepsData, this.state.currentStep, this.state.currentType))

            let self = this
            
            clearTimeout(this.jsTimeout)
            this.jsTimeout = setTimeout(function(){

                let distanza_c1 = self.state.stepsData[self.state.currentType].misure_laterale.cricchetti.distanza_c1
                if ((field==='carrelli[cr1]' || field==='carrelli[cr2]') && distanza_c1==='') {
                    distanza_c1 = self.state.stepsData[self.state.currentType].misure_laterale.carrelli.cr1
                }

                let distanza_c2 = self.state.stepsData[self.state.currentType].misure_laterale.cricchetti.distanza_c2
                if ((field==='carrelli[cr1]' || field==='carrelli[cr2]') && distanza_c2==='') {
                    distanza_c2 = self.state.stepsData[self.state.currentType].misure_laterale.carrelli.cr2
                }

                let interasse_piantone = []
                if (parseInt(self.state.stepsData[self.state.currentType].misure_laterale.numero_piantoni.numero_piantoni)>0) {
                    for (let i=0; i<=self.state.stepsData[self.state.currentType].misure_laterale.numero_piantoni.numero_piantoni; i++) {
                        interasse_piantone.push(self.state.stepsData[self.state.currentType].misure_laterale.interasse_piantone[i])
                    }
                    for (let i=parseInt(self.state.stepsData[self.state.currentType].misure_laterale.numero_piantoni.numero_piantoni)+1; i<5; i++) {
                        interasse_piantone.push('')
                    }
                }
                else {
                    interasse_piantone = ['','','','','','','','']
                }

                self.setState({
                    stepsData:
                    {
                        ...self.state.stepsData,
                        [self.state.currentType]: {
                            ...self.state.stepsData[self.state.currentType],
                            misure_laterale: {
                                ...self.state.stepsData[self.state.currentType].misure_laterale,
                                interasse_piantone: interasse_piantone,
                                cricchetti: {
                                    ...self.state.stepsData[self.state.currentType].misure_laterale.cricchetti,
                                    distanza_c1: distanza_c1,
                                    distanza_c2: distanza_c2
                                }
                            },
                            ganci_sponda: self._initialStepsData()[self.state.currentType].ganci_sponda
                        }
                    }
                }, () => {
                    if (field==='carrelli[cr2]' || field==='carrelli[cr2]') {
                        let validation = ValidateMisureLaterale(self.state.stepsData, self.state.currentStep, self.state.currentType)
                        self.updateValidation(step, validation, '', ValidateMisureLateraleAccordion(self.state.stepsData, self.state.currentStep, self.state.currentType))
                    }
                })
            }, 2000)
        }
        else if (step==='elastico') {
            this.updateValidation(step, ValidateElastico(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='cricchetti') {
            let validation = ValidateCricchetti(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation) {
                if (this.state.stepsData[this.state.currentType].cricchetti.tipologia_cricchetti[0]==='Recupera esistenti') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                cricchetti: {
                                    ...this.state.stepsData[this.state.currentType].cricchetti,
                                    // numero_cricchetti: '',
                                    colore_cricchetti: ['', '']
                                },
                                tipologia_carrelli: {
                                    ...this.state.stepsData[this.state.currentType].tipologia_carrelli,
                                    numero_carrelli: this.state.stepsData[this.state.currentType].cricchetti.numero_cricchetti
                                }
                            }
                        }
                    })
                }
                else if(this.state.stepsData[this.state.currentType].cricchetti.tipologia_cricchetti[0]==='Diretto Scatto') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                cricchetti: {
                                    ...this.state.stepsData[this.state.currentType].cricchetti,
                                    colore_cricchetti: ['', '']
                                },
                                tipologia_carrelli: {
                                    ...this.state.stepsData[this.state.currentType].tipologia_carrelli,
                                    numero_carrelli: this.state.stepsData[this.state.currentType].cricchetti.numero_cricchetti
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='tipologia_cinghie') {
            this.updateValidation(step, ValidateTipologiaCinghie(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='reflex') {
            this.updateValidation(step, ValidateReflex(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='maniglie') {
            this.updateValidation(step, ValidateManiglie(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='esecuzione_intermodale') {
            let validation = ValidateEsecuzioneIntermodale(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation) {
                if (this.state.stepsData[this.state.currentType].esecuzione_intermodale.esecuzione_intermodale === 'No') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                esecuzione_intermodale: {
                                    ...this.state.stepsData[this.state.currentType].esecuzione_intermodale,
                                    ta: '',
                                    tp: ''
                                }
                            }
                        }
                    })
                }
                else {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                esecuzione_intermodale: {
                                    ...this.state.stepsData[this.state.currentType].esecuzione_intermodale
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='ganci_sponda') {
            this.updateValidation(step, ValidateGanciSponda(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='tipologia_carrelli') {
            let validation = ValidateTipologiaCarrelli(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation) {
                if (this.state.stepsData[this.state.currentType].tipologia_carrelli.tipologia_carrelli==='Recupero carrello esistente') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                tipologia_carrelli: {
                                    ...this.state.stepsData[this.state.currentType].tipologia_carrelli
                                }
                            }
                        }
                    })
                }
                else {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                tipologia_carrelli: {
                                    ...this.state.stepsData[this.state.currentType].tipologia_carrelli,
                                    tipologia_carrelli_esistenti: ['', '']
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='tenditelo') {
            let validate = ValidateTenditelo(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                let tipologia_anteriore = this.state.stepsData[this.state.currentType].tenditelo.tipologia_anteriore
                if (this.state.stepsData[this.state.currentType].tenditelo.tenditelo_anteriore!=='Asta Tenditelo' && this.state.stepsData[this.state.currentType].tenditelo.tenditelo_anteriore!=='Profilo Tensotel TN' && this.state.stepsData[this.state.currentType].tenditelo.tenditelo_anteriore!=='Profilo Tensotel TV') {
                    tipologia_anteriore = ''
                }

                let tipologia_posteriore = this.state.stepsData[this.state.currentType].tenditelo.tipologia_posteriore
                if (this.state.stepsData[this.state.currentType].tenditelo.tenditelo_posteriore!=='Asta Tenditelo' && this.state.stepsData[this.state.currentType].tenditelo.tenditelo_posteriore!=='Profilo Tensotel TN' && this.state.stepsData[this.state.currentType].tenditelo.tenditelo_posteriore!=='Profilo Tensotel TV') {
                    tipologia_posteriore = ''
                }

                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [this.state.currentType]: {
                            ...this.state.stepsData[this.state.currentType],
                            tenditelo: {
                                ...this.state.stepsData[this.state.currentType].tenditelo,
                                tipologia_anteriore: tipologia_anteriore,
                                tipologia_posteriore: tipologia_posteriore
                            }
                        }
                    }
                })
            }
        }
        else if (step==='rete_antivandalismo') {
            let validate = ValidateReteAntivandalismo(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                if (this.state.stepsData[this.state.currentType].rete_antivandalismo.rete_antivandalismo!=='Sì') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                rete_antivandalismo: {
                                    ...this.state.stepsData[this.state.currentType].rete_antivandalismo,
                                    griglia: '',
                                    altezza: ''
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='tipologia_tetto') {
            let validate = ValidateTipologiaTetto(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                if (this.state.stepsData[this.state.currentType].tipologia_tetto.tipologia_tetto==='Tetto fisso') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                tipologia_tetto: {
                                    ...this.state.stepsData[this.state.currentType].tipologia_tetto,
                                    fasce_spalmate: ''
                                },
                                archi: this._initialStepsData()[this.state.currentType].archi,
                                cintiruni: this._initialStepsData()[this.state.currentType].cintiruni
                            }
                        }
                    })
                }
                else {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                traversi: this._initialStepsData()[this.state.currentType].traversi,
                                correnti: this._initialStepsData()[this.state.currentType].correnti
                            }
                        }
                    })
                }
            }
        }
        else if (step==='dimensioni_tetto') {
            this.updateValidation(step, ValidateDimensioniTetto(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='colore_tetto') {
            let validate = ValidateColoreTetto(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                if (this.state.stepsData[this.state.currentType].colore_tetto.colore_tetto!=='Fascia Colore') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                colore_tetto: {
                                    ...this.state.stepsData[this.state.currentType].colore_tetto,
                                    colore_tetto_colore: ''
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='nodo') {
            this.updateValidation(step, ValidateNodo(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='traversi') {
            let validate = ValidateTraversi(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                let passo_traversi = this.state.stepsData[this.state.currentType].traversi.passo_traversi
                let difference = passo_traversi.length - this.state.stepsData[this.state.currentType].traversi.numero_traversi + 1

                if (difference > 0) {
                    passo_traversi.splice(this.state.stepsData[this.state.currentType].traversi.numero_traversi-1, difference)

                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                traversi: {
                                    ...this.state.stepsData[this.state.currentType].traversi,
                                    passo_traversi: passo_traversi
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='correnti') {
            let validate = ValidateCorrenti(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                let passo_correnti = this.state.stepsData[this.state.currentType].correnti.passo_correnti
                let difference = passo_correnti.length - this.state.stepsData[this.state.currentType].correnti.numero_correnti + 1

                if (difference > 0) {
                    passo_correnti.splice(this.state.stepsData[this.state.currentType].correnti.numero_correnti, difference)

                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                correnti: {
                                    ...this.state.stepsData[this.state.currentType].correnti,
                                    passo_correnti: passo_correnti
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='archi') {
            this.updateValidation(step, ValidateArchi(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='cinturini') {
            this.updateValidation(step, ValidateCinturini(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='fronte_tetto') {
            let validate = ValidateFronteTetto(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                if (this.state.stepsData[this.state.currentType].fronte_tetto.no_fronte_tetto) {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                fronte_tetto: {
                                    ...this.state.stepsData[this.state.currentType].fronte_tetto,
                                    altezza_frontale: '',
                                    scansi_anteriori_doppi: ''
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='spalla_tetto') {
            this.updateValidation(step, ValidateSpallaTetto(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='occhielli') {
            let validate = ValidateOcchielli(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                if (this.state.stepsData[this.state.currentType].occhielli.no_occhielli) {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                occhielli: {
                                    ...this.state.stepsData[this.state.currentType].occhielli,
                                    numero: '',
                                    disposizione: '',
                                    quota: '',
                                    interasse: '',
                                    passo_ultimo: ''
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='sgocciolatoio') {
            this.updateValidation(step, ValidateSgocciolatoio(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='tipologia_posteriore') {
            let validate = ValidateTipologiaPosteriore(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        retrotelo: this._initialStepsData().retrotelo,
                        retroporte: this._initialStepsData().retroporte,
                        retroporte_a: this._initialStepsData().retroporte_a,
                        retroporte_b: this._initialStepsData().retroporte_b,
                    }
                })
            }
        }
        else if (step==='retrotelo') {
            this.updateValidation(step, ValidateRetrotelo(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='retroporte') {
            let validate = ValidateRetroporte(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        retroporte_a: this._initialStepsData().retroporte_a,
                        retroporte_b: this._initialStepsData().retroporte_b
                    }
                })
            }
        }
        else if (step==='retroporte_a') {
            this.updateValidation(step, ValidateRetroporteA(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='retroporte_b') {
            this.updateValidation(step, ValidateRetroporteB(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='opzioni') {
            this.updateValidation(step, ValidateOpzioni(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='opzioni_prontocar') {
            this.updateValidation(step, ValidateOpzioniProntocar(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='opzioni_rollfix_planeroller') {
            this.updateValidation(step, ValidateOpzioniRollfixPlaneroller(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='note') {
            this.updateValidation(step, ValidateNote(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
    }

    updateValidation = (step, value, currentType = '', accordionValue = false) => {
        if (!currentType || currentType==='') {
            if (this.state.currentType) {
                currentType = this.state.currentType
            }
            else {
                currentType = ''
            }
        }

        this.setState({
            currentType: currentType,
            stepsValidation:
            {
                ...this.state.stepsValidation,
                [step]: value
            },
            stepsAccordionValidation: {
                ...this.state.stepsAccordionValidation,
                [step]: accordionValue
            }
        })

    }


    //
    // Render
    //

    render() {
        const { history } = this.props
        const { path } = this.props.match

        const getHeader = () => {
            if (this.state.currentStep.id!=='riepilogo' && this.state.currentStep.id!=='thankyou') {
                return (
                    <Header steps={this.state.steps} stepsData={this.state.stepsData} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsValidation={this.state.stepsValidation} stepsCount={this.state.stepsCount} />
                )
            }
            else {
                return null
            }
        }

        const getFooter = () => {
            if (this.state.currentStep.id!=='riepilogo' && this.state.currentStep.id!=='thankyou') {
                return (
                    <div className="configurator__footer">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-6 col-lg-7 offset-1">
                                    <PrevButton currentStep={this.state.currentStep} currentType={this.state.currentType} updateStep={this.updateStep} />
                                </div>
                                <div className="col-16 col-lg-8 text-right text-lg-center">
                                    <NextButton steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} updateStep={this.updateStep} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return null
                // (
                    // <div className="configurator__footer">
                    //     <div className="container-fluid">
                    //         <div className="row align-items-center">
                    //             <div className="col-6 col-lg-7 offset-1">
                    //                 <PrevButton currentStep={this.state.currentStep} currentType={this.state.currentType} updateStep={this.updateStep} />
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                // )
            }

        }

        return (
            <div>
                <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                    if ((this.state.steps[this.state.currentStep.id] && this.state.steps[this.state.currentStep.id].position>1) || !this.state.steps[this.state.currentStep.id]) {
                        let valids = 0;

                        for (let item in this.state.stepsValidation) {
                            if (this.state.stepsValidation[item]) {
                                valids++;
                            }
                        }

                        // if (valids < this.state.steps[this.state.currentStep.id].position - 1) {
                        if (valids <= 0 && this.state.currentStep.id!=='thankyou') {
                            history.push('/')
                        }
                    }
                }} />

                <div className="configurator">
                    {getHeader()}

                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${path}/tipologia-mezzo`} />
                        </Route>
                        <Route path={`${path}/tipologia-mezzo`}>
                            <ShowStep stepId="tipologia-mezzo" steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} stepsAccordionValidation={this.state.stepsAccordionValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                        <Route path={`${path}/riepilogo`}>
                            <ShowStep stepId="riepilogo" steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} stepsAccordionValidation={this.state.stepsAccordionValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                        <Route path={`${path}/thankyou`}>
                            <ShowStep stepId="thankyou" steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} stepsAccordionValidation={this.state.stepsAccordionValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                        <Route path={`${path}/:typeId/:stepId`}>
                            <ShowStep steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} stepsAccordionValidation={this.state.stepsAccordionValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                        <Route path={`${path}/:stepId`}>
                            <ShowStep steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} stepsAccordionValidation={this.state.stepsAccordionValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                    </Switch>

                    {getFooter()}
                </div>
            </div>
        )
    }
}

const ShowStep = (props) => {
    let { typeId, stepId } = useParams();

    if (!stepId) {
        stepId = props.stepId
    }

    if (stepId === 'tipologia-mezzo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaMezzo steps={props.steps} currentStep={props.currentStep} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'dati-cliente') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <DatiCliente steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} stepsAccordionValidation={props.stepsAccordionValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'teli') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Teli steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'teli-laterali') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TeliLaterali steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'caratteristiche-teli') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <CaratteristicheTeli steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} stepsAccordionValidation={props.stepsAccordionValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'materiale-laterale') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <MaterialeLaterale steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} stepsAccordionValidation={props.stepsAccordionValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'misure-laterale') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <MisureLaterale steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} stepsAccordionValidation={props.stepsAccordionValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'elastico') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Elastico steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'cricchetti') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Cricchetti steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'tipologia-cinghie') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaCinghie steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'reflex') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Reflex steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'maniglie') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Maniglie steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'esecuzione-intermodale') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <EsecuzioneIntermodale steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'ganci-sponda') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <GanciSponda steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'tipologia-carrelli') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaCarrelli steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'tenditelo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Tenditelo steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'rete-antivandalismo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <ReteAntivandalismo steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'tipologia-tetto') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaTetto steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'dimensioni-tetto') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <DimensioniTetto steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'nodo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Nodo steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'colore-tetto') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <ColoreTetto steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'traversi') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Traversi steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'correnti') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Correnti steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'archi') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Archi steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'cinturini') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Cinturini steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'fronte-tetto') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <FronteTetto steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'spalla-tetto') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <SpallaTetto steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'occhielli') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Occhielli steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'sgocciolatoio') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Sgocciolatoio steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'tipologia-posteriore') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaPosteriore steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'retrotelo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Retrotelo steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'retroporte') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Retroporte steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'retroporte-a') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <RetroporteA steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'retroporte-b') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <RetroporteB steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'opzioni') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Opzioni steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'opzioni-prontocar') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <OpzioniProntocar steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'opzioni-rollfix-planeroller') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <OpzioniRollfixPlaneroller steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'note') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Note steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'riepilogo') {
        return (
            <Riepilogo steps={props.steps} currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} />
        );
    }
    else if (stepId === 'thankyou') {
        return (
            <Thankyou steps={props.steps} currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} />
        );
    }
    else {
        return (
            <Redirect to="/" />
        );
    }
}

export default withRouter(Configurator);
