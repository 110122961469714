import React from 'react';

const RadioStandard = (props) => {
    return (
        <div className='form-radio-standard'>
            <div className="form-radio-standard__control">
                <input
                    type="radio"
                    className="form-radio-standard__input"
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    checked={props.checked}
                    onChange={props.handleChange}
                />
                <label className="form-radio-standard__label" htmlFor={props.id}>
                    <div className="text">{props.label}</div>
                </label>
            </div>
        </div>
    )
}

export default RadioStandard;
