import React, { useState } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useAuth } from "./auth";

import camio from '../images/camio_login.png';

const inputParsers = {
    date(input) {
        const split = input.split('/');
        const day = split[1]
        const month = split[0];
        const year = split[2];
        return `${year}-${month}-${day}`;
    },
    uppercase(input) {
        return input.toUpperCase();
    },
    number(input) {
        return parseFloat(input);
    },
};

const Login = () => {
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const [displayErrors, setDisplayErrors] = useState(false)
    const [reCaptchaToken, setReCaptchaToken] = useState(null)

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { setAuthTokens } = useAuth();

    if (isLoggedIn) {
        window.location = '/'
    }

    const submitDisabled = () => {
        if (isLoading) {
            return true
        }
        else {
            return false
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!e.target.checkValidity()) {
            setInvalid(true)
            setDisplayErrors(true)
            return
        }

        setIsLoading(true)

        const form = e.target;
        const data = new FormData(form);

        for (let name of data.keys()) {
            const input = form.elements[name];
            const parserName = input.dataset.parse;
            if (parserName) {
                const parsedValue = inputParsers[parserName](data.get(name))
                data.set(name, parsedValue);
            }
        }

        data.set('email', email);
        data.set('password', password);
        data.set('token', reCaptchaToken);

        setInvalid(false)
        setDisplayErrors(false)

        fetch('https://rivenditori.telonitosetto.it/api/b2b/login/', {
        // fetch('https://telonitosetto.loc/api/b2b/login/', {
          method: 'POST',
          body: stringifyFormData(data),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status==='ok') {
                        form.reset()

                        setAuthTokens(result.user_data);
                        setLoggedIn(true);
                    }
                    else {
                        setError(result.message)
                    }
                    setIsLoading(false)
                },
                (error) => {
                    setError('Si è verificato un errore, riprovare più tardi.')
                    setIsLoading(false)
                }
            )
    }

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6LeQYLsZAAAAAPYtigJ_83Fdsdlt7HKPBvjHKUhb"
        >
            <GoogleReCaptcha onVerify={token => setReCaptchaToken(token)} />
            <div className="front-page">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24 col-lg-14 col-xl-16">
                            <div className="front-page__image">
                                <a href="https://telonitosetto.it/" className="d-none d-lg-inline">Ritorna al sito</a>
                                <img src={camio} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-24 col-md-20 col-lg-8 col-xl-6 offset-md-2 offset-lg-1">
                            <div className="front-page__content text-center text-lg-left">
                                <svg className="svg-icon svg-icon--logo" viewBox="0 0 598 223" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                    <use xlinkHref="#svg-icon__logo"></use>
                                </svg>

                                <div className="front-page__login-heading">
                                    <h1>Accedi al tuo account</h1>
                                    <p>Accesso riservato ai rivenditori autorizzati da Teloni Tosetto.</p>
                                </div>

                                <form onSubmit={handleSubmit} noValidate className={displayErrors ? 'front-page__form displayErrors mb-3' : 'front-page__form mb-3'}>
                                    <div className={email ? 'form-group form-input required form-input--filled' : 'form-group form-input required' }>
                                        <label htmlFor="email" className="form-input__label">Email</label>
                                        <input value={email} onChange={e => { setEmail(e.target.value); }} type="email" name="email" id="email" className="form-control form-input__control" required />
                                    </div>
                                    <div className={password ? 'form-group form-input required form-input--filled' : 'form-group form-input required' }>
                                        <label htmlFor="password" className="form-input__label">Password</label>
                                        <input value={password} onChange={e => { setPassword(e.target.value); }} type="password" name="password" id="password" className="form-control form-input__control" required/>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-lg" disabled={submitDisabled()}>Accedi</button>
                                </form>

                                <div className="front-page__response">
                                    {invalid && !error && (
                                        <div className="alert alert-danger mb-3">
                                            Si prega di compilare tutti i campi obbligatori e controllare la correttezza del campo email.
                                        </div>
                                    )}
                                    {!invalid && error && (
                                        <div className="alert alert-danger mb-3">
                                            {error}
                                        </div>
                                    )}
                                </div>

                                <a href="/area-riservata/account/reset-password-request/" className="forgottenpassword">Password dimenticata?</a>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-24 col-lg-15 offset-lg-1">
                            <div className="front-page__copyright text-center text-lg-left">
                                &copy; 2020 Teloni Tosetto srl Unipersonale. All Rights Reserved  -  C.F., P.IVA e Reg. Imprese PD: 04245050283
                            </div>
                        </div>
                        <div className="col-24 col-lg-7 text-lg-right">
                            <div className="front-page__legal text-center text-lg-right">
                                <a href="https://telonitosetto.it/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GoogleReCaptchaProvider>
    )
}

function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return JSON.stringify(data, null, 2);
}

export default Login;
