import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card'

import Image from './Image.js';
import FormInput from '../Form/Input';
import FormRadio from '../Form/Radio';
import FormCheckboxStandard from '../Form/CheckboxStandard';

class MisureLaterale extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['misure_laterale'];

        this.state = {
            nextStep: 'cricchetti',
            accordionActiveElement: 0
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        let name = target.name;

        if (target.getAttribute('type') === 'checkbox') {
            if (target.checked) {
                value = target.value
            }
            else {
                value = ''
            }
        }

        // if (name.indexOf('[')>0) {
        //     let newName = name.replace(/\[(\w+)]/g, '')
        //     let key = /\[(.*?)\]/g.exec(name)[1]

        //     let newValue = this.props.stepsData[this.step.id][newName]
        //     newValue[key] = value

        //     name = newName
        //     value = newValue
        // }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    handleAccordionToggle = (eventKey) => {
        if (parseInt(eventKey)!==this.state.accordionActiveElement) {
            this.setState({
                accordionActiveElement: parseInt(eventKey)
            }, () => {
            })
        }
        else {
            this.setState({
                accordionActiveElement: null
            }, () => {
            })
        }
    }

    cardClass = (eventKey) => {
        let htmlClass = 'card'

        if (parseInt(eventKey)===parseInt(this.state.accordionActiveElement)) {
            htmlClass += ' show'
        }

        if (this.props.stepsAccordionValidation[this.step.id] && this.props.stepsAccordionValidation[this.step.id][eventKey]) {
            htmlClass += ' valid'
        }

        if (eventKey===4 && !(this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]==='Francese' || this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[1]==='Scorrevole')) {
            htmlClass += ' d-none'
        }

        if (eventKey===5 && this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]!=='Francese') {
            htmlClass += ' d-none'
        }

        return htmlClass;
    }

    updateNextStep = (teli) => {
        let nextStep = 'cricchetti';

        if (this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]==='Spondato') {
            nextStep = 'elastico';
        }

        this.setState({
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    interassePiantone = () => {
        let badgeList = []

        if (this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni > 0) {
            for (let i=0; i<=this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni; i++) {
                badgeList.push(<div className="col-12 col-md-8 col-lg-6 col-xxl-3" key={i}><FormInput type="number" name={"interasse_piantone[" + i + "]"} id={"interasse_piantone_" + i} value={this.props.stepsData[this.props.currentType].misure_laterale.interasse_piantone[i]} label={"P " + (i+1)} handleChange={this.handleInputChange} /></div>)
            }
        }

        return badgeList
    }

    render() {
        return (
            <div className="step step__misure-laterale">
                <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                    this.updateNextStep()
                }} />
                <div className="row justify-content-center">
                    <div className="col-24 col-lg-20">
                        <div className="text-center">
                            <Image directory="mezzo_misure" name="mezzo" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} stepsData={this.props.stepsData} changeLaterali={true} currentLaterali={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]} />
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Card bsPrefix={this.cardClass(0)}>
                                <Toggle eventKey="0" callback={this.handleAccordionToggle}>
                                    <span>Altezza (mm) e Lunghezza (mm)</span>
                                    <br /><small>Inserire dimensioni effettive + abbondanze, es: 4500+40</small>
                                </Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="row align-items-center">
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="text" name="altezza[h1]" id="h1" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.h1} label="H1" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="text" name="altezza[h2]" id="h2" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.h2} label="H2" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="text" name="altezza[h3]" id="h3" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.h3} label="H3" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="text" name="altezza[altezza_variabile]" id="altezza_variabile" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.altezza_variabile} label="Altezza variabile" optional={true} handleChange={this.handleInputChange} />
                                            </div>
                                            <div className={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0] === 'Spondato' && this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[1] === 'Scorrevole' ? 'col-24 col-md-6 col-xxl-4' : 'd-none' }>
                                                <FormCheckboxStandard name="altezza[sponda_dente]" id="sponda_dente" value="Sì" label="Sponda con dente" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.altezza.sponda_dente==='Sì'} />
                                            </div>
                                            <div className={this.props.currentType==='motrice' ? 'col-24 col-md-24 col-xxl-16' : 'd-none' }>
                                                <div className="row">
                                                    <div className="col-24 col-lg-12">
                                                        <FormInput type="text" name="altezza[quote_scansi_sx]" id="quote_scansi_sx" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.quote_scansi_sx} label="Quote scansi SX" optional={true} handleChange={this.handleInputChange} />
                                                    </div>
                                                    <div className="col-24 col-lg-12">
                                                        <FormInput type="text" name="altezza[quote_scansi_dx]" id="quote_scansi_dx" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.quote_scansi_dx} label="Quote scansi DX" optional={true} handleChange={this.handleInputChange} />
                                                    </div>
                                                    <div className="col-24 text-center">
                                                        <small>Inserire quote scanso LS X HS e distanza dall’anteriore, es. 500 X 300 a 800 da davanti</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(1)}>
                                <Toggle eventKey="1" callback={this.handleAccordionToggle}>
                                    <span>Numero Piantoni</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-xxl-3">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_0" value="0" label="0" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '0'} />
                                            </div>
                                            <div className="col-12 col-md-6 col-xxl-3">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_1" value="1" label="1" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '1'} />
                                            </div>
                                            <div className="col-12 col-md-6 col-xxl-3">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_2" value="2" label="2" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '2'} />
                                            </div>
                                            <div className="col-12 col-md-6 col-xxl-3">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_3" value="3" label="3" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '3'} />
                                            </div>
                                            <div className="col-12 col-md-6 col-xxl-3">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_4" value="4" label="4" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '4'} />
                                            </div>
                                            <div className="col-12 col-md-6 col-xxl-3">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_5" value="5" label="5" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '5'} />
                                            </div>
                                            <div className="col-12 col-md-6 col-xxl-3">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_6" value="6" label="6" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '6'} />
                                            </div>
                                            <div className="col-12 col-md-6 col-xxl-3">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_7" value="7" label="7" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '7'} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(2)}>
                                <Toggle eventKey="2" callback={this.handleAccordionToggle}>
                                    <span>Interasse Piantone (mm) e Lunghezza (mm)</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="row">
                                            {this.interassePiantone()}
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="text" name="altezza[lu]" id="lu" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.lu} label="LU" optional={true} handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(3)}>
                                <Toggle eventKey="3" callback={this.handleAccordionToggle}>
                                    <span>Rinforzi (mm)</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="rinforzi[ra]" id="rinforzi_ra" value={this.props.stepsData[this.props.currentType].misure_laterale.rinforzi.ra} label="RA" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="rinforzi[rp]" id="rinforzi_rp" value={this.props.stepsData[this.props.currentType].misure_laterale.rinforzi.rp} label="RP" handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(4)}>
                                <Toggle eventKey="4" callback={this.handleAccordionToggle}>
                                    <span>Carrelli</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <div className="row align-items-center">
                                            <div className="col-24 col-md-auto">
                                                Primo e<br/> ultimo carrello (mm)
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4 no-form-margin-mobile no-form-margin-desktop">
                                                <FormInput type="number" name="carrelli[cr1]" id="carrelli_cr1" value={this.props.stepsData[this.props.currentType].misure_laterale.carrelli.cr1} label="CR1" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4 no-form-margin-mobile no-form-margin-desktop">
                                                <FormInput type="number" name="carrelli[cr2]" id="carrelli_cr2" value={this.props.stepsData[this.props.currentType].misure_laterale.carrelli.cr2} label="CR2" handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(5)}>
                                <Toggle eventKey="5" callback={this.handleAccordionToggle}>
                                    <span>Cricchetti</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-10">
                                                <div className="row align-items-center">
                                                    <div className="col-24 col-xxl-auto">
                                                        <span style={{display: 'inline-block', marginBottom: '15px'}}>Passo cricchetti (mm)</span>
                                                    </div>
                                                    <div className="col-24 col-xxl-12">
                                                        <FormInput type="text" name="cricchetti[passo]" id="cricchetti_passo" value={this.props.stepsData[this.props.currentType].misure_laterale.cricchetti.passo} label="" handleChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-14">
                                                <div className="row align-items-center">
                                                    <div className="col-24 col-xxl-auto">
                                                        <span style={{display: 'inline-block', marginBottom: '15px'}}>Distanza primo ultimo cricchetto (mm)</span>
                                                    </div>
                                                    <div className="col-24 col-xxl-7">
                                                        <FormInput type="text" name="cricchetti[distanza_c1]" id="cricchetti_distanza_c1" value={this.props.stepsData[this.props.currentType].misure_laterale.cricchetti.distanza_c1} label="C1" handleChange={this.handleInputChange} />
                                                    </div>
                                                    <div className="col-24 col-xxl-7">
                                                        <FormInput type="text" name="cricchetti[distanza_c2]" id="cricchetti_distanza_c2" value={this.props.stepsData[this.props.currentType].misure_laterale.cricchetti.distanza_c2} label="C2" handleChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}

const Toggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <Card.Header onClick={decoratedOnClick}>
            {children}
        </Card.Header>
    );
}

export const ValidateMisureLaterale = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].misure_laterale

    if(!currentStep.altezza.h1 || !currentStep.altezza.h2 || !currentStep.altezza.h3 || !currentStep.numero_piantoni.numero_piantoni || !currentStep.rinforzi.ra || !currentStep.rinforzi.rp) {
        isValid = false
    }

    if (currentStep.numero_piantoni.numero_piantoni>0) {
        for (let i=0; i<=currentStep.numero_piantoni.numero_piantoni; i++) {
            if (!currentStep.interasse_piantone[i]) {
                isValid = false
            }
        }
    }

    if (currentStep.numero_piantoni.numero_piantoni==='') {
        isValid = false
    }

    if (stepsData[currentType].teli_laterali.teli_laterali[0]==='Francese' || stepsData[currentType].teli_laterali.teli_laterali[1]==='Scorrevole') {
        if (!currentStep.carrelli.cr1 || !currentStep.carrelli.cr2) {
            isValid = false
        }
    }

    if (stepsData[currentType].teli_laterali.teli_laterali[0]==='Francese') {
        if (!currentStep.cricchetti.passo || !currentStep.cricchetti.distanza_c1 || !currentStep.cricchetti.distanza_c2) {
            isValid = false
        }
    }

    return isValid;
}

export const ValidateMisureLateraleAccordion = (stepsData, currentStepData, currentType) => {
    let isValid = [true, true, true, true, true, true]
    let currentStep = stepsData[currentType].misure_laterale

    if(!currentStep.altezza.h1 || !currentStep.altezza.h2 || !currentStep.altezza.h3) {
        isValid[0] = false
    }

    if(!currentStep.numero_piantoni.numero_piantoni) {
        isValid[1] = false
    }

    if (currentStep.numero_piantoni.numero_piantoni>0) {
        for (let i=0; i<=currentStep.numero_piantoni.numero_piantoni; i++) {
            if (!currentStep.interasse_piantone[i]) {
                isValid[2] = false
            }
        }
    }

    if (currentStep.numero_piantoni.numero_piantoni==='') {
        isValid[2] = false
    }

    if(!currentStep.rinforzi.ra || !currentStep.rinforzi.rp) {
        isValid[3] = false
    }

    if (stepsData[currentType].teli_laterali.teli_laterali[0]==='Francese' || stepsData[currentType].teli_laterali.teli_laterali[1]==='Scorrevole') {
        if (!currentStep.carrelli.cr1 || !currentStep.carrelli.cr2) {
            isValid[4] = false
        }
    }
    else {
        isValid[4] = false
    }

    if (stepsData[currentType].teli_laterali.teli_laterali[0]==='Francese') {
        if (!currentStep.cricchetti.passo || !currentStep.cricchetti.distanza_c1 || !currentStep.cricchetti.distanza_c2) {
            isValid[5] = false
        }
    }
    else {
        isValid[5] = false
    }

    return isValid;
}

export default withRouter(MisureLaterale)
