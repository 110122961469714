import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';

class GanciSponda extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['ganci_sponda'];

        this.state = {
            subSteps: 0,
            nextStep: 'tipologia_carrelli'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId, 0, 1)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let newName;
        let key;

        if (name.indexOf('[')>0) {
            newName = name.replace(/\[(\w+)]/g, '')
            key = /\[(.*?)\]/g.exec(name)[1]

            let newValue

            if (newName==="piantone") {
                let subKey = target.name.match(/\[(.*?)\]/g)[1].replace('[', '').replace(']', '')

                newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
                newValue[key][subKey] = value
            }
            else {
                newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
                newValue[key] = value
            }

            name = newName
            value = newValue
        }

       this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateSubstep = (choice, substep) => {
        this.props.updateStep(this.step.id, this.step.name, this.props.currentStep.next, null, substep, this.substeps)
    }

    updateStep = (substep) => {
        let subSteps = parseInt(this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni) + 1
        let nextStep = 'tipologia_carrelli';

        if (this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[1]==='Fisso') {
            nextStep = 'tenditelo';
        }

        this.setState({
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, null, substep, subSteps)
        })
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateStep(0)
                    }} />
                    <GanciSpondaSteps currentStep={this.props.currentStep} currentType={this.props.currentType} stepsData={this.props.stepsData} handleChange={this.handleInputChange} updateData={this.props.updateData} updateStep={this.props.updateStep} />
                </div>
            )
        }
        else {
            return null
        }
    }
}

const GanciSpondaSteps = (props) => {
    if (props.currentStep.substep===0) { // FIRST STEP
        if (parseInt(props.stepsData[props.currentType].misure_laterale.numero_piantoni.numero_piantoni)===0) {
            return (
                <div className="step step__ganci-sponda">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={ props.currentStep.id } name="piantone_primo" />
                            </div>
                            <div className="step__form">
                                <div className="step__title">Anteriore / Posteriore</div>
                                <GanciSpondaFields currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} handleChange={props.handleChange} updateData={props.updateData} updateStep={props.updateStep} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="step step__ganci-sponda">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={ props.currentStep.id } name="piantone_primo" />
                            </div>
                            <div className="step__form">
                                <div className="step__title">Anteriore / Piantone {props.currentStep.substep + 1}</div>
                                <GanciSpondaFields currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} handleChange={props.handleChange} updateData={props.updateData} updateStep={props.updateStep} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    else if (props.currentStep.substep===props.currentStep.subSteps-1) { // LAST STEP
        return (
            <div className="step step__ganci-sponda">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            <Image directory={ props.currentStep.id } name="piantone_ultimo" />
                        </div>
                        <div className="step__form">
                            <div className="step__title">Piantone {props.currentStep.substep} / Posteriore</div>
                            <GanciSpondaFields currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} handleChange={props.handleChange} updateData={props.updateData} updateStep={props.updateStep} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (props.currentStep.substep>0 && props.currentStep.substep<props.currentStep.subSteps-1) { // INTERMEDIATE STEP
        return (
            <div className="step step__ganci-sponda">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            <Image directory={ props.currentStep.id } name="piantone" />
                        </div>
                        <div className="step__form">
                            <div className="step__title">Piantone {props.currentStep.substep} / Piantone {props.currentStep.substep + 1}</div>
                            <GanciSpondaFields currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} handleChange={props.handleChange} updateData={props.updateData} updateStep={props.updateStep} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return null
    }
}

const GanciSpondaFields = (props) => {
    return (
        <div className="row justify-content-center">
            <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                <FormInput type="text" name={"piantone[" + props.currentStep.substep + "][numero_ganci]"} id={"piantone_" + props.currentStep.substep + "_numero_ganci"} value={props.stepsData[props.currentType].ganci_sponda.piantone[props.currentStep.substep].numero_ganci} label="N° Ganci" handleChange={props.handleChange} note="Lasciare vuoto se non si conosce il numero" />
            </div>
            <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                <FormInput type="text" name={"piantone[" + props.currentStep.substep + "][passo_ganci]"} id={"piantone_" + props.currentStep.substep + "_passo_ganci"} value={props.stepsData[props.currentType].ganci_sponda.piantone[props.currentStep.substep].passo_ganci} label="Passo Ganci (mm)" handleChange={props.handleChange} note="Segnalare eventuali varianti" />
            </div>
            <div className="w-100 d0none d-xl-flex"></div>
            <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                <FormInput type="number" name={"piantone[" + props.currentStep.substep + "][distanza_g1]"} id={"piantone_" + props.currentStep.substep + "_distanza_g1"} value={props.stepsData[props.currentType].ganci_sponda.piantone[props.currentStep.substep].distanza_g1} label="Dist. G1 (mm)" handleChange={props.handleChange} note="Dist. 1° gancio" />
            </div>
            <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                <FormInput type="number" name={"piantone[" + props.currentStep.substep + "][distanza_g2]"} id={"piantone_" + props.currentStep.substep + "_distanza_g2"} value={props.stepsData[props.currentType].ganci_sponda.piantone[props.currentStep.substep].distanza_g2} label="Dist. G2 (mm)" handleChange={props.handleChange} note="Dist. ultimo gancio" />
            </div>
        </div>
    )
}

export const ValidateGanciSponda = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].ganci_sponda

    if (!currentStep.piantone[currentStepData.substep].passo_ganci || !currentStep.piantone[currentStepData.substep].distanza_g1 || !currentStep.piantone[currentStepData.substep].distanza_g2) {
        isValid = false
    }
    
    return isValid;
}

export default withRouter(GanciSponda)
