import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';
import FormInput from '../Form/Input.js';
import FormSelect from '../Form/Select.js';

class TipologiaCarrelli extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['tipologia_carrelli'];

        this.state = {
            nextStep: 'tenditelo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)

        // if (this.props.currentType) {
        //     if (this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]==='Francese' && this.props.stepsData[this.props.currentType].tipologia_carrelli.numero_carrelli==='') {
        //         this.props.updateData(this.props.currentType, this.step.id, 'numero_carrelli', this.props.stepsData[this.props.currentType].cricchetti.numero_cricchetti)
        //     }
        // }
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    showTypeInput = () => {
        if (this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli_esistenti[0]==='Indica tipologia') {
            return ''
        }
        else {
            return 'd-none'
        }
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <div className="step step__cricchetti">

                        <div className="row align-items-center justify-content-center bottom-fields">
                            <div className='item-number col-16 col-lg-8 col-xl-5'>
                                <FormInput type="number" name="numero_carrelli_sx" id="numero_carrelli_sx" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.numero_carrelli_sx} label="Numero Carrelli lato SX" handleChange={this.handleInputChange} />
                            </div>
                            <div className='item-number col-16 col-lg-8 col-xl-5'>
                                <FormInput type="number" name="numero_carrelli_dx" id="numero_carrelli_dx" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.numero_carrelli_dx} label="Numero Carrelli lato DX" handleChange={this.handleInputChange} />
                            </div>
                        </div>

                        <div className="scrollable-options">
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli1" value="Recupero carrello esistente" label="Recupero carrello esistente" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Recupero carrello esistente'}>
                                <div>
                                    <span className="title">Recupera esistenti</span>
                                    <FormSelect type="text" name="tipologia_carrelli_esistenti[0]" id="tipologia_carrelli_esistenti1" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli_esistenti[0]} label="" handleChange={this.handleInputChange}>
                                        <option value="">Seleziona</option>
                                        <option value="VERSUS STRIKE tipo nuovo con asola">VERSUS STRIKE tipo nuovo con asola</option>
                                        <option value="VERSUS tipo vecchio a 6 ruote">VERSUS tipo vecchio a 6 ruote</option>
                                        <option value="EDSCHA a 2 ruote su un lato con asola">EDSCHA a 2 ruote su un lato con asola</option>
                                        <option value="SCHMITZ a 4 ruote d. 31 mm piastra piegata">SCHMITZ a 4 ruote d. 31 mm piastra piegata</option>
                                        <option value="EDSCHA a 2 ruote d. 35 mm staffa corta">EDSCHA a 2 ruote d. 35 mm staffa corta</option>
                                        <option value="EDSCHA a 2 ruote d. 35 mm staffa piegata">EDSCHA a 2 ruote d. 35 mm staffa piegata</option>
                                        <option value="EDSCHA a 2 ruote d. 37 mm staffa lunga">EDSCHA a 2 ruote d. 37 mm staffa lunga</option>
                                        <option value="EDSCHA a 2 ruote d. 37 mm con asola">EDSCHA a 2 ruote d. 37 mm con asola</option>
                                        <option value="EDSCHA a 2 ruote d. 35 mm con asola">EDSCHA a 2 ruote d. 35 mm con asola</option>
                                        <option value="EDSCHA a 4 ruote staffa lunga e piegata">EDSCHA a 4 ruote staffa lunga e piegata</option>
                                        <option value="A 4 ruote d. 24 mm">A 4 ruote d. 24 mm</option>
                                        <option value="A 2 ruote d. 24 mm">A 2 ruote d. 24 mm</option>
                                        <option value="ALUSTAR a 2 ruote plastica staffa lunga">ALUSTAR a 2 ruote plastica staffa lunga</option>
                                        <option value="ALUSTAR a 2 ruote plastica staffa corta">ALUSTAR a 2 ruote plastica staffa corta</option>
                                        <option value="ALU SCHMAL a 2 ruote con piastra">ALU SCHMAL a 2 ruote con piastra</option>

                                        <option value="EDSCHA a 2 ruote su un lato rivettato">EDSCHA a 2 ruote su un lato rivettato</option>
                                        <option value="SC5 a 2 ruote in ferro">SC5 a 2 ruote in ferro</option>
                                        <option value="AUTOCAR a 2 ruote gialle">AUTOCAR a 2 ruote gialle</option>
                                        <option value="AUTOCAR a 3 ruote">AUTOCAR a 3 ruote</option>
                                        <option value="ALUSTAR a 2 ruote in ferro (staffa lunga)">ALUSTAR a 2 ruote in ferro (staffa lunga)</option>
                                        <option value="A cricchetto a 2 ruote d. 42mm">A cricchetto a 2 ruote d. 42mm</option>
                                        <option value="A cricchetto a 4 ruote">A cricchetto a 4 ruote</option>

                                        <option value="Indica tipologia">Indica tipologia</option>
                                    </FormSelect>
                                    <div className={this.showTypeInput()}>
                                        <FormInput type="text" name="tipologia_carrelli_esistenti[1]" id="tipologia_carrelli_esistenti2" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli_esistenti[1]} label="Tipologia" handleChange={this.handleInputChange} />
                                    </div>
                                    {/* <FormInput type="text" name="tipologia_carrelli_esistenti" id="tipologia_carrelli_esistenti" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli_esistenti} label="Indica tipologia" handleChange={this.handleInputChange} /> */}
                                </div>
                            </FormRadio>
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli2" value="VERSUS STRIKE tipo nuovo con asola" label="VERSUS STRIKE tipo nuovo con asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'VERSUS STRIKE tipo nuovo con asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli3" value="VERSUS tipo vecchio a 6 ruote" label="VERSUS tipo vecchio a 6 ruote" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'VERSUS tipo vecchio a 6 ruote'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli4" value="EDSCHA 2 ruote su un lato con asola" label="EDSCHA 2 ruote su un lato con asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA 2 ruote su un lato con asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli5" value="SCHMITZ a 4 ruote d. 31 mm piastra piegata" label="SCHMITZ a 4 ruote d. 31 mm piastra piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'SCHMITZ a 4 ruote d. 31 mm piastra piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli6" value="EDSCHA a 2 ruote d. 35 mm staffa corta" label="EDSCHA a 2 ruote d. 35 mm staffa corta" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA a 2 ruote d. 35 mm staffa corta'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli7" value="EDSCHA a 2 ruote d. 35 mm staffa piegata" label="EDSCHA a 2 ruote d. 35 mm staffa piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA a 2 ruote d. 35 mm staffa piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli8" value="EDSCHA a 2 ruote d. 37 mm staffa lunga" label="EDSCHA a 2 ruote d. 37 mm staffa lunga" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA a 2 ruote d. 37 mm staffa lunga'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli9" value="EDSCHA a 2 ruote d. 37 mm con asola" label="EDSCHA a 2 ruote d. 37 mm con asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA a 2 ruote d. 37 mm con asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli10" value="EDSCHA a 2 ruote d. 35 mm con asola" label="EDSCHA a 2 ruote d. 35 mm con asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA a 2 ruote d. 35 mm con asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli11" value="EDSCHA a 4 ruote staffa lunga e piegata" label="EDSCHA a 4 ruote staffa lunga e piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA a 4 ruote staffa lunga e piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli12" value="A 4 ruote d. 24 mm" label="A 4 ruote d. 24 mm" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'A 4 ruote d. 24 mm'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli13" value="A 2 ruote d. 24 mm" label="A 2 ruote d. 24 mm" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'A 2 ruote d. 24 mm'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli14" value="ALUSTAR a 2 ruote plastica staffa lunga" label="ALUSTAR a 2 ruote plastica staffa lunga" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'ALUSTAR a 2 ruote plastica staffa lunga'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli15" value="ALUSTAR a 2 ruote plastica staffa corta" label="ALUSTAR a 2 ruote plastica staffa corta" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'ALUSTAR a 2 ruote plastica staffa corta'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli16" value="ALU SCHMAL a 2 ruote con piastra" label="ALU SCHMAL a 2 ruote con piastra" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'ALU SCHMAL a 2 ruote con piastra'} />

                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli17" value="EDSCHA a 2 ruote su un lato rivettato" label="EDSCHA a 2 ruote su un lato rivettato" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA a 2 ruote su un lato rivettato'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli18" value="SC5 a 2 ruote in ferro" label="SC5 a 2 ruote in ferro" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'SC5 a 2 ruote in ferro'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli19" value="AUTOCAR a 2 ruote gialle" label="AUTOCAR a 2 ruote gialle" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'AUTOCAR a 2 ruote gialle'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli20" value="AUTOCAR a 3 ruote" label="AUTOCAR a 3 ruote" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'AUTOCAR a 3 ruote'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli21" value="ALUSTAR a 2 ruote in ferro (staffa lunga)" label="ALUSTAR a 2 ruote in ferro (staffa lunga)" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'ALUSTAR a 2 ruote in ferro (staffa lunga)'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli22" value="A cricchetto a 2 ruote d. 42mm" label="A cricchetto a 2 ruote d. 42mm" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'A cricchetto a 2 ruote d. 42mm'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli23" value="A cricchetto a 4 ruote" label="A cricchetto a 4 ruote" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'A cricchetto a 4 ruote'} />
                            
                        </div>

                        <div className="row align-items-center justify-content-center">
                            <div className='item-number col-16 col-lg-8 col-xl-5'>
                                <FormInput type="text" name="carrelli_particolari" id="carrelli_particolari" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.carrelli_particolari} label="Carrelli particolari" optional={true} handleChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTipologiaCarrelli = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].tipologia_carrelli

    if(!currentStep.tipologia_carrelli || !currentStep.numero_carrelli_sx || !currentStep.numero_carrelli_dx) {
        isValid = false
    }
    else if (currentStep.tipologia_carrelli==='Recupero carrello esistente' && !currentStep.tipologia_carrelli_esistenti[0]) {
        isValid = false
    }

    if(currentStep.tipologia_carrelli==='Recupero carrello esistente' && currentStep.tipologia_carrelli_esistenti[0]==='Indica tipologia' && !currentStep.tipologia_carrelli_esistenti[1]) {
        isValid = false
    }

    return isValid;
}

export default withRouter(TipologiaCarrelli)
