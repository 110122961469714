import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";

import moment from 'moment';

const NavItem = (props) => {
    let { url } = useRouteMatch();

    url = url.replace('/riepilogo', '')

    const isEmpty = (element) => {
        if (element) {
            if (typeof element==='string') {
                if (!element) {
                    return true
                }
            }
            else if (typeof element==='object' || element instanceof Array) {
                let emptyCounter = 0
                let elementsCount = 0

                if (typeof element==='object') {
                    elementsCount = Object.keys(element).length
                }
                else {
                    elementsCount = element.length
                }

                for (var key in element) {
                    if (isEmpty(element[key])) {
                        emptyCounter++
                    }
                }

                if (emptyCounter===elementsCount) {
                    return true
                }
            }
            else {
                return true
            }
        }
        else {
            return true
        }

        return false
    }

    if(props.currentStep.id === props.steps[props.step].id) {
        if (props.currentType===props.type) {
            return (
                <li className="navigation-nav__item">
                    <strong className="active">{props.steps[props.step].name}</strong>
                </li>
            )
        }
        else {
            if (!isEmpty(props.stepsData[props.steps[props.step].id])) {
                let type = ''
                if (props.type) {
                    type = props.type + '/'
                }

                return (
                    <li className="navigation-nav__item">
                        <Link to={`${url}/${type}${props.steps[props.step].slug}`}>
                            <LinkText step={props.steps[props.step]} stepsData={props.stepsData} />
                        </Link>
                    </li>
                )
            }
            else {
                return null
            }
        }
    }
    else if(props.stepsData[props.step]) {
        if (!isEmpty(props.stepsData[props.steps[props.step].id])) {
            let type = ''
            if (props.type) {
                type = props.type + '/'
            }

            return (
                <li className="navigation-nav__item">
                    <Link to={`${url}/${type}${props.steps[props.step].slug}`}>
                        <LinkText step={props.steps[props.step]} stepsData={props.stepsData} />
                    </Link>
                </li>
            )
        }
        else {
            return null
        }
    }
    else{
        return null
    }
}

const LinkText = (props) => {
    let stepData = props.stepsData[props.step.id]

    if (props.step.id==='dati_cliente') {
        const getDatiMezzo = () => {
            if (stepData.dati_mezzo && (stepData.dati_mezzo.targa || stepData.dati_mezzo.marca || stepData.dati_mezzo.modello || stepData.dati_mezzo.anno)) {
                let content = '<span class="subtitle">Dati Mezzo:</span>';

                if (stepData.dati_mezzo.targa) {
                    content += 'Targa/Telaio: ' + stepData.dati_mezzo.targa + '<br/>'
                }

                if (stepData.dati_mezzo.marca) {
                    content += 'Marca: ' + stepData.dati_mezzo.marca + '<br/>'
                }

                if (stepData.dati_mezzo.modello) {
                    content += 'Modello: ' + stepData.dati_mezzo.modello + '<br/>'
                }

                if (stepData.dati_mezzo.anno) {
                    content += 'Anno: ' + stepData.dati_mezzo.anno + '<br/>'
                }

                return content
            }
            else {
                return null
            }
        }

        const getDataConsegna = () => {
            if (stepData.dati_lavorazione.data_consegna) {
                return 'Data Consegna: ' + moment(stepData.dati_lavorazione.data_consegna).format("DD/MM/YYYY") + '<br/>'
            }
            else {
                return null
            }
        }

        const getMontaggio = () => {
            if (stepData.dati_lavorazione.montaggio==='Sì') {
                let string = ''

                if (stepData.dati_lavorazione.interno_ubicazione_p) {
                    string += 'Interno Ubicazione P.: ' + stepData.dati_lavorazione.interno_ubicazione_p + '<br/>'
                }

                if (stepData.dati_lavorazione.esterno) {
                    string += 'Esterno: ' + stepData.dati_lavorazione.esterno + '<br/>'
                }

                return string
            }
            else {
                return null
            }
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Dati Principali:</span>
                Azienda: {stepData.dati_principali.azienda}<br/>
                Referente: {stepData.dati_principali.referente}<br/>
                Telefono: {stepData.dati_principali.telefono}<br/>
                <span dangerouslySetInnerHTML={{__html: getDatiMezzo()}}/>
                <span className="item-subtitle">Dati Lavorazione:</span>
                Montaggio: {stepData.dati_lavorazione.montaggio}<br/>
                <span dangerouslySetInnerHTML={{__html: getMontaggio()}}/>
                <span dangerouslySetInnerHTML={{__html: getDataConsegna()}}/>
            </div>
        )
    }
    else if (props.step.id==='caratteristiche_teli') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Teloni:</span>
                Lato sinistro: {stepData.teloni.quantita_sx}<br/>
                Lato destro: {stepData.teloni.quantita_dx}<br/>
                <span className="item-subtitle">Stampa:</span>
                Stampa Logo: {stepData.stampa.logo}<br/>
                Note: {stepData.stampa.logo_note}<br/>
            </div>
        )
    }
    else if (props.step.id==='materiale_laterale') {
        const getTipologia = () => {
            if (stepData.materiale.tipologia) {
                return 'Ignifugo: Sì<br/>'
            }
            else {
                return null
            }
        }
        const getColoreFascia = () => {
            if (stepData.colore.colore_fascia) {
                return 'Fascia: ' + stepData.colore.colore_fascia + '<br/>'
            }
            else {
                return null
            }
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Materiale:</span>
                Materiale: {stepData.materiale.mareriale}<br/>
                <span dangerouslySetInnerHTML={{__html: getTipologia()}}/>
                <span className="item-subtitle">Opzione Colore:</span>
                <span dangerouslySetInnerHTML={{__html: getColoreFascia()}}/>
                Telo: {stepData.colore.colore_telo}<br/>
            </div>
        )
    }
    else if (props.step.id==='misure_laterale') {
        const getCarrelli = () => {
            if (stepData.carrelli.carrelli_cr1 || stepData.carrelli.carrelli_cr1) {
                let content = '<span class="subtitle">Dati Mezzo:</span>';

                content += 'CR1: ' + stepData.carrelli.carrelli_cr1 + ' mm<br/>'
                content += 'CR2: ' + stepData.carrelli.carrelli_cr2 + ' mm<br/>'

                return content
            }
            else {
                return null
            }
        }
        const getCricchetti = () => {
            if (stepData.cricchetti.passo || stepData.cricchetti.distanza_c1 || stepData.cricchetti.distanza_c2) {
                let content = '<span class="item-subtitle">Cricchetti:</span>';

                content += 'Passo: ' + stepData.cricchetti.passo + 'mm<br/>'
                content += 'C1: ' + stepData.cricchetti.distanza_c1 + ' mm<br/>'
                content += 'C2: ' + stepData.cricchetti.distanza_c2 + ' mm<br/>'

                return content
            }
            else {
                return null
            }
        }

        const getPiantoni = () => {
            let piantoni = ''

            for (let i=0; i<8; i++) {
                if (stepData.interasse_piantone[i]) {
                    piantoni += (i+1) + ": " + stepData.interasse_piantone[i] + " mm<br/>"
                }
            }

            return piantoni
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Altezza:</span>
                H1: {stepData.altezza.h1} mm<br/>
                H2: {stepData.altezza.h2} mm<br/>
                H3: {stepData.altezza.h3} mm<br/>
                <span dangerouslySetInnerHTML={{__html: stepData.altezza.sponda_dente ? 'Sponda con dente:  ' + stepData.altezza.sponda_dente + '<br/>' : ''}}/>
                <span dangerouslySetInnerHTML={{__html: stepData.altezza.altezza_variabile ? 'Altezza variabile: ' + stepData.altezza.altezza_variabile + '<br/>' : ''}}/>
                <span dangerouslySetInnerHTML={{__html: stepData.altezza.quote_scansi_sx ? 'Quote scansi SX: ' + stepData.altezza.quote_scansi_sx + '<br/>' : ''}}/>
                <span dangerouslySetInnerHTML={{__html: stepData.altezza.quote_scansi_dx ? 'Quote scansi DX: ' + stepData.altezza.quote_scansi_dx + '<br/>' : ''}}/>
                <span className="item-subtitle">Numero Piantoni:</span>
                Numero Piantoni: {stepData.numero_piantoni.numero_piantoni}<br/>
                <span className="item-subtitle">Interasse Piantone:</span>
                <span dangerouslySetInnerHTML={{__html: getPiantoni()}}/>
                <span dangerouslySetInnerHTML={{__html: stepData.altezza.lu ? 'LU:  ' + stepData.altezza.lu + ' mm<br/>' : ''}}/>
                <span className="item-subtitle">Rinforzi:</span>
                RA: {stepData.rinforzi.ra} mm<br/>
                RP: {stepData.rinforzi.rp} mm<br/>
                <span dangerouslySetInnerHTML={{__html: getCarrelli()}}/>
                <span dangerouslySetInnerHTML={{__html: getCricchetti()}}/>
            </div>
        )
    }
    else if (props.step.id==='cricchetti') {
        if(stepData.tipologia_cricchetti[0]==='Recupera esistenti') {
            if(stepData.tipologia_cricchetti[1]==='Indica tipologia') {
                return (
                    <div>
                        <span className="item-title">{props.step.name}:{' '}</span>
                        SX {stepData.numero_cricchetti_sx}<br/>
                        DX {stepData.numero_cricchetti_dx}<br/>
                        {stepData.tipologia_cricchetti[0]} ({stepData.tipologia_cricchetti[2]})
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <span className="item-title">{props.step.name}:{' '}</span>
                        SX {stepData.numero_cricchetti_sx}<br/>
                        DX {stepData.numero_cricchetti_dx}<br/>
                        {stepData.tipologia_cricchetti[0]} ({stepData.tipologia_cricchetti[1]})
                        {stepData.tipologia_cricchetti[1]==='Sì' ? ' EN 12641-2 XL' : ''}
                    </div>
                )
            }
        }
        else {
            const getColor = () => {
                if (stepData.colore_cricchetti[0]) {
                    return <span>
                        {stepData.colore_cricchetti[0]} <span className="color" data-hex={stepData.colore_cricchetti[1]}></span>
                    </span>
                }
                else {
                    return null
                }
            }

            return (
                <div>
                    <span className="item-title">{props.step.name}:{' '}</span>
                    SX {stepData.numero_cricchetti_sx}<br/>
                    DX {stepData.numero_cricchetti_dx}<br/>
                    {stepData.tipologia_cricchetti[0]}
                    {stepData.tipologia_cricchetti[1]==='Sì' ? ' EN 12641-2 XL' : ''}
                    {getColor()}
                </div>
            )
        }
    }
    else if (props.step.id==='tipologia_cinghie') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.numero_cinghie_orizzontali} Cinghie Oriz.
                {stepData.cinghie_verticali_en==='Sì' ? ' / Cinghie Verticali 2300 Kn  EN 12641-2 XL' : ''}
                {stepData.cinghie_oblique_xl==='Sì' ? ' / Cinghie oblique XL' : ''}
            </div>
        )
    }
    else if (props.step.id==='reflex') {
        const getColor = () => {
            if (stepData.reflex[0]!=='Senza Reflex') {
                return <span className="color" data-hex={stepData.reflex[1]}></span>
            }
            else {
                return null
            }
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.reflex[0]} {getColor()}
            </div>
        )
    }
    else if (props.step.id==='esecuzione_intermodale') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.esecuzione_intermodale}
                <span dangerouslySetInnerHTML={{__html: stepData.esecuzione_intermodale==='Sì' ? '<br/>TA: ' + stepData.ta : ''}} />
                <span dangerouslySetInnerHTML={{__html: stepData.esecuzione_intermodale==='Sì' ? '<br/>TP: ' + stepData.tp : ''}} />
            </div>
        )
    }
    else if (props.step.id==='ganci_sponda') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.piantone.map((item, i) => {
                    let piantoni = parseInt(props.stepsData.misure_laterale.numero_piantoni.numero_piantoni) + 1

                    if (i===0) {
                        return (
                            <div key={i}>
                                <span className="item-subtitle">Anteriore / Piantone {i + 1}:</span>
                                Numero Ganci: {item.numero_ganci}<br/>
                                Passo Ganci: {item.passo_ganci} mm<br/>
                                Distanza G1: {item.distanza_g1} mm<br/>
                                Distanza G2: {item.distanza_g2} mm
                            </div>
                        ) 
                    }
                    else if (i===piantoni-1) {
                        return (
                            <div key={i}>
                                <span className="item-subtitle">Piantone {i + 1} / Posteriore:</span>
                                Numero Ganci: {item.numero_ganci}<br/>
                                Passo Ganci: {item.passo_ganci} mm<br/>
                                Distanza G1: {item.distanza_g1} mm<br/>
                                Distanza G2: {item.distanza_g2} mm
                            </div>
                        )
                    }
                    else if (i<piantoni) {
                        return (
                            <div key={i}>
                                <span className="item-subtitle">Piantone {i} / Piantone {i + 1}:</span>
                                Numero Ganci: {item.numero_ganci}<br/>
                                Passo Ganci: {item.passo_ganci} mm<br/>
                                Distanza G1: {item.distanza_g1} mm<br/>
                                Distanza G2: {item.distanza_g2} mm
                            </div>
                        )
                    }
                    else {
                        return null
                    }
                })}
            </div>
        )
    }
    else if (props.step.id==='tipologia_carrelli') {
        if (stepData.tipologia_carrelli_esistenti[0]==='Indica tipologia') {
            return (
                <div>
                    <span className="item-title">{props.step.name}:{' '}</span>
                    SX {stepData.numero_carrelli_sx}<br/>
                    DX {stepData.numero_carrelli_dx}<br/>
                    {stepData.tipologia_carrelli}
                    {stepData.tipologia_carrelli_esistenti[0] ? ' (' + stepData.tipologia_carrelli_esistenti[1] + ')' : ''}
                    <span dangerouslySetInnerHTML={{__html: stepData.carrelli_particolari ? '<br/>Carrelli particolari: ' + stepData.carrelli_particolari : ''}} />
                </div>
            )
        }
        else {
            return (
                <div>
                    <span className="item-title">{props.step.name}:{' '}</span>
                    SX {stepData.numero_carrelli_sx}<br/>
                    DX {stepData.numero_carrelli_dx}<br/>
                    {stepData.tipologia_carrelli}
                    {stepData.tipologia_carrelli_esistenti[0] ? ' (' + stepData.tipologia_carrelli_esistenti[0] + ')' : ''}
                    <span dangerouslySetInnerHTML={{__html: stepData.carrelli_particolari ? '<br/>Carrelli particolari: ' + stepData.carrelli_particolari : ''}} />
                </div>
            )
        }
    }
    else if (props.step.id==='tenditelo') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                Anteriore: {stepData.tenditelo_anteriore_recupera ? ' Recupero esistente (' : ''}{stepData.tenditelo_anteriore}{' '}{stepData.tipologia_anteriore}{stepData.tenditelo_anteriore_recupera ? ')' : ''}<br/>
                Posteriore: {stepData.tenditelo_posteriore_recupera ? ' Recupero esistente (' : ''}{stepData.tenditelo_posteriore}{' '}{stepData.tipologia_posteriore}{stepData.tenditelo_posteriore_recupera ? ')' : ''}<br/>
            </div>
        )
    }
    else if (props.step.id==='rete_antivandalismo') {
        const getAltezza = () => {
            if (stepData.altezza) {
                return 'Altezza ' + stepData.altezza + ' mm'
            }
            else {
                return null
            }
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.rete_antivandalismo}{' '}
                {stepData.griglia}<br/>
                <span dangerouslySetInnerHTML={{__html: getAltezza()}} />
            </div>
        )
    }
    else if (props.step.id==='tipologia_tetto') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.tipologia_tetto}
                <span dangerouslySetInnerHTML={{__html: stepData.fasce_spalmate ? '<br/>Fasce spalmate oblique: ' + stepData.fasce_spalmate : ''}} />
            </div>
        )
    }
    else if (props.step.id==='dimensioni_tetto') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.lunghezza} mm x
                {stepData.larghezza} mm
            </div>
        )
    }
    else if (props.step.id==='traversi') {
        const getPasso = () => {
            let html = ''
            let alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

            if (stepData.passo_traversi.length) {
                for (let i=0; i<stepData.passo_traversi.length; i++) {
                    html +=  alphabet[i] + ': ' + stepData.passo_traversi[i] + ' mm<br/>'
                }
            }

            return html
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                N° Traversi: {stepData.numero_traversi}<br/>
                <span className="item-subtitle">Passo:</span>
                <span dangerouslySetInnerHTML={{__html: getPasso()}} />
            </div>
        )
    }
    else if (props.step.id==='correnti') {
        const getPasso = () => {
            let html = ''

            if (stepData.passo_correnti.length) {
                for (let i=0; i<stepData.passo_correnti.length; i++) {
                    html +=  (i+1) + ': ' + stepData.passo_correnti[i] + ' mm<br/>'
                }
            }

            return html
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                N° Correnti: {stepData.numero_correnti}<br/>
                <span className="item-subtitle">Passo:</span>
                <span dangerouslySetInnerHTML={{__html: getPasso()}} />
            </div>
        )
    }
    else if (props.step.id==='fronte_tetto') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                Altezza Frontale: {stepData.altezza_frontale} mm<br/>
                Eventuali scansi / anteriori doppi: {stepData.scansi_anteriori_doppi}
            </div>
        )
    }
    else if (props.step.id==='archi') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                N° Archi: {stepData.numero_archi}<br/>
                Passo Archi: {stepData.passo_archi} mm<br/>
                Passo Ultimo Arco: {stepData.passo_ultimo_arco} mm<br/>
                Dist. 1° Arco: {stepData.distanza_a1} mm<br/>
                Dist. dal 1° a 2° Arco: {stepData.distanza_a2} mm<br/>
                Dist. penultimo Arco: {stepData.distanza_a3} mm<br/>
                Dist. ultimo Arco: {stepData.distanza_a4} mm<br/>
                Rinforzo RA: {stepData.rinforzo_ra} mm<br/>
                Rinforzo RP: {stepData.rinforzo_rp} mm<br/>
                Note: {stepData.note_archi}
            </div>
        )
    }
    else if (props.step.id==='spalla_tetto') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                D: {stepData.d} mm<br/>
                E: {stepData.e} mm<br/>
                F: {stepData.f} mm<br/>
                G1: {stepData.g} mm<br/>
                Larghezza tra G1 e G2: {stepData.g1g2} mm<br/>
            </div>
        )
    }
    else if (props.step.id==='occhielli') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                Numero: {stepData.numero}<br/>
                Disposizione: {stepData.disposizione}<br/>
                Quota: {stepData.quota} mm<br/>
                Interasse: {stepData.interasse} mm<br/>
                Passo Ultimo occhiello: {stepData.passo_ultimo} mm
            </div>
        )
    }
    else if (props.step.id==='retrotelo') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                LA: {stepData.la} mm<br/>
                H: {stepData.h} mm
            </div>
        )
    }
    else if (props.step.id==='retroporte_a') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                LA: {stepData.la} mm<br/>
                L1: {stepData.l1} mm<br/>
                L2: {stepData.l2} mm<br/>
                L3: {stepData.l3} mm<br/>
                H: {stepData.h} mm
            </div>
        )
    }
    else if (props.step.id==='retroporte_b') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                L1: {stepData.l1} mm<br/>
                L2: {stepData.l2} mm<br/>
                H: {stepData.h} mm
            </div>
        )
    }
    else if (props.step.id==='opzioni') {
        const getLabel = (option) => {
            let label = ''

            if (option==='opt_1') {
                label = 'Cavallotto rettangolare base rettangolare 2 fori alto'
            }
            else if (option==='opt_2') {
                label = 'Cavallotto rettangolare base rettangolare 2 fori basso'
            }
            else if (option==='opt_3') {
                label = 'Cavallotto sagomato a pipa'
            }
            else if (option==='opt_4') {
                label = 'Cavallotto tourniquet a molla zincato'
            }
            else if (option==='opt_5') {
                label = 'Cavetto TIR d. 6 mm lunghezza 22 m in acciaio'
            }
            else if (option==='opt_6') {
                label = 'Cavetto TIR d. 6 mm lunghezza 28 m in acciaio'
            }
            else if (option==='opt_7') {
                label = 'Cavetto TIR d. 6 mm lunghezza 34 m in acciaio'
            }
            else if (option==='opt_8') {
                label = 'Cavetto TIR d. 6 mm lunghezza 36 m in acciaio'
            }
            else if (option==='opt_9') {
                label = 'Cavetto TIR d. 6 mm lunghezza 40 m in acciaio'
            }
            else if (option==='opt_10') {
                label = 'Cinturino C 25 telato x tetti'
            }
            else if (option==='opt_11') {
                label = 'Cinghia TIR tedesca trasparente larghezza 22 mm'
            }
            else if (option==='opt_12') {
                label = 'Cinturino per tetto lunghezza 25 cm con fibbia'
            }
            else if (option==='opt_13') {
                label = 'Cappetta copri scopri bianca con asole in striscia'
            }
            else if (option==='opt_14') {
                label = 'Coprigiunto altezza 20 mm alluminio anodizzato'
            }
            else if (option==='opt_15') {
                label = 'Corda treccia d. 5 mm PE bianca'
            }
            else if (option==='opt_16') {
                label = 'Corda ritorta d. 6 mm PP bianca'
            }
            else if (option==='opt_17') {
                label = 'Corda elastica d. 8 mm bianca'
            }
            else if (option==='opt_18') {
                label = 'Corda elastica d. 8 mm blu'
            }
            else if (option==='opt_19') {
                label = 'Corda elastica d. 8 mm gialla'
            }
            else if (option==='opt_20') {
                label = 'Corda elastica d. 8 mm grigia'
            }
            else if (option==='opt_21') {
                label = 'Corda elastica d. 8 mm nera'
            }
            else if (option==='opt_22') {
                label = 'Corda elastica d. 8 mm rossa'
            }
            else if (option==='opt_23') {
                label = 'Corda elastica d. 8 mm verde'
            }
            else if (option==='opt_24') {
                label = 'Gancio a becco d\'aquila zincato'
            }
            else if (option==='opt_25') {
                label = 'Gancio cestello d. 8 mm ferro tropicalizzato'
            }
            else if (option==='opt_26') {
                label = 'Gancio cobra nylon bianco'
            }
            else if (option==='opt_27') {
                label = 'Gancio cobra nylon grigio'
            }
            else if (option==='opt_28') {
                label = 'Gancio Nylon Tondo'
            }
            else if (option==='opt_29') {
                label = 'Gancio a S da 45 mm zincato'
            }
            else if (option==='opt_30') {
                label = 'Gancio a S chiuso da 50 mm zincato'
            }
            else if (option==='opt_31') {
                label = 'Gancio nylon a S larga bianco'
            }
            else if (option==='opt_32') {
                label = 'Gancio Zig-Zag Doppio Nylon Bianco'
            }
            else if (option==='opt_33') {
                label = 'Gancio Zig-Zag Doppio Nylon Nero'
            }
            else if (option==='opt_34') {
                label = 'Gancio a zig-zag grande zincato'
            }
            else if (option==='opt_35') {
                label = 'Gancio a zig-zag piccolo zincato'
            }
            else if (option==='opt_36') {
                label = 'Gancio a zig-zag chiuso in nylon bianco'
            }
            else if (option==='opt_37') {
                label = 'Gancio a zig-zag aperto in nylon bianco'
            }
            else if (option==='opt_38') {
                label = 'GGancio a zig-zag chiuso in nylon nero'
            }
            else if (option==='opt_39') {
                label = 'Occhiello TIR ovale 42 x 22 mm Aluzink'
            }
            else if (option==='opt_40') {
                label = 'Occhiello VL 50 ottone nichel x macchina automatica'
            }
            else if (option==='opt_41') {
                label = 'Occhiello VL 80 ottone nichel x macchina automatica'
            }
            else if (option==='opt_42') {
                label = 'Occhiello VL 70 ottone x macchina automatica'
            }
            else if (option==='opt_43') {
                label = 'Occhiello VL 150 ferro zincato x macchina automatica'
            }
            else if (option==='opt_44') {
                label = 'Piatto 20 x 4 mm lunghezza 2,50 m in PVC'
            }
            else if (option==='opt_45') {
                label = 'Piatto 20 x 4 mm in alluminio anodizzato'
            }
            else if (option==='opt_46') {
                label = 'Tubetto in PVC Desmopan vuoto d. 7 mm in rotolo'
            }

            return label
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string' && value[1]){
                        return (
                            <div key={i}>{getLabel(value[0])}: {value[1]}</div>
                        )
                    }
                    else {
                        return (
                            null
                        )
                    }
                })}
            </div>
        )
    }
    else if (props.step.id==='opzioni_prontocar') {
        const getLabel = (option) => {
            let label = ''

            if (option==='opt_1') {
                label = 'Cinghia spalmata PRONTOCAR bianca'
            }
            else if (option==='opt_2') {
                label = 'Cinghia spalmata PRONTOCAR grigia'
            }
            else if (option==='opt_3') {
                label = 'Cricchetto PRONTOCAR a scatto gancio tranciato 70 cm nero'
            }
            else if (option==='opt_4') {
                label = 'Cricchetto PRONTOCAR a scatto gancio tranciato 100 cm nero'
            }
            else if (option==='opt_5') {
                label = 'Cricchetto PRONTOCAR a molla gancio tranciato 70 cm nero'
            }
            else if (option==='opt_6') {
                label = 'Cricchetto PRONTOCAR plastica rosso gancio tondo 70 cm'
            }
            else if (option==='opt_7') {
                label = 'Cricchetto PRONTOCAR diretto Inox'
            }
            else if (option==='opt_8') {
                label = 'Cinghia con gancio tranciato per cricchetto PRONTOCAR grigia'
            }
            else if (option==='opt_9') {
                label = 'Cinghia con gancio tranciato per cricchetto PRONTOCAR nera'
            }
            else if (option==='opt_10') {
                label = 'Cinghia con gancio tondo per cricchetto PRONTOCAR nera'
            }
            else if (option==='opt_11') {
                label = 'Cinghia con gancio tranciato per cricchetto PRONTOCAR nera 100 cm'
            }
            else if (option==='opt_12') {
                label = 'Asta tenditelo alluminio d. 27 mm lunghezza 3,3 m'
            }
            else if (option==='opt_13') {
                label = 'Asta tenditelo alluminio d. 34 mm lunghezza 6 m'
            }
            else if (option==='opt_14') {
                label = 'Perno inferiore per asta d. 27 mm innesto quadro'
            }
            else if (option==='opt_15') {
                label = 'Perno inferiore per asta d. 27 mm innesto tondo'
            }
            else if (option==='opt_16') {
                label = 'Perno superiore per asta d. 27 mm in ottone'
            }
            else if (option==='opt_17') {
                label = 'Perno inferiore per asta d. 34 mm'
            }
            else if (option==='opt_18') {
                label = 'Perno superiore per asta d. 34 mm in ottone'
            }
            else if (option==='opt_19') {
                label = 'Perno adattatore per asta da d. 34 mm a 27 mm'
            }
            else if (option==='opt_20') {
                label = 'Perno adattatore per asta da d. 27 mm a 34 mm'
            }
            else if (option==='opt_21') {
                label = 'Perno adattatore avvolgente per asta da d. 34 mm a 27 mm'
            }
            else if (option==='opt_22') {
                label = 'Perno avvolgente inferiore per asta d. 34 mm'
            }
            else if (option==='opt_23') {
                label = 'Profilo Tensotel da 60 mm alluminio anodizzato tipo nuovo'
            }
            else if (option==='opt_24') {
                label = 'Profilo Tensotel da 55 mm alluminio anodizzato tipo vecchio '
            }
            else if (option==='opt_25') {
                label = 'Profilo Tensotel da 60 mm alluminio anodizzato tipo vecchio'
            }
            else if (option==='opt_26') {
                label = 'Profilo Tensotel da 35 mm alluminio anodizzato tipo nuovo'
            }
            else if (option==='opt_27') {
                label = 'Profilo a Z zincato'
            }
            else if (option==='opt_28') {
                label = 'Tubetto in PVC d. 10 mm lunghezza 3 m nero'
            }
            else if (option==='opt_29') {
                label = 'Tubetto in PVC d. 12 mm lunghezza 3 m nero'
            }
            else if (option==='opt_30') {
                label = 'Nastro Reflex adesivo argento per PVC segmentato'
            }
            else if (option==='opt_31') {
                label = 'Nastro Reflex adesivo giallo per PVC segmentato'
            }

            return label
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string' && value[1]){
                        return (
                            <div key={i}>{getLabel(value[0])}: {value[1]}</div>
                        )
                    }
                    else {
                        return (
                            null
                        )
                    }
                })}
            </div>
        )
    }
    else if (props.step.id==='opzioni_rollfix_planeroller') {
        const getLabel = (option) => {
            let label = ''

            if (option==='opt_1') {
                label = 'Profilo angolare 40 x 20 x 2 mm in alluminio'
            }
            else if (option==='opt_2') {
                label = 'Keder d. 10 mm PVC bianco monoala'
            }
            else if (option==='opt_3') {
                label = 'Keder d. 7,5 mm PVC bianco monoala'
            }
            else if (option==='opt_4') {
                label = 'Calotta perno tondo d. 43 mm PVC bianca'
            }
            else if (option==='opt_5') {
                label = 'Calotta perno tondo d. 60 mm bianca'
            }
            else if (option==='opt_6') {
                label = 'Molla tiro diretto d. 43 x 600 mm'
            }
            else if (option==='opt_7') {
                label = 'Molla tiro diretto d. 60 x 700 mm'
            }
            else if (option==='opt_8') {
                label = 'Molla inox lunghezza 1500 mm'
            }
            else if (option==='opt_9') {
                label = 'Molla inox lunghezza 1100 mm'
            }
            else if (option==='opt_10') {
                label = 'Molla inox lunghezza 2000 mm'
            }
            else if (option==='opt_11') {
                label = 'Binario Rollfix in alluminio anodizzato'
            }
            else if (option==='opt_12') {
                label = 'Supporto per molla d. 43 mm'
            }
            else if (option==='opt_13') {
                label = 'Supporto per molla d. 60 mm'
            }
            else if (option==='opt_14') {
                label = 'Tappo per profilo Rollfix in PVC'
            }
            else if (option==='opt_15') {
                label = 'Profilo con ogiva d. 43 mm lunghezza 2,55 m'
            }
            else if (option==='opt_16') {
                label = 'Profilo con ogiva d. 60 mm lunghezza 2,6 m'
            }

            return label
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string' && value[1]){
                        return (
                            <div key={i}>{getLabel(value[0])}: {value[1]}</div>
                        )
                    }
                    else {
                        return (
                            null
                        )
                    }
                })}
            </div>
        )
    }
    else if (props.step.id==='note') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Note Aggiuntive:</span>
                {stepData.note_aggiuntive}
                <span className="item-subtitle">Altro:</span>
                {stepData.altro}
            </div>
        )
    }
    else {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string'){
                        return (
                            value[1] + ' '
                        )
                    }
                    else {
                        let returnStr = ''

                        for (let item in value[1]) {
                            returnStr += value[1][item] + ' '
                        }

                        return returnStr
                    }
                })}
            </div>
        )
    }
}

export default NavItem;
