import React from 'react';
import { useHistory } from "react-router-dom";

const PrevButton = (props) => {
    let history = useHistory()

    const goBack = () => {
        if (props.currentStep.substep==null || props.currentStep.substep<=0) {
            history.goBack()
        }
        else {
            props.updateStep(props.currentStep.id, props.currentStep.name, props.currentStep.next, null, props.currentStep.substep - 1, props.currentStep.subSteps)
        }
    }

    return (
        <button onClick={goBack} className="btn btn-link btn-icon btn-icon__prepended btn-prev">
            <span className="d-none d-lg-inline">Indietro</span>
            <span className="icon">
                <svg className="svg-icon svg-icon--arrow svg-icon--arrow-prev" viewBox="0 0 20 12" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                    <use xlinkHref="#svg-icon__arrow"></use>
                </svg>
            </span>
        </button>
    )
}

export default PrevButton;
