import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';
import FormSelect from '../Form/Select';

class ColoreTetto extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['colore_tetto'];

        this.state = {
            nextStep: 'nodo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <div className="step step__colore-tetto">
                        <div className="row justify-content-center">
                            <div className="col-22 col-md-8 col-lg-6">
                                <FormRadio name="colore_tetto" id="colore_tetto1" value="Monocolore bianco" label="Monocolore bianco" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_tetto.colore_tetto === 'Monocolore bianco'} />
                            </div>
                            <div className="col-22 col-md-8 col-lg-6 offset-lg-1">
                                <FormRadio name="colore_tetto" id="colore_tetto2" value="Bianco con fascia nera" label="Bianco con fascia nera" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_tetto.colore_tetto === 'Bianco con fascia nera'} />
                            </div>
                            <div className="col-22 col-md-8 col-lg-6 offset-lg-1">
                                <FormRadio name="colore_tetto" id="colore_tetto3" value="Fascia Colore" label="Fascia Colore" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_tetto.colore_tetto === 'Fascia Colore'}>
                                    <div>
                                        <span className="title">Fascia Colore</span>
                                        <FormSelect type="text" name="colore_tetto_colore" id="colore_tetto_colore" value={this.props.stepsData[this.props.currentType].colore_tetto.colore_tetto_colore} label="Colore" handleChange={this.handleInputChange}>
                                            <option value="">Seleziona</option>
                                            <option value="Il cliente non ha ancora deciso">Il cliente non ha ancora deciso</option>
                                            <option value="ARANCIO 244 – RAL 2004">ARANCIO 244 – RAL 2004</option>
                                            <option value="ARGENTO – RAL 9006">ARGENTO – RAL 9006</option>
                                            <option value="AZZURRO 581 – RAL 5015">AZZURRO 581 – RAL 5015</option>
                                            <option value="BEIGE 123 – RAL 1014">BEIGE 123 – RAL 1014</option>
                                            <option value="BIANCO – RAL 9010">BIANCO – RAL 9010</option>
                                            <option value="BLU 537 – RAL 5010">BLU 537 – RAL 5010</option>
                                            <option value="BLU 543">BLU 543</option>
                                            <option value="BLU 566 – RAL 5013">BLU 566 – RAL 5013</option>
                                            <option value="GIALLO 119">GIALLO 119</option>
                                            <option value="GIALLO 148 – RAL 1003">GIALLO 148 – RAL 1003</option>
                                            <option value="GIALLO 168 – RAL 1018">GIALLO 168 – RAL 1018</option>
                                            <option value="GRIGIO 705">GRIGIO 705</option>
                                            <option value="GRIGIO 712">GRIGIO 712</option>
                                            <option value="LAGUNE 586 - Blu laguna">LAGUNE 586 - Blu laguna</option>
                                            <option value="GRIGIO 729 – RAL 7035">GRIGIO 729 – RAL 7035</option>
                                            <option value="GRIGIO 763 – RAL 7037">GRIGIO 763 – RAL 7037</option>
                                            <option value="NERO – RAL 9005">NERO – RAL 9005</option>
                                            <option value="ROSSO 370 – RAL 3002">ROSSO 370 – RAL 3002</option>
                                            <option value="VERDE 636 – RAL 6026">VERDE 636 – RAL 6026</option>
                                            <option value="VERDE 682 – RAL 6028">VERDE 682 – RAL 6028</option>
                                        </FormSelect>
                                    </div>
                                </FormRadio>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateColoreTetto = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].colore_tetto

    if(!currentStep.colore_tetto) {
        isValid = false
    }
    else if(currentStep.colore_tetto==='Fascia Colore' && !currentStep.colore_tetto_colore){
        isValid = false
    }

    return isValid;
}

export default withRouter(ColoreTetto)
