import React from 'react';

const Svgicons = (props) => {
    return (
        <div className="d-none">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <symbol id="svg-icon__logo" viewBox="0 0 598 223" fill="currentColor">
                        <path className="svg-fill svg-fill--secondary" d="M16.7298,1.144 C16.7298,1.144 9.5298,-1.758 7.2458,5.474 C4.9608,12.707 2.0428,22.476 1.7898,41.635 C1.7898,41.635 1.7898,44.935 5.4688,45.823 C9.1488,46.711 102.5338,80.842 175.9988,8.773 C175.9988,8.773 176.9288,7.377 177.1828,6.362 C177.1828,6.362 177.7748,2.725 172.5308,4.078 C172.5308,4.078 97.0108,36.045 18.3548,1.891 C18.3358,1.883 17.7198,1.6 17.7028,1.59 L16.7298,1.144 Z"></path>
                        <path d="M0.9017,56.2905 C0.9017,56.2905 -2.6933,91.0345 23.9307,104.8225 C23.9307,104.8225 29.1117,107.8675 45.0557,114.2115 C45.0557,114.2115 46.1987,114.6565 46.1987,115.9035 C46.1987,115.9035 37.2747,208.4435 103.5907,222.6535 C103.5907,222.6535 105.6217,223.0775 104.5217,221.5535 C104.5217,221.5535 59.0127,163.8235 106.7207,104.9495 C106.7207,104.9495 162.9717,71.7485 183.7807,20.1495 C183.7807,20.1495 183.4427,17.5275 181.3277,19.4735 C181.3277,19.4735 99.8697,93.4035 3.1857,57.3685 L0.9017,56.2905 Z"></path>
                        <path d="M214.3558,101.3305 L214.3558,57.3595 L199.6158,57.3595 C198.6228,57.3595 197.9128,57.0355 197.4808,56.3825 C197.0458,55.7265 196.8328,54.6955 196.8328,53.2805 L196.8328,50.7515 C196.8328,49.3365 197.0458,48.3025 197.4808,47.6495 C197.9128,46.9975 198.6228,46.6725 199.6158,46.6725 L243.4718,46.6725 C244.4598,46.6725 245.1698,46.9975 245.6018,47.6495 C246.0368,48.3025 246.2538,49.3365 246.2538,50.7515 L246.2538,53.2805 C246.2538,54.6955 246.0368,55.7265 245.6018,56.3825 C245.1698,57.0355 244.4598,57.3595 243.4718,57.3595 L228.7268,57.3595 L228.7268,101.3305 C228.7268,103.0745 226.8728,103.9445 223.1668,103.9445 L219.9198,103.9445 C216.2098,103.9445 214.3558,103.0745 214.3558,101.3305"></path>
                        <path d="M254.9212,103.0049 C254.3302,102.4879 254.0382,101.6589 254.0382,100.5179 L254.0382,49.9359 C254.0382,48.7949 254.3302,47.9659 254.9212,47.4489 C255.5082,46.9319 256.4512,46.6729 257.7482,46.6729 L290.2952,46.6729 C291.2802,46.6729 291.9942,46.9969 292.4252,47.6499 C292.8592,48.3019 293.0732,49.3369 293.0732,50.7519 L293.0732,53.2799 C293.0732,54.6959 292.8592,55.7259 292.4252,56.3819 C291.9942,57.0349 291.2802,57.3589 290.2952,57.3589 L268.3192,57.3589 L268.3192,69.1909 L287.7922,69.1909 C288.7762,69.1909 289.4902,69.5149 289.9222,70.1669 C290.3532,70.8189 290.5702,71.8539 290.5702,73.2699 L290.5702,75.7969 C290.5702,77.2129 290.3532,78.2429 289.9222,78.8959 C289.4902,79.5479 288.7762,79.8769 287.7922,79.8769 L268.3192,79.8769 L268.3192,93.0899 L290.3862,93.0899 C291.3742,93.0899 292.0842,93.4189 292.5202,94.0709 C292.9502,94.7239 293.1682,95.7579 293.1682,97.1699 L293.1682,99.7019 C293.1682,101.1169 292.9502,102.1479 292.5202,102.7999 C292.0842,103.4519 291.3742,103.7809 290.3862,103.7809 L257.7482,103.7809 C256.4512,103.7809 255.5082,103.5219 254.9212,103.0049"></path>
                        <path d="M305.5453,103.0049 C304.9533,102.4879 304.6623,101.6589 304.6623,100.5179 L304.6623,49.1189 C304.6623,47.3789 306.5173,46.5089 310.2273,46.5089 L313.3793,46.5089 C317.0883,46.5089 318.9433,47.3789 318.9433,49.1189 L318.9433,93.0899 L338.9703,93.0899 C339.9583,93.0899 340.6683,93.4189 341.1043,94.0709 C341.5343,94.7239 341.7523,95.7579 341.7523,97.1699 L341.7523,99.7019 C341.7523,101.1169 341.5343,102.1479 341.1043,102.7999 C340.6683,103.4519 339.9583,103.7809 338.9703,103.7809 L308.3723,103.7809 C307.0753,103.7809 306.1313,103.5219 305.5453,103.0049"></path>
                        <path d="M387.6527,89.0112 C390.1837,85.8592 391.4527,81.2632 391.4527,75.2272 C391.4527,69.1902 390.1837,64.6062 387.6527,61.4792 C385.1167,58.3522 381.3777,56.7882 376.4327,56.7882 C371.4837,56.7882 367.7287,58.3522 365.1637,61.4792 C362.5987,64.6062 361.3187,69.1902 361.3187,75.2272 C361.3187,81.2632 362.5987,85.8592 365.1637,89.0112 C367.7287,92.1672 371.4837,93.7472 376.4327,93.7472 C381.3777,93.7472 385.1167,92.1672 387.6527,89.0112 M354.3627,96.8862 C349.2327,91.8552 346.6687,84.6362 346.6687,75.2272 C346.6687,65.8172 349.2327,58.5982 354.3627,53.5672 C359.4927,48.5362 366.8507,46.0202 376.4327,46.0202 C386.0107,46.0202 393.3517,48.5362 398.4537,53.5672 C403.5497,58.5982 406.1027,65.8172 406.1027,75.2272 C406.1027,84.6362 403.5497,91.8552 398.4537,96.8862 C393.3517,101.9172 386.0107,104.4332 376.4327,104.4332 C366.8507,104.4332 359.4927,101.9172 354.3627,96.8862"></path>
                        <path d="M416.6693,101.3305 L416.6693,49.1185 C416.6693,47.3785 418.5243,46.5085 422.2343,46.5085 L426.2193,46.5085 C427.7043,46.5085 428.9403,46.6605 429.9293,46.9555 C430.9183,47.2555 431.7223,47.8425 432.3383,48.7125 L453.2013,77.0195 C454.4373,78.6005 455.5533,80.2005 456.5413,81.8335 L456.9103,81.7515 C456.7873,80.4995 456.7263,78.6535 456.7263,76.2035 L456.7263,49.1185 C456.7263,47.3785 458.5813,46.5085 462.2913,46.5085 L464.9793,46.5085 C468.6893,46.5085 470.5393,47.3785 470.5393,49.1185 L470.5393,101.3305 C470.5393,103.0745 468.6893,103.9445 464.9793,103.9445 L461.4533,103.9445 C459.9723,103.9445 458.7323,103.7925 457.7433,103.4935 C456.7553,103.1935 455.9503,102.6105 455.3353,101.7415 L434.0083,73.0225 C432.7733,71.4475 431.6603,69.8425 430.6713,68.2095 L430.2983,68.2915 C430.4213,69.5425 430.4863,71.3895 430.4863,73.8395 L430.4863,101.3305 C430.4863,103.0745 428.6323,103.9445 424.9223,103.9445 L422.2343,103.9445 C418.5243,103.9445 416.6693,103.0745 416.6693,101.3305"></path>
                        <path d="M482.8714,101.3305 L482.8714,49.1185 C482.8714,47.3255 484.7254,46.4265 488.4354,46.4265 L491.6824,46.4265 C495.3874,46.4265 497.2424,47.3255 497.2424,49.1185 L497.2424,101.3305 C497.2424,103.1275 495.3874,104.0225 491.6824,104.0225 L488.4354,104.0225 C484.7254,104.0225 482.8714,103.1275 482.8714,101.3305"></path>
                        <path d="M229.5296,125.5859 L229.5296,171.5449 C229.5296,174.2329 226.7596,176.5839 223.6066,176.5839 C220.4576,176.5839 217.6846,174.2329 217.6846,171.5449 L217.6846,125.5859 L204.1236,125.5859 C200.8776,125.5859 198.1076,123.2359 198.1076,120.5479 C198.1076,117.7739 200.8776,115.4199 204.1236,115.4199 L243.1876,115.4199 C246.3416,115.4199 249.1096,117.7739 249.1096,120.5479 C249.1096,123.2359 246.3416,125.5859 243.1876,125.5859 L229.5296,125.5859 Z"></path>
                        <path d="M264.2005,145.9189 C264.2005,158.5209 270.2165,167.0899 281.2965,167.0899 C292.2785,167.0899 298.2985,158.5209 298.2985,145.9189 C298.2985,133.3179 292.2785,124.6609 281.2965,124.6609 C270.2165,124.6609 264.2005,133.3179 264.2005,145.9189 M310.1385,145.8349 C310.1385,165.4959 298.4875,177.2559 281.2965,177.2559 C264.0065,177.2559 252.3555,165.4959 252.3555,145.8349 C252.3555,126.2589 264.0065,114.4139 281.2965,114.4139 C298.2985,114.4139 310.1385,126.2589 310.1385,145.8349"></path>
                        <path d="M364.6781,127.9414 C364.6781,130.9634 362.0021,133.3984 358.6581,133.3984 C355.8901,133.3984 353.8861,131.7194 352.9311,129.1164 C352.4531,127.7714 352.0691,126.7614 350.2551,125.5864 C348.7291,124.5804 346.6281,124.0734 344.5271,124.0734 C339.6531,124.0734 335.2611,126.8454 335.2611,131.4664 C335.2611,136.0064 339.5601,137.8534 345.0041,139.6164 C349.1101,140.9614 354.8411,142.4744 358.8531,145.1634 C363.1481,148.0204 366.1121,152.2174 366.1121,158.7744 C366.1121,171.2074 355.5091,177.2554 343.5721,177.2554 C334.2081,177.2554 326.6671,173.4764 323.3241,167.2584 C322.5581,165.8304 321.7931,163.8974 321.7931,162.3844 C321.7931,159.4464 324.4691,157.1764 327.7151,157.1764 C330.1971,157.1764 332.3021,158.6054 333.2571,160.7064 C334.4031,163.2254 334.8801,163.8974 336.2161,164.9084 C338.1271,166.4174 340.8961,167.1734 343.8551,167.1734 C349.4901,167.1734 354.2671,164.3164 354.2671,159.1934 C354.2671,154.5724 350.2551,152.7254 345.0981,151.1274 C339.6531,149.4494 334.8801,147.9354 330.7721,145.2474 C326.5701,142.4744 323.3241,138.3564 323.3241,131.9734 C323.3241,121.3844 332.3951,114.4144 344.5271,114.4144 C351.5951,114.4144 357.8981,116.7644 361.8121,121.5534 C363.4351,123.5704 364.6781,125.9244 364.6781,127.9414"></path>
                        <path d="M391.804,125.5859 L391.804,139.9549 L412.243,139.9549 C415.202,139.9549 417.781,142.0559 417.781,144.5749 C417.781,147.1789 415.202,149.3639 412.243,149.3639 L391.804,149.3639 L391.804,165.9989 L415.68,165.9989 C418.926,165.9989 421.602,168.4339 421.602,171.1219 C421.602,173.8959 418.926,176.2499 415.68,176.2499 L385.882,176.2499 C382.729,176.2499 379.959,173.8959 379.959,171.1219 L379.959,120.5479 C379.959,117.7739 382.729,115.4199 385.882,115.4199 L415.68,115.4199 C418.926,115.4199 421.602,117.7739 421.602,120.5479 C421.602,123.2359 418.926,125.5859 415.68,125.5859 L391.804,125.5859 Z"></path>
                        <path d="M460.3831,125.5859 L460.3831,171.5449 C460.3831,174.2329 457.6131,176.5839 454.4601,176.5839 C451.3111,176.5839 448.5381,174.2329 448.5381,171.5449 L448.5381,125.5859 L434.9771,125.5859 C431.7311,125.5859 428.9611,123.2359 428.9611,120.5479 C428.9611,117.7739 431.7311,115.4199 434.9771,115.4199 L474.0411,115.4199 C477.1951,115.4199 479.9631,117.7739 479.9631,120.5479 C479.9631,123.2359 477.1951,125.5859 474.0411,125.5859 L460.3831,125.5859 Z"></path>
                        <path d="M516.5413,125.5859 L516.5413,171.5449 C516.5413,174.2329 513.7723,176.5839 510.6193,176.5839 C507.4703,176.5839 504.6963,174.2329 504.6963,171.5449 L504.6963,125.5859 L491.1363,125.5859 C487.8893,125.5859 485.1203,123.2359 485.1203,120.5479 C485.1203,117.7739 487.8893,115.4199 491.1363,115.4199 L530.1993,115.4199 C533.3533,115.4199 536.1223,117.7739 536.1223,120.5479 C536.1223,123.2359 533.3533,125.5859 530.1993,125.5859 L516.5413,125.5859 Z"></path>
                        <path d="M551.2132,145.9189 C551.2132,158.5209 557.2292,167.0899 568.3082,167.0899 C579.2902,167.0899 585.3102,158.5209 585.3102,145.9189 C585.3102,133.3179 579.2902,124.6609 568.3082,124.6609 C557.2292,124.6609 551.2132,133.3179 551.2132,145.9189 M597.1512,145.8349 C597.1512,165.4959 585.5002,177.2559 568.3082,177.2559 C551.0192,177.2559 539.3682,165.4959 539.3682,145.8349 C539.3682,126.2589 551.0192,114.4139 568.3082,114.4139 C585.3102,114.4139 597.1512,126.2589 597.1512,145.8349"></path>
                    </symbol>

                    <symbol id="svg-icon__logo-symbol" viewBox="0 0 35 43" fill="currentColor">
                        <path className="svg-fill svg-fill--secondary" d="M3.04968,0.1098 C3.04968,0.1098 1.67568,-0.4434 1.24128,0.9354 C0.80568,2.3142 0.24888,4.1778 0.19968,7.8318 C0.19968,7.8318 0.19968,8.4606 0.90168,8.6298 C1.60368,8.799 19.41168,15.3078 33.42168,1.5642 C33.42168,1.5642 33.59928,1.299 33.64728,1.1046 C33.64728,1.1046 33.76008,0.411 32.75928,0.669 C32.75928,0.669 18.35808,6.765 3.35928,0.2526 C3.35568,0.2502 3.23808,0.1962 3.23448,0.195 L3.04968,0.1098 Z"></path>
                        <path d="M0.03084,10.62564 C0.03084,10.62564 -0.65436,17.25084 4.42284,19.88004 C4.42284,19.88004 5.41044,20.46204 8.45124,21.67164 C8.45124,21.67164 8.66844,21.75564 8.66844,21.99444 C8.66844,21.99444 6.96684,39.64044 19.61364,42.35004 C19.61364,42.35004 20.00124,42.43044 19.79124,42.14124 C19.79124,42.14124 11.11284,31.13124 20.21004,19.90524 C20.21004,19.90524 30.93684,13.57404 34.90524,3.73404 C34.90524,3.73404 34.84044,3.23364 34.43724,3.60444 C34.43724,3.60444 18.90444,17.70324 0.46644,10.83084 L0.03084,10.62564 Z"></path>
                    </symbol>

                    <symbol id="svg-icon__logo-symbol-monocolor" viewBox="0 0 35 43" fill="currentColor">
                        <path d="M3.04968,0.1098 C3.04968,0.1098 1.67568,-0.4434 1.24128,0.9354 C0.80568,2.3142 0.24888,4.1778 0.19968,7.8318 C0.19968,7.8318 0.19968,8.4606 0.90168,8.6298 C1.60368,8.799 19.41168,15.3078 33.42168,1.5642 C33.42168,1.5642 33.59928,1.299 33.64728,1.1046 C33.64728,1.1046 33.76008,0.411 32.75928,0.669 C32.75928,0.669 18.35808,6.765 3.35928,0.2526 C3.35568,0.2502 3.23808,0.1962 3.23448,0.195 L3.04968,0.1098 Z"></path>
                        <path d="M0.03084,10.62564 C0.03084,10.62564 -0.65436,17.25084 4.42284,19.88004 C4.42284,19.88004 5.41044,20.46204 8.45124,21.67164 C8.45124,21.67164 8.66844,21.75564 8.66844,21.99444 C8.66844,21.99444 6.96684,39.64044 19.61364,42.35004 C19.61364,42.35004 20.00124,42.43044 19.79124,42.14124 C19.79124,42.14124 11.11284,31.13124 20.21004,19.90524 C20.21004,19.90524 30.93684,13.57404 34.90524,3.73404 C34.90524,3.73404 34.84044,3.23364 34.43724,3.60444 C34.43724,3.60444 18.90444,17.70324 0.46644,10.83084 L0.03084,10.62564 Z"></path>
                    </symbol>

                    <symbol id="svg-icon__list" viewBox="0 0 23 15" fill="currentColor">
                        <circle cx="1.35" cy="1.35" r="1.35"></circle>
                        <rect x="6" y="0.6" width="15.444" height="1.5" rx="0.75"></rect>
                        <rect x="6" y="6.6" width="16.7112" height="1.5" rx="0.75"></rect>
                        <rect x="6" y="12.6" width="10.9692" height="1.5" rx="0.75"></rect>
                        <circle cx="1.35" cy="7.35" r="1.35"></circle>
                        <circle cx="1.35" cy="13.35" r="1.35"></circle>
                    </symbol>

                    <symbol id="svg-icon__close" viewBox="0 0 16 16" fill="currentColor">
                        <path d="M-0.206144954,-0.524915027 C0.0574028315,-0.793872885 0.4737991,-0.822310626 0.769608653,-0.607450245 L0.854460482,-0.535690409 L14.6953696,13.0268096 C14.9912232,13.3167122 14.9960475,13.7915614 14.706145,14.087415 C14.4425972,14.3563729 14.0262009,14.3848106 13.7303913,14.1699502 L13.6455395,14.0981904 L-0.195369573,0.535690409 C-0.491223216,0.245787844 -0.496047519,-0.229061384 -0.206144954,-0.524915027 Z"></path>
                        <path d="M-0.206144954,-0.524915027 C0.0574028315,-0.793872885 0.4737991,-0.822310626 0.769608653,-0.607450245 L0.854460482,-0.535690409 L14.6953696,13.0268096 C14.9912232,13.3167122 14.9960475,13.7915614 14.706145,14.087415 C14.4425972,14.3563729 14.0262009,14.3848106 13.7303913,14.1699502 L13.6455395,14.0981904 L-0.195369573,0.535690409 C-0.491223216,0.245787844 -0.496047519,-0.229061384 -0.206144954,-0.524915027 Z" transform="translate(7.250000, 6.781250) scale(-1, 1) translate(-7.250000, -6.781250) "></path>
                    </symbol>

                    <symbol id="svg-icon__check" viewBox="0 0 11 9" fill="currentColor">
                        <path d="M5.57223841,-0.185725394 C5.80682689,-0.439128528 6.18632156,-0.478195906 6.46513115,-0.293458685 L6.55400172,-0.223577563 L10.2375087,3.18643669 C10.4401487,3.37403107 10.4523456,3.69037852 10.2647512,3.8930185 C10.0980007,4.07314292 9.82951928,4.10279378 9.62959486,3.97540557 L9.55816941,3.92026097 L6.117,0.735 L0.392923508,8.00921047 C0.241125972,8.20210439 -0.0241205426,8.25315057 -0.233600181,8.14217396 L-0.309210473,8.09292351 C-0.502104394,7.94112597 -0.553150565,7.67587946 -0.442173958,7.46639982 L-0.392923508,7.39078953 L5.5360968,-0.143403725 C5.55360232,-0.165047297 5.55360232,-0.165047297 5.57223841,-0.185725394 Z" transform="translate(4.948910, 3.895760) rotate(-180.000000) translate(-4.948910, -3.895760) "></path>
                    </symbol>

                    <symbol id="svg-icon__arrow" viewBox="0 0 20 12" fill="currentColor">
                        <path d="M13.3347249,-0.410020654 L13.4036904,-0.351828956 L17.9338826,4.22266348 C18.3297883,4.62244069 18.3580673,5.24906171 18.0187195,5.68124518 L17.9338826,5.77733652 L13.4036904,10.351829 C13.2093807,10.5480389 12.8928019,10.5495792 12.696592,10.3552695 C12.5221832,10.1825497 12.5015874,9.91322228 12.6356337,9.71769961 L12.6931515,9.64817104 L16.859,5.441 L0.149712969,5.44153702 C-0.126429406,5.44153702 -0.350287031,5.2176794 -0.350287031,4.94153702 C-0.350287031,4.69607713 -0.17341187,4.49192865 0.0598373369,4.44959269 L0.149712969,4.44153702 L16.743,4.441 L12.6931515,0.351828956 C12.4988418,0.155619054 12.5003821,-0.160959688 12.696592,-0.355269455 C12.8491997,-0.506399274 13.0746231,-0.539051853 13.2586994,-0.45378267 L13.3347249,-0.410020654 Z"></path>
                    </symbol>

                    <symbol id="svg-icon__rotate-device" viewBox="0 0 210 269" fill="currentColor">
                    <defs>
                        <path d="M11.3333333,3.10115471e-06 L19.4699916,3.10115471e-06 C21.2292481,1.50719908e-06 22.781986,1.14944361 23.2956756,2.83203286 C24.4832078,6.72179197 26.7179826,8.66667153 30,8.66667153 C36,8.66667153 70.6666667,8.66667153 76.6666667,8.66667153 C79.9486823,8.66667153 82.1834565,6.72179411 83.3709891,2.83203927 C83.8846728,1.14944611 85.4374126,3.10115471e-06 87.1966711,3.10115471e-06 L96.3333333,3.10115471e-06 C103.144845,3.10115472e-06 108.666667,5.52182452 108.666667,12.3333364 L108.666667,221.555338 C108.666667,229.164717 102.498046,235.333338 94.8886666,235.333338 L13.778,235.333338 C6.16862073,235.333338 1.15114376e-12,229.164717 1.13686838e-12,221.555338 L1.13686838e-12,11.3333364 C1.13610184e-12,5.07410927 5.07410617,3.10115471e-06 11.3333333,3.10115471e-06 Z" id="path-1"></path>
                    </defs>
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(-16.000000, -8.000000)">
                                <g transform="translate(121.000000, 142.500000) rotate(-22.000000) translate(-121.000000, -142.500000) translate(56.000000, 16.000000)">
                                    <g transform="translate(0.000000, 34.666667)" fill="#FFFFFF" stroke="#0A1F8F" strokeWidth="0.666666667">
                                        <path d="M126.581486,24.3333333 L123.666667,24.3333333 L123.666667,53.6666667 L126.581486,53.6666667 C127.675779,53.6666667 128.077281,53.5645104 128.478595,53.3498853 C128.854214,53.149002 129.149002,52.8542138 129.349885,52.4785947 C129.56451,52.0772807 129.666667,51.675779 129.666667,50.5814864 L129.666667,27.4185136 C129.666667,26.324221 129.56451,25.9227193 129.349885,25.5214053 C129.149002,25.1457862 128.854214,24.850998 128.478595,24.6501147 C128.077281,24.4354896 127.675779,24.3333333 126.581486,24.3333333 Z"></path>
                                        <path d="M9,41.6666667 L1.33333333,41.6666667 C1.05719096,41.6666667 0.807190958,41.7785955 0.626226552,41.9595599 C0.445262146,42.1405243 0.333333333,42.3905243 0.333333333,42.6666667 L0.333333333,58.6666667 C0.333333333,58.942809 0.445262146,59.192809 0.626226552,59.3737734 C0.807190958,59.5547379 1.05719096,59.6666667 1.33333333,59.6666667 L9,59.6666667 L9,41.6666667 Z"></path>
                                        <path d="M9,18.3333333 L1.33333333,18.3333333 C1.05719096,18.3333333 0.807190958,18.4452621 0.626226552,18.6262266 C0.445262146,18.807191 0.333333333,19.057191 0.333333333,19.3333333 L0.333333333,35.3333333 C0.333333333,35.6094757 0.445262146,35.8594757 0.626226552,36.0404401 C0.807190958,36.2214045 1.05719096,36.3333333 1.33333333,36.3333333 L9,36.3333333 L9,18.3333333 Z"></path>
                                        <path d="M9,0.333333333 L1.33333333,0.333333333 C1.05719096,0.333333333 0.807190958,0.445262146 0.626226552,0.626226552 C0.445262146,0.807190958 0.333333333,1.05719096 0.333333333,1.33333333 L0.333333333,8.66666667 C0.333333333,8.94280904 0.445262146,9.19280904 0.626226552,9.37377345 C0.807190958,9.55473785 1.05719096,9.66666667 1.33333333,9.66666667 L9,9.66666667 L9,0.333333333 Z"></path>
                                    </g>
                                    <path d="M116.306079,3.55892525 C112.747639,1.65585071 109.211143,0.666666667 99.4844702,0.666666667 L29.9994733,0.667619269 C20.6615938,0.702474476 17.1884178,1.69004777 13.6939208,3.55892525 C10.1868702,5.43451641 7.43451641,8.18687022 5.55892525,11.6939208 C3.65585071,15.2523608 2.66666667,18.788857 2.66666667,28.5155298 L2.66666667,224.151137 C2.66666667,233.87781 3.65585071,237.414306 5.55892525,240.972746 C7.43451641,244.479796 10.1868702,247.23215 13.6939208,249.107741 C17.2523608,251.010816 20.788857,252 30.5155298,252 L99.4844702,252 C109.211143,252 112.747639,251.010816 116.306079,249.107741 C119.81313,247.23215 122.565484,244.479796 124.441075,240.972746 C126.344149,237.414306 127.333333,233.87781 127.333333,224.151137 L127.333333,28.5155298 C127.333333,18.788857 126.344149,15.2523608 124.441075,11.6939208 C122.565484,8.18687022 119.81313,5.43451641 116.306079,3.55892525 Z" stroke="#0A1F8F" strokeWidth="1.33333333" fill="#EDF8FF"></path>
                                    <path d="M99.8824431,3.66666667 L30.1175569,3.66666667 C21.5939415,3.66666667 18.4983878,4.54236973 15.3810245,6.20955387 C12.2893559,7.86299632 9.86299632,10.2893559 8.20955387,13.3810245 C6.54236973,16.4983878 5.66666667,19.5939415 5.66666667,28.1175569 L5.66666667,224.54911 C5.66666667,233.072725 6.54236973,236.168279 8.20955387,239.285642 C9.86299632,242.377311 12.2893559,244.80367 15.3810245,246.457113 C18.4983878,248.124297 21.5939415,249 30.1175569,249 L99.8824431,249 C108.406058,249 111.501612,248.124297 114.618976,246.457113 C117.710644,244.80367 120.137004,242.377311 121.790446,239.285642 C123.45763,236.168279 124.333333,233.072725 124.333333,224.54911 L124.333333,28.1175569 C124.333333,19.5939415 123.45763,16.4983878 121.790446,13.3810245 C120.137004,10.2893559 117.710644,7.86299632 114.618976,6.20955387 C111.501612,4.54236973 108.406058,3.66666667 99.8824431,3.66666667 Z" stroke="#0A1F8F" strokeWidth="0.666666667"></path>
                                    <path d="M66.8518519,9.60999999 L55,9.60999999 C54.800257,9.60999999 54.6194237,9.69096184 54.4885261,9.82185942 C54.3576285,9.95275701 54.2766667,10.1335903 54.2766667,10.3333333 C54.2766667,10.5330763 54.3576285,10.7139097 54.4885261,10.8448072 C54.6194237,10.9757048 54.800257,11.0566667 55,11.0566667 L66.8518519,11.0566667 C67.0515948,11.0566667 67.2324282,10.9757048 67.3633258,10.8448072 C67.4942233,10.7139097 67.5751852,10.5330763 67.5751852,10.3333333 C67.5751852,10.1335903 67.4942233,9.95275701 67.3633258,9.82185942 C67.2324282,9.69096184 67.0515948,9.60999999 66.8518519,9.60999999 Z" stroke="#0A1F8F" strokeWidth="0.553333322"></path>
                                    <ellipse stroke="#0A1F8F" strokeWidth="0.666666667" cx="74.462963" cy="10.3333333" rx="1.87037037" ry="2"></ellipse>
                                    <g fill="#ffffff" transform="translate(10.666667, 8.666667)">
                                        <mask id="mask-2" fill="white">
                                            <use xlinkHref="#path-1"></use>
                                        </mask>
                                        <path stroke="#0A1F8F" strokeWidth="0.666666667" d="M19.4699919,0.333336434 L11.3333333,0.333336434 C8.29576721,0.333336434 5.54576721,1.56455337 3.55515874,3.55516184 C1.56455027,5.54577031 0.333333333,8.29577031 0.333333333,11.3333364 L0.333333333,221.555338 C0.333333333,225.26798 1.83817891,228.629147 4.27118504,231.062153 C6.70419117,233.495159 10.0653578,235.000005 13.778,235.000005 L94.8886666,235.000005 C98.6013088,235.000005 101.962475,233.495159 104.395482,231.062153 C106.828488,228.629147 108.333333,225.26798 108.333333,221.555338 L108.333333,12.3333364 C108.333333,9.01962794 106.990188,6.01962794 104.818615,3.84805506 C102.647042,1.67648218 99.6470418,0.333336434 96.3333333,0.333336434 L87.1966711,0.333336434 C86.3903438,0.333336434 85.6313437,0.596750723 85.015066,1.05296239 C84.3987893,1.50917333 83.9252345,2.15818106 83.6897964,2.92936885 C83.0742256,4.94566657 82.1804608,6.45222182 81.0230875,7.45945973 C79.8466042,8.48332865 78.3957163,9.00000486 76.6666667,9.00000486 L30,9.00000486 C28.2709494,9.00000486 26.8200609,8.48332808 25.6435773,7.45945804 C24.4862036,6.45221905 23.5924388,4.94566218 22.9768687,2.92936367 C22.7414276,2.15817683 22.2678712,1.50916958 21.6515936,1.05295925 C21.0353162,0.596749071 20.2763176,0.333335704 19.4699919,0.333336434 Z"></path>
                                    </g>
                                </g>
                                <g transform="translate(208.500000, 121.000000) rotate(22.000000) translate(-208.500000, -121.000000) translate(183.000000, 93.000000)" fill="#0A1F8F" fillRule="nonzero">
                                    <path d="M2,6 C28.7858104,6 50.5,27.7141896 50.5,54.5 C50.5,54.7761424 50.2761424,55 50,55 C49.7238576,55 49.5,54.7761424 49.5,54.5 C49.5,28.2664744 28.2335256,7 2,7 C1.72385763,7 1.5,6.77614237 1.5,6.5 C1.5,6.22385763 1.72385763,6 2,6 Z"></path>
                                    <path d="M9.89339828,2.5 C10.1695407,2.5 10.3933983,2.72385763 10.3933983,3 C10.3933983,3.24545989 10.2165231,3.44960837 9.98327391,3.49194433 L9.89339828,3.5 L3.393,3.5 L3.39339828,10 C3.39339828,10.2454599 3.21652312,10.4496084 2.98327391,10.4919443 L2.89339828,10.5 C2.64793839,10.5 2.44378991,10.3231248 2.40145395,10.0898756 L2.39339828,10 L2.39339828,2.5 L9.89339828,2.5 Z" transform="translate(6.393398, 6.500000) rotate(-45.000000) translate(-6.393398, -6.500000) "></path>
                                </g>
                                <g transform="translate(33.500000, 171.000000) scale(-1, -1) rotate(22.000000) translate(-33.500000, -171.000000) translate(9.000000, 144.000000)" fill="#0A1F8F" fillRule="nonzero">
                                    <path d="M1,5 C27.7858104,5 49.5,26.7141896 49.5,53.5 C49.5,53.7761424 49.2761424,54 49,54 C48.7238576,54 48.5,53.7761424 48.5,53.5 C48.5,27.2664744 27.2335256,6 1,6 C0.723857625,6 0.5,5.77614237 0.5,5.5 C0.5,5.22385763 0.723857625,5 1,5 Z"></path>
                                    <path d="M8.89339828,1.5 C9.16954066,1.5 9.39339828,1.72385763 9.39339828,2 C9.39339828,2.24545989 9.21652312,2.44960837 8.98327391,2.49194433 L8.89339828,2.5 L2.393,2.5 L2.39339828,9 C2.39339828,9.24545989 2.21652312,9.44960837 1.98327391,9.49194433 L1.89339828,9.5 C1.64793839,9.5 1.44378991,9.32312484 1.40145395,9.08987563 L1.39339828,9 L1.39339828,1.5 L8.89339828,1.5 Z" transform="translate(5.393398, 5.500000) rotate(-45.000000) translate(-5.393398, -5.500000) "></path>
                                </g>
                                <g transform="translate(121.500000, 140.500000) rotate(-22.000000) translate(-121.500000, -140.500000) translate(104.000000, 119.000000)">
                                    <path d="M3.04968,0.1098 C3.04968,0.1098 1.67568,-0.4434 1.24128,0.9354 C0.80568,2.3142 0.24888,4.1778 0.19968,7.8318 C0.19968,7.8318 0.19968,8.4606 0.90168,8.6298 C1.60368,8.799 19.41168,15.3078 33.42168,1.5642 C33.42168,1.5642 33.59928,1.299 33.64728,1.1046 C33.64728,1.1046 33.76008,0.411 32.75928,0.669 C32.75928,0.669 18.35808,6.765 3.35928,0.2526 C3.35568,0.2502 3.23808,0.1962 3.23448,0.195 L3.04968,0.1098 Z" fill="#D7282F"></path>
                                    <path d="M0.03084,10.62564 C0.03084,10.62564 -0.65436,17.25084 4.42284,19.88004 C4.42284,19.88004 5.41044,20.46204 8.45124,21.67164 C8.45124,21.67164 8.66844,21.75564 8.66844,21.99444 C8.66844,21.99444 6.96684,39.64044 19.61364,42.35004 C19.61364,42.35004 20.00124,42.43044 19.79124,42.14124 C19.79124,42.14124 11.11284,31.13124 20.21004,19.90524 C20.21004,19.90524 30.93684,13.57404 34.90524,3.73404 C34.90524,3.73404 34.84044,3.23364 34.43724,3.60444 C34.43724,3.60444 18.90444,17.70324 0.46644,10.83084 L0.03084,10.62564 Z" fill="#0A1F8F"></path>
                                </g>
                            </g>
                        </g>
                    </symbol>
                </defs>
            </svg>
        </div>
    )
}

export default Svgicons;
