import React from "react";
import { withRouter } from "react-router-dom";

class UpdateBodyClass extends React.Component {
    updateBodyClass() {
        let oldClasses = document.body.className.split(' ')
        let newClasses = this.props.location.pathname.split('/')

        for(let i=0;i<oldClasses.length;i++) {
            if (oldClasses[i]) {
                document.body.classList.remove(oldClasses[i])
            }
        }

        for(let i=0;i<newClasses.length;i++) {
            if (newClasses[i]) {
                document.body.classList.add(newClasses[i])
            }
        }
    }

    componentDidMount() {
        this.updateBodyClass()
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.updateBodyClass()
        }
    }

    render() {
        return null //this.props.children
    }
}

export default withRouter(UpdateBodyClass)
