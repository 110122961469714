import React from 'react';
import { withRouter } from "react-router-dom";

import Offline from "react-offline";

class Thankyou extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }
    
    componentDidMount() {
        this.props.updateStep('thankyou', 'Thankyou', 'thankyou')
    }

    render() {
        const handleClick = () => {
            window.location = '/'
        }

        return (
            <div className="thankyou">
                <div className="container-fluid">
                    <div className="thankyou__header">
                        <div className="row">
                            <div className="col-22 offset-1 text-center">
                                <svg className="svg-icon svg-icon--logo" viewBox="0 0 598 223" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                    <use xlinkHref="#svg-icon__logo"></use>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="thankyou__content">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-22 col-md-14 col-lg-12 col-xl-10 text-center">
                                <Offline
                                    render={({ isOffline, isOnline }) => {
                                        if (isOffline) {
                                            return <span className="title">La tua configurazione verrà inoltrata a Teloni Tosetto e salvata nella tua lista non appena sarai online.</span>
                                        }
                                        else {
                                            return <span className="title">La tua configurazione è stata inoltrata a Teloni Tosetto e salvata nella tua lista.</span>
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>    
                <div className="configurator__footer">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-6 col-lg-7 offset-1">
                            </div>
                            <div className="col-16 col-lg-8 text-right text-lg-center">
                                <button onClick={handleClick} className="btn btn-primary btn-lg btn-uppercase btn-apply">
                                    Vai alla dashboard
                                </button>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default withRouter(Thankyou);
