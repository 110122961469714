import React from 'react';

const RadioColor = (props) => {

    const style = {
        backgroundColor: props.hex,
        borderColor: props.hex
    }

    return (
        <div className={"form-radio-color form-radio-color--" + props.hex.replace('#', '')}>
            <input
                type="radio"
                className="form-radio-color__input"
                id={props.id}
                name={props.name}
                value={props.value}
                checked={props.checked}
                onChange={props.handleChange}
                data-hex={props.hex}
                data-id={props.dataId}
            />
            <label className="form-radio-color__label" htmlFor={props.id} style={style}><span>{props.label}</span></label>
        </div>
    )
}

export default RadioColor;
