import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';
import FormRadio from '../Form/Radio';

class EsecuzioneIntermodale extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['esecuzione_intermodale'];

        this.state = {
            nextStep: 'tipologia_carrelli'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__maniglie">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="esecuzione_intermodale" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-16 col-lg-6">
                            <FormRadio name="esecuzione_intermodale" id="esecuzione_intermodale1" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].esecuzione_intermodale.esecuzione_intermodale === 'Sì'} />
                        </div>
                        <div className="col-16 col-lg-6 offset-lg-1">
                            <FormRadio name="esecuzione_intermodale" id="esecuzione_intermodale2" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].esecuzione_intermodale.esecuzione_intermodale === 'No'} /><br/>
                        </div>
                        <div className="w-100"/>
                        <div className={this.props.stepsData[this.props.currentType].esecuzione_intermodale.esecuzione_intermodale === 'Sì' ? 'col-16 col-lg-6' : 'd-none'}>
                            <FormInput type="text" name="ta" id="ta" value={this.props.stepsData[this.props.currentType].esecuzione_intermodale.ta} label="TA" handleChange={this.handleInputChange} />
                        </div>
                        <div className={this.props.stepsData[this.props.currentType].esecuzione_intermodale.esecuzione_intermodale === 'Sì' ? 'col-16 col-lg-6 offset-lg-1' : 'd-none'}>
                            <FormInput type="text" name="tp" id="tp" value={this.props.stepsData[this.props.currentType].esecuzione_intermodale.tp} label="TP" handleChange={this.handleInputChange} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateEsecuzioneIntermodale = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].esecuzione_intermodale

    if(currentStep.esecuzione_intermodale==='Sì') {
        if (!currentStep.ta || !currentStep.tp) {
            isValid = false
        }
    }

    return isValid;
}

export default withRouter(EsecuzioneIntermodale)
