import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import { AuthContext } from "./components/auth";
import PrivateRoute from './components/PrivateRoute';

import Offline from "react-offline";

import ScrollToTop from './components/ScrollToTop';
import UpdateBodyClass from './components/UpdateBodyClass';
import Home from './components/Home';
import Login from './components/Login';
import Configurator from './components/Configurator';
import Svgicons from './components/Svgicons';

const App = () => {
    const existingTokens = {
        email: Cookies.get('teloni_user_email'),
        token: Cookies.get('teloni_user_token'),
    };
    const [authTokens, setAuthTokens] = useState(existingTokens.token);
    
    const setTokens = (data) => {
        Cookies.set('teloni_user_email', data.email, { expires: 15 });
        Cookies.set('teloni_user_token', data.token, { expires: 15 });
        
        setAuthTokens(data);
    }
       
    return (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
            <Router>
                <ScrollToTop />
                <UpdateBodyClass />
                <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <PrivateRoute path="/configurator" component={Configurator} />
                    <PrivateRoute path="/" component={Home} />
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>

                <Offline
                    render={({ isOffline, isOnline }) => {
                        if (isOffline) {
                            return <div className="offline-warning">Sei offline, alcune funzionalità potrebbero non essere disponibili.</div>
                        }
                        else {
                            return null
                        }
                    }}
                />
            </Router>

            <Svgicons />
        </AuthContext.Provider>
    )
}

export default App;
