import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormRadio from '../Form/Radio';
import FormInput from '../Form/Input';

class ReteAntivandalismo extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['rete_antivandalismo'];

        this.state = {
            nextStep: 'tipologia_tetto'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateNextStep = (teli) => {
        let nextStep = 'tipologia_posteriore'

        if (teli!=='Teli laterali') {
            nextStep = 'tipologia_tetto'
        }
        else {
            if (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio' && this.props.currentType==='motrice') {
                nextStep = 'teli'
            }
        }

        this.setState({
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    showFields = () => {
        if (this.props.stepsData[this.props.currentType].rete_antivandalismo.rete_antivandalismo==='' || this.props.stepsData[this.props.currentType].rete_antivandalismo.rete_antivandalismo==='No') {
            return 'col-22 col-lg-20 offset-1 d-none'
        }
        else {
            return 'col-22 col-lg-20 offset-1'
        }
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__rete-antivandalismo">
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep(this.props.stepsData[this.props.currentType].teli.teli)
                    }} />
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="rete_antivandalismo" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-22 col-md-10 col-lg-4 offset-lg-1">
                            <FormRadio name="rete_antivandalismo" id="rete_antivandalismo1" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].rete_antivandalismo.rete_antivandalismo === 'Sì'} />
                        </div>
                        <div className="col-22 col-md-10 col-lg-4 offset-md-1">
                            <FormRadio name="rete_antivandalismo" id="rete_antivandalismo2" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].rete_antivandalismo.rete_antivandalismo === 'No'} />
                        </div>
                        <div className={this.showFields()}>
                            <br/><br/>
                            <div className="row align-items-center justify-content-center align-items-center">
                                <div className="col-24 col-md-6 col-lg-4 col-xl-3">
                                    <FormRadio name="griglia" id="griglia1" value="10x15" label="10x15" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].rete_antivandalismo.griglia === '10x15'} />
                                </div>
                                <div className="col-24 col-md-6 col-lg-4 col-xl-3">
                                    <FormRadio name="griglia" id="griglia2" value="15x15" label="15x15" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].rete_antivandalismo.griglia === '15x15'} />
                                </div>
                                <div className="col-24 col-md-6 col-lg-4 col-xl-3">
                                    <FormRadio name="griglia" id="griglia3" value="20x20" label="20x20" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].rete_antivandalismo.griglia === '20x20'} />
                                </div>
                                <div className="col-24 col-md-6 col-lg-4 col-xl-3">
                                    <FormRadio name="griglia" id="griglia4" value="30x30" label="30x30" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].rete_antivandalismo.griglia === '30x30'} />
                                </div>
                                <div className="col-24 col-md-8 col-lg-5 col-xl-4 no-form-margin-desktop">
                                    <FormInput type="text" name="altezza" id="altezza" value={this.props.stepsData[this.props.currentType].rete_antivandalismo.altezza} label="Altezza mm" handleChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateReteAntivandalismo = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].rete_antivandalismo

    if(!currentStep.rete_antivandalismo) {
        isValid = false
    }
    else if(currentStep.rete_antivandalismo!=='No') {
        if(!currentStep.griglia || !currentStep.altezza) {
            isValid = false
        }
    }

    return isValid;
}

export default withRouter(ReteAntivandalismo)
