import React from 'react';

const Input = (props) => {
    const getLabel = () => {
        let label = props.label

        if(props.optional) {
            label += ' <span>(facoltativo)</span>'
        }

        if(props.note) {
            label += ' <span>(' + props.note + ')</span>'
        }

        return label
    }

    return (
        <div className={props.value ? 'form-input form-input--filled' : 'form-input' }>
            <label htmlFor={props.id} className="form-input__label" dangerouslySetInnerHTML={{__html: getLabel()}}/>
            <input
                type={props.type}
                className="form-input__control form-control"
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
            />
        </div>
    )
}

export default Input;
