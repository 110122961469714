import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';
import FormCheckboxStandard from '../Form/CheckboxStandard';

class Tenditelo extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['tenditelo'];

        this.state = {
            nextStep: 'rete_antivandalismo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        let name = target.name;

        if (target.getAttribute('type') === 'checkbox' && !target.checked) {
            value = ''
        }

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <div className="step step__tenditelo">
                        <div className="step__item">
                            <div className="step__title">Anteriore</div>
                            <div className="row justify-content-center">
                                <div className="col-auto no-form-margin-desktop"><br />
                                    <FormCheckboxStandard name="tenditelo_anteriore_recupera" id="tenditelo_anteriore_recupera" value="Sì" label="Recupera esistente" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore_recupera === 'Sì'} />
                                </div>
                            </div>
                            <div className="scrollable-options">
                                <FormRadio name="tenditelo_anteriore" id="tenditelo_anteriore_0" value="Senza" label="Senza" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Senza'}/>
                                <FormRadio name="tenditelo_anteriore" id="tenditelo_anteriore_1" value="Asta Tenditelo" label="Asta Tenditelo" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Asta Tenditelo'}>
                                    <div>
                                        <span className="title">Asta Tenditelo</span>
                                        <div className="inline">
                                            <FormRadio name="tipologia_anteriore" id="tipologia_anteriore_1" value="27" label="27" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Asta Tenditelo' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_anteriore === '27'} />
                                            <FormRadio name="tipologia_anteriore" id="tipologia_anteriore_2" value="34" label="34" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Asta Tenditelo' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_anteriore === '34'} />
                                        </div>
                                    </div>
                                </FormRadio>
                                <FormRadio name="tenditelo_anteriore" id="tenditelo_anteriore_2" value="Profilo Tensotel TN" label="Profilo Tensotel TN" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Profilo Tensotel TN'}>
                                    <div>
                                        <span className="title">Profilo Tensotel TN</span>
                                        <div className="inline">
                                            <FormRadio name="tipologia_anteriore" id="tipologia_anteriore_3" value="35" label="35" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Profilo Tensotel TN' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_anteriore === '35'} />
                                            <FormRadio name="tipologia_anteriore" id="tipologia_anteriore_4" value="60" label="60" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Profilo Tensotel TN' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_anteriore === '60'} />
                                        </div>
                                    </div>
                                </FormRadio>
                                <FormRadio name="tenditelo_anteriore" id="tenditelo_anteriore_3" value="Profilo Tensotel TV" label="Profilo Tensotel TV" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Profilo Tensotel TV'}>
                                    <div>
                                        <span className="title">Profilo Tensotel TV</span>
                                        <div className="inline">
                                            <FormRadio name="tipologia_anteriore" id="tipologia_anteriore_5" value="55" label="55" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Profilo Tensotel TV' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_anteriore === '55'} />
                                            <FormRadio name="tipologia_anteriore" id="tipologia_anteriore_6" value="60" label="60" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_anteriore === 'Profilo Tensotel TV' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_anteriore === '60'} />
                                        </div>
                                    </div>
                                </FormRadio>
                            </div>
                        </div>
                        <div className="step__item">
                            <div className="step__title">Posteriore</div>
                            <div className="row justify-content-center">
                                <div className="col-auto no-form-margin-desktop"><br />
                                    <FormCheckboxStandard name="tenditelo_posteriore_recupera" id="tenditelo_posteriore_recupera" value="Sì" label="Recupera esistente" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore_recupera === 'Sì'} />
                                </div>
                            </div>
                            <div className="scrollable-options">
                            <FormRadio name="tenditelo_posteriore" id="tenditelo_posteriore_0" value="Senza" label="Senza" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Senza'}/>
                                <FormRadio name="tenditelo_posteriore" id="tenditelo_posteriore_1" value="Asta Tenditelo" label="Asta Tenditelo" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Asta Tenditelo'}>
                                    <div>
                                        <span className="title">Asta Tenditelo</span>
                                        <div className="inline">
                                            <FormRadio name="tipologia_posteriore" id="tipologia_posteriore_7" value="27" label="27" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Asta Tenditelo' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_posteriore === '27'} />
                                            <FormRadio name="tipologia_posteriore" id="tipologia_posteriore_8" value="34" label="34" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Asta Tenditelo' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_posteriore === '34'} />
                                        </div>
                                    </div>
                                </FormRadio>
                                <FormRadio name="tenditelo_posteriore" id="tenditelo_posteriore_2" value="Profilo Tensotel TN" label="Profilo Tensotel TN" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Profilo Tensotel TN'}>
                                    <div>
                                        <span className="title">Tensotel TN</span>
                                        <div className="inline">
                                            <FormRadio name="tipologia_posteriore" id="tipologia_posteriore_9" value="35" label="35" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Profilo Tensotel TN' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_posteriore === '35'} />
                                            <FormRadio name="tipologia_posteriore" id="tipologia_posteriore_10" value="60" label="60" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Profilo Tensotel TN' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_posteriore === '60'} />
                                        </div>
                                    </div>
                                </FormRadio>
                                <FormRadio name="tenditelo_posteriore" id="tenditelo_posteriore_3" value="Profilo Tensotel TV" label="Profilo Tensotel TV" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Profilo Tensotel TV'}>
                                    <div>
                                        <span className="title">Profilo Tensotel TV</span>
                                        <div className="inline">
                                            <FormRadio name="tipologia_posteriore" id="tipologia_posteriore_11" value="55" label="55" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Profilo Tensotel TV' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_posteriore === '55'} />
                                            <FormRadio name="tipologia_posteriore" id="tipologia_posteriore_12" value="60" label="60" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo_posteriore === 'Profilo Tensotel TV' && this.props.stepsData[this.props.currentType].tenditelo.tipologia_posteriore === '60'} />
                                        </div>
                                    </div>
                                </FormRadio>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTenditelo = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].tenditelo

    if(!currentStep.tenditelo_anteriore || !currentStep.tenditelo_posteriore) {
        isValid = false
    }
    // else if(currentStep.tenditelo_anteriore==='Senza' && currentStep.tenditelo_posteriore==='Senza') {
    //     isValid = false
    // }
    else if (
        (currentStep.tenditelo_anteriore==='Asta Tenditelo' && !currentStep.tipologia_anteriore) || (currentStep.tenditelo_posteriore==='Asta Tenditelo' && !currentStep.tipologia_posteriore) || 
        (currentStep.tenditelo_anteriore==='Profilo Tensotel TN' && !currentStep.tipologia_anteriore) || (currentStep.tenditelo_posteriore==='Profilo Tensotel TN' && !currentStep.tipologia_posteriore)
    ) {
        isValid = false
    }

    if (currentStep.tenditelo_anteriore!=='Senza') {
        if (currentStep.tenditelo_anteriore==='Asta Tenditelo' && (currentStep.tipologia_anteriore!=='27' && currentStep.tipologia_anteriore!=='34')) {
            isValid = false
        }

        if (currentStep.tenditelo_anteriore==='Profilo Tensotel TN' && (currentStep.tipologia_anteriore!=='35' && currentStep.tipologia_anteriore!=='60')) {
            isValid = false
        }

        if (currentStep.tenditelo_anteriore==='Profilo Tensotel TV' && (currentStep.tipologia_anteriore!=='55' && currentStep.tipologia_anteriore!=='60')) {
            isValid = false
        }
    }

    if (currentStep.tenditelo_posteriore!=='Senza') {
        if (currentStep.tenditelo_posteriore==='Asta Tenditelo' && (currentStep.tipologia_posteriore!=='27' && currentStep.tipologia_posteriore!=='34')) {
            isValid = false
        }
        
        if (currentStep.tenditelo_posteriore==='Profilo Tensotel TN' && (currentStep.tipologia_posteriore!=='35' && currentStep.tipologia_posteriore!=='60')) {
            isValid = false
        }

        if (currentStep.tenditelo_posteriore==='Profilo Tensotel TV' && (currentStep.tipologia_posteriore!=='55' && currentStep.tipologia_posteriore!=='60')) {
            isValid = false
        }
    }

    return isValid;
}

export default withRouter(Tenditelo)
