import React from 'react';
import { withRouter } from "react-router-dom";

import FormInput from '../Form/Input';

class OpzioniProntocar extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['opzioni_prontocar'];

        this.state = {
            nextStep: 'opzioni_rollfix_planeroller'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData) {
            return (
                <div className="step step__opzioni">
                    <div className="row justify-content-center">
                        <div className="col-22 col-lg-14 col-xl-12">
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia spalmata PRONTOCAR bianca</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_1" id="opt_1" value={this.props.stepsData.opzioni_prontocar.opt_1} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia spalmata PRONTOCAR grigia</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_2" id="opt_2" value={this.props.stepsData.opzioni_prontocar.opt_2} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto PRONTOCAR a scatto gancio tranciato 70 cm nero</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_3" id="opt_3" value={this.props.stepsData.opzioni_prontocar.opt_3} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto PRONTOCAR a scatto gancio tranciato 100 cm nero</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_4" id="opt_4" value={this.props.stepsData.opzioni_prontocar.opt_4} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto PRONTOCAR a molla gancio tranciato 70 cm nero</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_5" id="opt_5" value={this.props.stepsData.opzioni_prontocar.opt_5} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto PRONTOCAR plastica rosso gancio tondo 70 cm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_6" id="opt_6" value={this.props.stepsData.opzioni_prontocar.opt_6} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto PRONTOCAR diretto Inox</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_7" id="opt_7" value={this.props.stepsData.opzioni_prontocar.opt_7} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia con gancio tranciato per cricchetto PRONTOCAR grigia</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_8" id="opt_8" value={this.props.stepsData.opzioni_prontocar.opt_8} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia con gancio tranciato per cricchetto PRONTOCAR nera</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_9" id="opt_9" value={this.props.stepsData.opzioni_prontocar.opt_9} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia con gancio tondo per cricchetto PRONTOCAR nera</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_10" id="opt_10" value={this.props.stepsData.opzioni_prontocar.opt_10} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia con gancio tranciato per cricchetto PRONTOCAR nera 100 cm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_11" id="opt_11" value={this.props.stepsData.opzioni_prontocar.opt_11} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Asta tenditelo alluminio d. 27 mm lunghezza 3,3 m</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_12" id="opt_12" value={this.props.stepsData.opzioni_prontocar.opt_12} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Asta tenditelo alluminio d. 34 mm lunghezza 6 m</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_13" id="opt_13" value={this.props.stepsData.opzioni_prontocar.opt_13} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno inferiore per asta d. 27 mm innesto quadro</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_14" id="opt_14" value={this.props.stepsData.opzioni_prontocar.opt_14} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno inferiore per asta d. 27 mm innesto tondo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_15" id="opt_15" value={this.props.stepsData.opzioni_prontocar.opt_15} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno superiore per asta d. 27 mm in ottone</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_16" id="opt_16" value={this.props.stepsData.opzioni_prontocar.opt_16} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno inferiore per asta d. 34 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_17" id="opt_17" value={this.props.stepsData.opzioni_prontocar.opt_17} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno superiore per asta d. 34 mm in ottone</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_18" id="opt_18" value={this.props.stepsData.opzioni_prontocar.opt_18} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno adattatore per asta da d. 34 mm a 27 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_19" id="opt_19" value={this.props.stepsData.opzioni_prontocar.opt_19} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno adattatore per asta da d. 27 mm a 34 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_20" id="opt_20" value={this.props.stepsData.opzioni_prontocar.opt_20} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno adattatore avvolgente per asta da d. 34 mm a 27 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_21" id="opt_21" value={this.props.stepsData.opzioni_prontocar.opt_21} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno avvolgente inferiore per asta d. 34 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_22" id="opt_22" value={this.props.stepsData.opzioni_prontocar.opt_22} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Tensotel da 60 mm alluminio anodizzato tipo nuovo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_23" id="opt_23" value={this.props.stepsData.opzioni_prontocar.opt_23} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Tensotel da 55 mm alluminio anodizzato tipo vecchio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_24" id="opt_24" value={this.props.stepsData.opzioni_prontocar.opt_24} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Tensotel da 60 mm alluminio anodizzato tipo vecchio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_25" id="opt_25" value={this.props.stepsData.opzioni_prontocar.opt_25} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Tensotel da 35 mm alluminio anodizzato tipo nuovo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_26" id="opt_26" value={this.props.stepsData.opzioni_prontocar.opt_26} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo a Z zincato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_27" id="opt_27" value={this.props.stepsData.opzioni_prontocar.opt_27} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Tubetto in PVC d. 10 mm lunghezza 3 m nero</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_28" id="opt_28" value={this.props.stepsData.opzioni_prontocar.opt_28} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Tubetto in PVC d. 12 mm lunghezza 3 m nero</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_29" id="opt_29" value={this.props.stepsData.opzioni_prontocar.opt_29} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Nastro Reflex adesivo argento per PVC segmentato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_30" id="opt_30" value={this.props.stepsData.opzioni_prontocar.opt_30} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Nastro Reflex adesivo giallo per PVC segmentato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_31" id="opt_31" value={this.props.stepsData.opzioni_prontocar.opt_31} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateOpzioniProntocar = (stepsData, currentStepData, currentType) => {
    let isValid = true
    //let currentStep = stepsData.opzioni_prontocar

    return isValid;
}

export default withRouter(OpzioniProntocar)
