import React from 'react';
import { withRouter } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card'

import Image from './Image.js';
import FormInput from '../Form/Input';
import FormRadio from '../Form/Radio';
import FormTextarea from '../Form/Textarea';

class CaratteristicheTeli extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['caratteristiche_teli'];

        this.state = {
            nextStep: 'materiale_laterale',
            accordionActiveElement: 0
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        // if (name.indexOf('[')>0) {
        //     let newName = name.replace(/\[(\w+)]/g, '')
        //     let key = /\[(.*?)\]/g.exec(name)[1]

        //     let newValue = this.props.stepsData[this.step.id][newName]
        //     newValue[key] = value

        //     name = newName
        //     value = newValue
        // }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    handleAccordionToggle = (eventKey) => {
        if (parseInt(eventKey)!==this.state.accordionActiveElement) {
            this.setState({
                accordionActiveElement: parseInt(eventKey)
            }, () => {
            })
        }
        else {
            this.setState({
                accordionActiveElement: null
            }, () => {
            })
        }
    }

    cardClass = (eventKey) => {
        let htmlClass = 'card'

        if (parseInt(eventKey)===parseInt(this.state.accordionActiveElement)) {
            htmlClass += ' show'
        }

        if (this.props.stepsAccordionValidation[this.step.id] && this.props.stepsAccordionValidation[this.step.id][eventKey]) {
            htmlClass += ' valid'
        }

        return htmlClass;
    }

    render() {
        return (
            <div className="step step__caratteristiche-teli">
                <div className="row justify-content-center">
                    <div className="col-24 col-lg-20">
                        <div className="text-center">
                            <Image directory="mezzo" name="mezzo" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} stepsData={this.props.stepsData} changeLaterali={true} currentLaterali={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]} />
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Card bsPrefix={this.cardClass(0)}>
                                <Toggle eventKey="0" callback={this.handleAccordionToggle}>
                                    <span>Teloni</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="row align-items-center">
                                            <div className="col-24 col-lg-6 no-form-margin-desktop">
                                                <FormInput type="number" name="teloni[quantita_sx]" id="quantita_sx" value={this.props.stepsData[this.props.currentType].caratteristiche_teli.teloni.quantita_sx} label="Quantità lato sinistro" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-lg-6 no-form-margin-desktop">
                                                <FormInput type="number" name="teloni[quantita_dx]" id="quantita_dx" value={this.props.stepsData[this.props.currentType].caratteristiche_teli.teloni.quantita_dx} label="Quantità lato destro" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="w-100"/>
                                            <div className="col-24 col-lg-12 text-center">
                                                <small>Destra o Sinistra si intendono rispetto al lato guida</small>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(1)}>
                                <Toggle eventKey="1" callback={this.handleAccordionToggle}>
                                    <span>Stampa</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-24 col-lg-16">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        Stampa Logo
                                                    </div>
                                                    <div className="col-8 no-form-margin-mobile">
                                                        <FormRadio name="stampa[logo]" id="logo_stampa_si" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].caratteristiche_teli.stampa.logo === 'Sì'} />
                                                    </div>
                                                    <div className="col-8 no-form-margin-mobile">
                                                        <FormRadio name="stampa[logo]" id="logo_stampa_no" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].caratteristiche_teli.stampa.logo === 'No'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div><br/><br/>
                                        <FormTextarea name="stampa[logo_note]" id="logo_note" value={this.props.stepsData[this.props.currentType].caratteristiche_teli.stampa.logo_note} label="Logo" placeholder="Inserisci le tue note" optional={true} handleChange={this.handleInputChange} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}

const Toggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <Card.Header onClick={decoratedOnClick}>
            {children}
        </Card.Header>
    );
}

export const ValidateCaratteristicheTeli = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].caratteristiche_teli

    if(!currentStep.teloni.quantita_sx || !currentStep.teloni.quantita_dx || !currentStep.stampa.logo) {
        isValid = false
    }

    return isValid;
}

export const ValidateCaratteristicheTeliAccordion = (stepsData, currentStepData, currentType) => {
    let isValid = [true, true]
    let currentStep = stepsData[currentType].caratteristiche_teli

    if(!currentStep.teloni.quantita_sx || !currentStep.teloni.quantita_dx) {
        isValid[0] = false
    }

    if(!currentStep.stampa.logo) {
        isValid[1] = false
    }

    return isValid;
}

export default withRouter(CaratteristicheTeli)