import React from 'react';

import Image from '../Configurator/Image.js'

const Checkbox = (props) => {

    const getImage = () => {
        if (props.image) {
            let changeColor = false
            let currentColor = false
            
            if (props.changeColor) {
                changeColor = true
                currentColor = props.stepsData[props.currentType].colore_telo.colore_telo[2]
            }


            let changeType = false
            let currentType = false
            let currentSubtype = false

            if (props.currentType) {
                changeType = true
                currentType = props.stepsData.tipologia_mezzo.tipo_mezzo
                currentSubtype = props.currentSubtype
            }

            return <Image directory={props.currentStep.id} name={props.id} changeColor={changeColor} currentColor={currentColor} currentType={currentType} currentSubtype={currentSubtype} changeType={changeType} />
        }
        else {
            return null
        }
    }

    const ChildClasses = () => {
        if (props.children && props.checked) {
            return 'form-radio__children'
        }
        else {
            return 'form-radio__children d-none'
        }
    }

    return (
        <div className={props.image ? 'form-radio form-radio--image' : 'form-radio' }>
            <div className="form-radio__control">
                <input
                    type="checkbox"
                    className="form-radio__input"
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    checked={props.checked}
                    onChange={props.handleChange}
                />
                <label className="form-radio__label" htmlFor={props.id}>
                    <div className="image">{getImage()}</div>
                    <div className="text">{props.label}</div>
                </label>
            </div>
            <div className={ChildClasses()}>
                {props.children}
            </div>
        </div>
    )
}

export default Checkbox;
