import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';

class Elastico extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['elastico'];

        this.state = {
            nextStep: 'ganci_sponda'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateNextStep = () => {
        let nextStep = 'ganci_sponda';

        if (this.props.stepsData[this.props.currentType].misure_laterale.altezza.sponda_dente==='Sì') {
            nextStep = 'tipologia_carrelli';
        }

        this.setState({
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__elastico">
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep()
                    }} />
                    <div className="row justify-content-center">
                        <div className="col-22 col-md-16 col-lg-7">
                            <FormRadio name="elastico" id="elastico1" value="Interno" label="Interno" image={true} currentStep={this.props.currentStep} stepsData={this.props.stepsData} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].elastico.elastico === 'Interno'} />
                        </div>
                        <div className="col-22 col-md-16 col-lg-7">
                            <FormRadio name="elastico" id="elastico2" value="Esterno" label="Esterno" image={true} currentStep={this.props.currentStep} stepsData={this.props.stepsData} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].elastico.elastico === 'Esterno'} />
                        </div>
                        <div className="col-22 col-md-16 col-lg-7">
                            <FormRadio name="elastico" id="elastico3" value="Ochiellatura Tir" label="Ochiellatura Tir" image={true} currentStep={this.props.currentStep} stepsData={this.props.stepsData} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].elastico.elastico === 'Ochiellatura Tir'} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateElastico = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].elastico.elastico

    if(!currentStep) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Elastico)
