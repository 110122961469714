import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormRadio from '../Form/Radio';

class TipologiaPosteriore extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['tipologia_posteriore'];

        this.state = {
            nextStep: 'opzioni'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
        this.updateNextStep(value)
    }

    updateNextStep = (value=this.props.stepsData.tipologia_posteriore.tipologia_posteriore) => {
        let nextStep = 'opzioni'

        if (value==='Retrotelo Fisso' || value==='Retrotelo Scorrevole' || value==='Planeroller' || value==='Sponda idraulica' || value==='Sistema di avvolgimento') {
            nextStep = 'retrotelo'
        }
        else if (value==='Porte Fisse') {
            nextStep = 'retroporte'
        }
        else {
            if (
                (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Semirimorchio' && this.props.stepsData['semirimorchio'].teli.teli==='Telo tetto') || 
                (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice' && this.props.stepsData['motrice'].teli.teli==='Telo tetto') ||
                (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio' && (this.props.stepsData['motrice'].teli.teli==='Telo tetto' && this.props.stepsData['rimorchio'].teli.teli==='Telo tetto'))
            ) {
                nextStep = 'note'
            }
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    render() {
        if (this.props.stepsData) {
            return (
                <div className="step step__tipologia-posteriore">
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep()
                    }} />
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="tipologia_posteriore" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-22">
                            <div className="row">
                                <div className="col-24 col-md-8 col-lg-6 offsetl-lg-1">
                                    <FormRadio name="tipologia_posteriore" id="tipologia_posteriore1" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_posteriore.tipologia_posteriore === 'No'} />
                                    <br className="d-none d-lg-inline"/>
                                </div>
                                <div className="col-24 col-md-8 col-lg-6 offsetl-lg-1">
                                    <FormRadio name="tipologia_posteriore" id="tipologia_posteriore2" value="Retrotelo Fisso" label="Retrotelo Fisso" handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_posteriore.tipologia_posteriore === 'Retrotelo Fisso'} />
                                </div>
                                <div className="col-24 col-md-8 col-lg-6 offsetl-lg-1">
                                    <FormRadio name="tipologia_posteriore" id="tipologia_posteriore3" value="Retrotelo Scorrevole" label="Retrotelo Scorrevole" handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_posteriore.tipologia_posteriore === 'Retrotelo Scorrevole'} />
                                </div>
                                <div className="col-24 col-md-8 col-lg-6 offsetl-lg-1">
                                    <FormRadio name="tipologia_posteriore" id="tipologia_posteriore4" value="Planeroller" label="Planeroller" handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_posteriore.tipologia_posteriore === 'Planeroller'} />
                                </div>
                                <div className="col-24 col-md-8 col-lg-6 offsetl-lg-1">
                                    <FormRadio name="tipologia_posteriore" id="tipologia_posteriore5" value="Porte Fisse" label="Porte Fisse" handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_posteriore.tipologia_posteriore === 'Porte Fisse'} />
                                </div>
                                <div className="col-24 col-md-8 col-lg-6 offsetl-lg-1">
                                    <FormRadio name="tipologia_posteriore" id="tipologia_posteriore6" value="Sponda idraulica" label="Sponda idraulica" handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_posteriore.tipologia_posteriore === 'Sponda idraulica'} />
                                </div>
                                <div className="col-24 col-md-8 col-lg-6 offsetl-lg-1">
                                    <FormRadio name="tipologia_posteriore" id="tipologia_posteriore7" value="Sistema di avvolgimento" label="Sistema di avvolgimento" handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_posteriore.tipologia_posteriore === 'Sistema di avvolgimento'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTipologiaPosteriore = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData.tipologia_posteriore

    if(!currentStep.tipologia_posteriore) {
        isValid = false
    }

    return isValid;
}

export default withRouter(TipologiaPosteriore)
