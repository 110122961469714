import React from 'react';

import NavItem from './NavItem'

const Nav = (props) => {
    return (
        <ul className="navigation-nav">
            <NavItem key={0} step={'tipologia_mezzo'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
            <NavItem key={99} step={'dati_cliente'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            
            {Object.entries(props.steps).map((step, i) => {
                return (
                    <NavItem key={i} step={step[1].id} type={'semirimorchio'} steps={props.steps} stepsData={props.stepsData.semirimorchio} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
                )
            })}

            <NavTitle type={'Motrice'} truckType={props.stepsData.tipologia_mezzo.tipo_mezzo} currentType={props.currentType}/>
            {Object.entries(props.steps).map((step, i) => {
                return (
                    <NavItem key={i} step={step[1].id} type={'motrice'} steps={props.steps} stepsData={props.stepsData.motrice} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
                )
            })}

            <NavTitle type={'Rimorchio'} truckType={props.stepsData.tipologia_mezzo.tipo_mezzo} currentType={props.currentType} />
            {Object.entries(props.steps).map((step, i) => {
                return (
                    <NavItem key={i} step={step[1].id} type={'rimorchio'} steps={props.steps} stepsData={props.stepsData.rimorchio} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
                )
            })}

            <NavItem key={100} step={'tipologia_posteriore'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            <NavItem key={101} step={'retrotelo'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            <NavItem key={102} step={'retroporte'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            <NavItem key={103} step={'retroporte_a'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            <NavItem key={104} step={'retroporte_b'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            <NavItem key={105} step={'opzioni'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            <NavItem key={106} step={'opzioni_prontocar'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            <NavItem key={107} step={'opzioni_rollfix_planeroller'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
            <NavItem key={108} step={'note'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
        </ul>
    )
}

const NavTitle = (props) => {
    if ( (props.truckType==='Semirimorchio' && props.type==='Semirimorchio') || (props.truckType==='Motrice + rimorchio' && props.type==='Motrice') || (props.truckType==='Motrice + rimorchio' && props.type==='Rimorchio' && props.currentType==='rimorchio') ) {
        return (
            <li className="navigation-nav__item navigation-nav__item--title">{props.type}</li>
        )
    }
    else {
        return null
    }
}

export default Nav;
