import React from 'react';
import { withRouter } from "react-router-dom";

import FormTextarea from '../Form/Textarea';

class Note extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['note'];

        this.state = {
            nextStep: 'riepilogo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData) {
            return (
                <div className="step step__opzioni">
                    <div className="row justify-content-center">
                        <div className="col-22 col-lg-12">
                            <FormTextarea name="note_aggiuntive" id="note_aggiuntive" value={this.props.stepsData.note.note_aggiuntive} label="Note Aggiuntive" placeholder="Inserisci le tue note" optional={true} handleChange={this.handleInputChange} />
                            <br/><br/>
                            <FormTextarea name="altro" id="altro" value={this.props.stepsData.note.altro} label="Altro" placeholder="Inserisci le tue note" optional={true} handleChange={this.handleInputChange} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateNote = (stepsData, currentStepData, currentType) => {
    let isValid = true
    //let currentStep = stepsData.note

    return isValid;
}

export default withRouter(Note)
