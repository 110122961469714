import React from 'react';
import { withRouter } from "react-router-dom";

import FormInput from '../Form/Input';

class Opzioni extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['opzioni'];

        this.state = {
            nextStep: 'opzioni_prontocar'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData) {
            return (
                <div className="step step__opzioni">
                    <div className="row justify-content-center">
                        <div className="col-22 col-lg-14 col-xl-12">
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavallotto rettangolare base rettangolare 2 fori alto</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_1" id="opt_1" value={this.props.stepsData.opzioni.opt_1} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavallotto rettangolare base rettangolare 2 fori basso</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_2" id="opt_2" value={this.props.stepsData.opzioni.opt_2} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavallotto sagomato a pipa</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_3" id="opt_3" value={this.props.stepsData.opzioni.opt_3} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavallotto tourniquet a molla zincato  a molla zincato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_4" id="opt_4" value={this.props.stepsData.opzioni.opt_4} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavetto TIR d. 6 mm lunghezza 22 m in acciaio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_5" id="opt_5" value={this.props.stepsData.opzioni.opt_5} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavetto TIR d. 6 mm lunghezza 28 m in acciaio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_6" id="opt_6" value={this.props.stepsData.opzioni.opt_6} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavetto TIR d. 6 mm lunghezza 34 m in acciaio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_7" id="opt_7" value={this.props.stepsData.opzioni.opt_7} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavetto TIR d. 6 mm lunghezza 36 m in acciaio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_8" id="opt_8" value={this.props.stepsData.opzioni.opt_8} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cavetto TIR d. 6 mm lunghezza 40 m in acciaio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_9" id="opt_9" value={this.props.stepsData.opzioni.opt_9} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinturino C 25 telato x tetti</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_10" id="opt_10" value={this.props.stepsData.opzioni.opt_10} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia TIR tedesca trasparente larghezza 22 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_11" id="opt_11" value={this.props.stepsData.opzioni.opt_11} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinturino per tetto lunghezza 25 cm con fibbia</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_12" id="opt_12" value={this.props.stepsData.opzioni.opt_12} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cappetta copri scopri bianca con asole in striscia</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_13" id="opt_13" value={this.props.stepsData.opzioni.opt_13} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Coprigiunto altezza 20 mm alluminio anodizzato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_14" id="opt_14" value={this.props.stepsData.opzioni.opt_14} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda treccia d. 5 mm PE bianca</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_15" id="opt_15" value={this.props.stepsData.opzioni.opt_15} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda ritorta d. 6 mm PP bianca</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_16" id="opt_16" value={this.props.stepsData.opzioni.opt_16} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda elastica d. 8 mm bianca</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_17" id="opt_17" value={this.props.stepsData.opzioni.opt_17} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda elastica d. 8 mm blu</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_18" id="opt_18" value={this.props.stepsData.opzioni.opt_18} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda elastica d. 8 mm gialla</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_19" id="opt_19" value={this.props.stepsData.opzioni.opt_19} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda elastica d. 8 mm grigia</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_20" id="opt_20" value={this.props.stepsData.opzioni.opt_20} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda elastica d. 8 mm nera</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_21" id="opt_21" value={this.props.stepsData.opzioni.opt_21} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda elastica d. 8 mm rossa</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_22" id="opt_22" value={this.props.stepsData.opzioni.opt_22} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Corda elastica d. 8 mm verde</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_23" id="opt_23" value={this.props.stepsData.opzioni.opt_23} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio a becco d'aquila zincato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_24" id="opt_24" value={this.props.stepsData.opzioni.opt_24} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio cestello d. 8 mm ferro tropicalizzato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_25" id="opt_25" value={this.props.stepsData.opzioni.opt_25} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio cobra nylon bianco</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_26" id="opt_26" value={this.props.stepsData.opzioni.opt_26} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio cobra nylon grigio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_27" id="opt_27" value={this.props.stepsData.opzioni.opt_27} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio Nylon Tondo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_28" id="opt_28" value={this.props.stepsData.opzioni.opt_28} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio a S da 45 mm zincato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_29" id="opt_29" value={this.props.stepsData.opzioni.opt_29} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio a S chiuso da 50 mm zincato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_30" id="opt_30" value={this.props.stepsData.opzioni.opt_30} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio nylon a S larga bianco</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_31" id="opt_31" value={this.props.stepsData.opzioni.opt_31} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio Zig-Zag Doppio Nylon Bianco</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_32" id="opt_32" value={this.props.stepsData.opzioni.opt_32} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio Zig-Zag Doppio Nylon Nero</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_33" id="opt_33" value={this.props.stepsData.opzioni.opt_33} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio a zig-zag grande zincato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_34" id="opt_34" value={this.props.stepsData.opzioni.opt_34} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio a zig-zag piccolo zincato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_35" id="opt_35" value={this.props.stepsData.opzioni.opt_35} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio a zig-zag chiuso in nylon bianco</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_36" id="opt_36" value={this.props.stepsData.opzioni.opt_36} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio a zig-zag aperto in nylon bianco</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_37" id="opt_37" value={this.props.stepsData.opzioni.opt_37} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Gancio a zig-zag chiuso in nylon nero</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_38" id="opt_38" value={this.props.stepsData.opzioni.opt_38} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Occhiello TIR ovale 42 x 22 mm Aluzink</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_39" id="opt_39" value={this.props.stepsData.opzioni.opt_39} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Occhiello VL 50 ottone nichel x macchina automatica</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_40" id="opt_40" value={this.props.stepsData.opzioni.opt_40} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Occhiello VL 80 ottone nichel x macchina automatica</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_41" id="opt_41" value={this.props.stepsData.opzioni.opt_41} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Occhiello VL 70 ottone x macchina automatica</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_42" id="opt_42" value={this.props.stepsData.opzioni.opt_42} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Occhiello VL 150 ferro zincato x macchina automatica</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_43" id="opt_43" value={this.props.stepsData.opzioni.opt_43} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Piatto 20 x 4 mm lunghezza 2,50 m in PVC</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_44" id="opt_44" value={this.props.stepsData.opzioni.opt_44} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Piatto 20 x 4 mm in alluminio anodizzato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_45" id="opt_45" value={this.props.stepsData.opzioni.opt_45} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Tubetto in PVC Desmopan vuoto d. 7 mm in rotolo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_46" id="opt_46" value={this.props.stepsData.opzioni.opt_46} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateOpzioni = (stepsData, currentStepData, currentType) => {
    let isValid = true
    //let currentStep = stepsData.opzioni

    return isValid;
}

export default withRouter(Opzioni)
