import React, { useState } from 'react';
import { withRouter } from "react-router-dom";

import moment from 'moment';
import Cookies from 'js-cookie';

import Offline from "react-offline";

import Nav from './Nav.js'
import PrevButton from './PrevButton';

class Riepilogo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    // Creates a sync tag called 'addQuote'
    registerBackgroundSync = async () => {
        const registration = await navigator.serviceWorker.ready
        await registration.sync.register('addQuoteQueue')
    }

    storeQuoteAndSendSignal = async (quote, serviceWorkerExists) => {
        // await this.database.quotes.add(quote)
        // console.log(await this.database.quotes.toArray())
        const { history } = this.props
        const { path } = this.props.match

        let newPath = path.replace('/riepilogo', '')

        fetch('https://rivenditori.telonitosetto.it/api/b2b/quote-save/', {
        // fetch('https://telonitosetto.loc/api/b2b/quote-save/', {
            method: 'POST',
            body: quote,
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.status==='ok') {
                    history.push(newPath + '/thankyou');
                }
                else {
                    alert(result.message)
                }
                this.setState({
                    isLoading: false
                })
            },
            (error) => {
                /* if (navigator.serviceWorker) {
                    history.push(newPath + '/thankyou')
                }
                else { */
                    alert('Si è verificato un errore, riprovare più tardi.')
                    this.setState({
                        isLoading: false
                    })
                // }
            }
        )
        
        if (serviceWorkerExists) {
            await this.registerBackgroundSync()
        }
    }

    create = async (quote) => {
        await this.useBackgroundSyncOrRequest(quote)
    }
    
    componentDidMount() {
        this.props.updateStep('riepilogo', 'Riepilogo', 'richiedi_preventivo')
    }

    render() {
        const { history } = this.props
        const { path } = this.props.match

        let newPath = path.replace('/riepilogo', '')

        const handleClick = () => {
            history.push(newPath + '/richiedi-preventivo');
        }

        const handleSubmit = (e) => {
            e.preventDefault()
    
            this.setState({
                isLoading: true
            })
    
            const data = new FormData();
    
            data.set('user_token', Cookies.get('teloni_user_token'));
            data.set('user_email', Cookies.get('teloni_user_email'));

            data.set('tipo_mezzo', this.props.stepsData.tipologia_mezzo.tipo_mezzo);
            
            data.set('azienda', this.props.stepsData.dati_cliente.dati_principali.azienda);
            data.set('referente', this.props.stepsData.dati_cliente.dati_principali.referente);
            data.set('telefono', this.props.stepsData.dati_cliente.dati_principali.telefono);
            
            data.set('targa', this.props.stepsData.dati_cliente.dati_mezzo.targa);
            data.set('marca', this.props.stepsData.dati_cliente.dati_mezzo.marca);
            data.set('modello', this.props.stepsData.dati_cliente.dati_mezzo.modello);
            data.set('anno', this.props.stepsData.dati_cliente.dati_mezzo.anno);
            
            data.set('montaggio', this.props.stepsData.dati_cliente.dati_lavorazione.montaggio);
            data.set('interno_ubicazione_p', this.props.stepsData.dati_cliente.dati_lavorazione.interno_ubicazione_p);
            data.set('esterno', this.props.stepsData.dati_cliente.dati_lavorazione.esterno);
            data.set('data_consegna', moment(this.props.stepsData.dati_cliente.dati_lavorazione.data_consegna).format("YYYY-MM-DD"));
            
            data.set('tipologia_posteriore', this.props.stepsData.tipologia_posteriore.tipologia_posteriore);
            
            data.set('retrotelo_la', this.props.stepsData.retrotelo.la);
            data.set('retrotelo_h', this.props.stepsData.retrotelo.h);

            data.set('retroporte', this.props.stepsData.retroporte.retroporte);

            data.set('retroporte_a_la', this.props.stepsData.retroporte_a.la);
            data.set('retroporte_a_l1', this.props.stepsData.retroporte_a.l1);
            data.set('retroporte_a_l2', this.props.stepsData.retroporte_a.l2);
            data.set('retroporte_a_l3', this.props.stepsData.retroporte_a.l3);
            data.set('retroporte_a_h', this.props.stepsData.retroporte_a.h);

            data.set('retroporte_b_l1', this.props.stepsData.retroporte_b.l1);
            data.set('retroporte_b_l2', this.props.stepsData.retroporte_b.l2);
            data.set('retroporte_b_h', this.props.stepsData.retroporte_b.h);

            for (let i=1; i<47; i++) {
                data.set('opzioni_opt_' + i, this.props.stepsData.opzioni['opt_' + i]);
            }

            for (let i=1; i<32; i++) {
                data.set('opzioni_prontocar_opt_' + i, this.props.stepsData.opzioni_prontocar['opt_' + i]);
            }

            for (let i=1; i<17; i++) {
                data.set('opzioni_rollfix_planeroller_opt_' + i, this.props.stepsData.opzioni_rollfix_planeroller['opt_' + i]);
            }

            data.set('note_aggiuntive', this.props.stepsData.note.note_aggiuntive);
            data.set('note_altro', this.props.stepsData.note.altro);

            let truckTypes = ['semirimorchio', 'motrice', 'rimorchio'];
            for (let i=0; i<truckTypes.length; i++) {
                let tryckType = truckTypes[i]
                
                data.set(tryckType + '_teli', this.props.stepsData[tryckType].teli.teli);

                data.set(tryckType + '_teli_laterali', this.props.stepsData[tryckType].teli_laterali.teli_laterali[0]);
                data.set(tryckType + '_teli_laterali_tipologia', this.props.stepsData[tryckType].teli_laterali.teli_laterali[1]);
                
                data.set(tryckType + '_teloni_quantita_sx', this.props.stepsData[tryckType].caratteristiche_teli.teloni.quantita_sx);
                data.set(tryckType + '_teloni_quantita_dx', this.props.stepsData[tryckType].caratteristiche_teli.teloni.quantita_dx);

                data.set(tryckType + '_stampa_logo', this.props.stepsData[tryckType].caratteristiche_teli.stampa.logo);
                data.set(tryckType + '_stampa_logo_note', this.props.stepsData[tryckType].caratteristiche_teli.stampa.logo_note);
                
                data.set(tryckType + '_mareriale', this.props.stepsData[tryckType].materiale_laterale.materiale.mareriale);
                data.set(tryckType + '_mareriale_tipologia', this.props.stepsData[tryckType].materiale_laterale.materiale.tipologia);
                
                data.set(tryckType + '_colore_fascia', this.props.stepsData[tryckType].materiale_laterale.colore.colore_fascia);
                data.set(tryckType + '_colore_telo', this.props.stepsData[tryckType].materiale_laterale.colore.colore_telo);
                
                data.set(tryckType + '_misure_laterale_h1', this.props.stepsData[tryckType].misure_laterale.altezza.h1);
                data.set(tryckType + '_misure_laterale_h2', this.props.stepsData[tryckType].misure_laterale.altezza.h2);
                data.set(tryckType + '_misure_laterale_h3', this.props.stepsData[tryckType].misure_laterale.altezza.h3);
                data.set(tryckType + '_misure_laterale_lu', this.props.stepsData[tryckType].misure_laterale.altezza.lu);
                data.set(tryckType + '_misure_laterale_altezza_variabile', this.props.stepsData[tryckType].misure_laterale.altezza.altezza_variabile);
                data.set(tryckType + '_misure_laterale_sponda_dente', this.props.stepsData[tryckType].misure_laterale.altezza.sponda_dente);
                data.set(tryckType + '_misure_laterale_quote_scansi_sx', this.props.stepsData[tryckType].misure_laterale.altezza.quote_scansi_sx);
                data.set(tryckType + '_misure_laterale_quote_scansi_dx', this.props.stepsData[tryckType].misure_laterale.altezza.quote_scansi_dx);
                
                data.set(tryckType + '_numero_piantoni', this.props.stepsData[tryckType].misure_laterale.numero_piantoni.numero_piantoni);

                data.set(tryckType + '_interasse_piantone_1', this.props.stepsData[tryckType].misure_laterale.interasse_piantone[0] ? this.props.stepsData[tryckType].misure_laterale.interasse_piantone[0] : '');
                data.set(tryckType + '_interasse_piantone_2', this.props.stepsData[tryckType].misure_laterale.interasse_piantone[1] ? this.props.stepsData[tryckType].misure_laterale.interasse_piantone[1] : '');
                data.set(tryckType + '_interasse_piantone_3', this.props.stepsData[tryckType].misure_laterale.interasse_piantone[2] ? this.props.stepsData[tryckType].misure_laterale.interasse_piantone[2] : '');
                data.set(tryckType + '_interasse_piantone_4', this.props.stepsData[tryckType].misure_laterale.interasse_piantone[3] ? this.props.stepsData[tryckType].misure_laterale.interasse_piantone[3] : '');
                data.set(tryckType + '_interasse_piantone_5', this.props.stepsData[tryckType].misure_laterale.interasse_piantone[4] ? this.props.stepsData[tryckType].misure_laterale.interasse_piantone[4] : '');
                data.set(tryckType + '_interasse_piantone_6', this.props.stepsData[tryckType].misure_laterale.interasse_piantone[5] ? this.props.stepsData[tryckType].misure_laterale.interasse_piantone[5] : '');
                data.set(tryckType + '_interasse_piantone_7', this.props.stepsData[tryckType].misure_laterale.interasse_piantone[6] ? this.props.stepsData[tryckType].misure_laterale.interasse_piantone[6] : '');
                data.set(tryckType + '_interasse_piantone_8', this.props.stepsData[tryckType].misure_laterale.interasse_piantone[7] ? this.props.stepsData[tryckType].misure_laterale.interasse_piantone[7] : '');
                
                data.set(tryckType + '_rinforzi_ra', this.props.stepsData[tryckType].misure_laterale.rinforzi.ra);
                data.set(tryckType + '_rinforzi_rp', this.props.stepsData[tryckType].misure_laterale.rinforzi.rp);
                
                data.set(tryckType + '_carrelli_cr1', this.props.stepsData[tryckType].misure_laterale.carrelli.cr1);
                data.set(tryckType + '_carrelli_cr2', this.props.stepsData[tryckType].misure_laterale.carrelli.cr2);
                
                data.set(tryckType + '_cricchetti_passo', this.props.stepsData[tryckType].misure_laterale.cricchetti.passo);
                data.set(tryckType + '_cricchetti_distanza_c1', this.props.stepsData[tryckType].misure_laterale.cricchetti.distanza_c1);
                data.set(tryckType + '_cricchetti_distanza_c2', this.props.stepsData[tryckType].misure_laterale.cricchetti.distanza_c2);
                
                data.set(tryckType + '_elastico', this.props.stepsData[tryckType].elastico.elastico);
                
                data.set(tryckType + '_cricchetti', this.props.stepsData[tryckType].cricchetti.tipologia_cricchetti[0]);
                data.set(tryckType + '_cricchetti_tipologia', this.props.stepsData[tryckType].cricchetti.tipologia_cricchetti[1]);
                data.set(tryckType + '_cricchetti_tipologia_esistenti', this.props.stepsData[tryckType].cricchetti.tipologia_cricchetti[2]);
                data.set(tryckType + '_numero_cricchetti_sx', this.props.stepsData[tryckType].cricchetti.numero_cricchetti_sx);
                data.set(tryckType + '_numero_cricchetti_dx', this.props.stepsData[tryckType].cricchetti.numero_cricchetti_dx);
                data.set(tryckType + '_colore_cricchetti', this.props.stepsData[tryckType].cricchetti.colore_cricchetti[0]);
                
                data.set(tryckType + '_numero_cinghie_orizzontali', this.props.stepsData[tryckType].tipologia_cinghie.numero_cinghie_orizzontali);
                data.set(tryckType + '_cinghie_verticali_en', this.props.stepsData[tryckType].tipologia_cinghie.cinghie_verticali_en);
                data.set(tryckType + '_cinghie_oblique_xl', this.props.stepsData[tryckType].tipologia_cinghie.cinghie_oblique_xl);

                data.set(tryckType + '_reflex', this.props.stepsData[tryckType].reflex.reflex[0]);
                
                data.set(tryckType + '_maniglie', this.props.stepsData[tryckType].maniglie.maniglie);
                
                data.set(tryckType + '_esecuzione_intermodale', this.props.stepsData[tryckType].esecuzione_intermodale.esecuzione_intermodale);
                data.set(tryckType + '_esecuzione_intermodale_ta', this.props.stepsData[tryckType].esecuzione_intermodale.ta);
                data.set(tryckType + '_esecuzione_intermodale_tp', this.props.stepsData[tryckType].esecuzione_intermodale.tp);
                
                data.set(tryckType + '_ganci_sponda_1_numero_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[0].numero_ganci);
                data.set(tryckType + '_ganci_sponda_1_passo_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[0].passo_ganci);
                data.set(tryckType + '_ganci_sponda_1_distanza_g1', this.props.stepsData[tryckType].ganci_sponda.piantone[0].distanza_g1);
                data.set(tryckType + '_ganci_sponda_1_distanza_g2', this.props.stepsData[tryckType].ganci_sponda.piantone[0].distanza_g2);
                
                data.set(tryckType + '_ganci_sponda_2_numero_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[1].numero_ganci);
                data.set(tryckType + '_ganci_sponda_2_passo_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[1].passo_ganci);
                data.set(tryckType + '_ganci_sponda_2_distanza_g1', this.props.stepsData[tryckType].ganci_sponda.piantone[1].distanza_g1);
                data.set(tryckType + '_ganci_sponda_2_distanza_g2', this.props.stepsData[tryckType].ganci_sponda.piantone[1].distanza_g2);
                
                data.set(tryckType + '_ganci_sponda_3_numero_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[2].numero_ganci);
                data.set(tryckType + '_ganci_sponda_3_passo_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[2].passo_ganci);
                data.set(tryckType + '_ganci_sponda_3_distanza_g1', this.props.stepsData[tryckType].ganci_sponda.piantone[2].distanza_g1);
                data.set(tryckType + '_ganci_sponda_3_distanza_g2', this.props.stepsData[tryckType].ganci_sponda.piantone[2].distanza_g2);
                
                data.set(tryckType + '_ganci_sponda_4_numero_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[3].numero_ganci);
                data.set(tryckType + '_ganci_sponda_4_passo_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[3].passo_ganci);
                data.set(tryckType + '_ganci_sponda_4_distanza_g1', this.props.stepsData[tryckType].ganci_sponda.piantone[3].distanza_g1);
                data.set(tryckType + '_ganci_sponda_4_distanza_g2', this.props.stepsData[tryckType].ganci_sponda.piantone[3].distanza_g2);
                
                data.set(tryckType + '_ganci_sponda_5_numero_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[4].numero_ganci);
                data.set(tryckType + '_ganci_sponda_5_passo_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[4].passo_ganci);
                data.set(tryckType + '_ganci_sponda_5_distanza_g1', this.props.stepsData[tryckType].ganci_sponda.piantone[4].distanza_g1);
                data.set(tryckType + '_ganci_sponda_5_distanza_g2', this.props.stepsData[tryckType].ganci_sponda.piantone[4].distanza_g2);
                
                data.set(tryckType + '_ganci_sponda_6_numero_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[5].numero_ganci);
                data.set(tryckType + '_ganci_sponda_6_passo_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[5].passo_ganci);
                data.set(tryckType + '_ganci_sponda_6_distanza_g1', this.props.stepsData[tryckType].ganci_sponda.piantone[5].distanza_g1);
                data.set(tryckType + '_ganci_sponda_6_distanza_g2', this.props.stepsData[tryckType].ganci_sponda.piantone[5].distanza_g2);
                
                data.set(tryckType + '_ganci_sponda_7_numero_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[6].numero_ganci);
                data.set(tryckType + '_ganci_sponda_7_passo_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[6].passo_ganci);
                data.set(tryckType + '_ganci_sponda_7_distanza_g1', this.props.stepsData[tryckType].ganci_sponda.piantone[6].distanza_g1);
                data.set(tryckType + '_ganci_sponda_7_distanza_g2', this.props.stepsData[tryckType].ganci_sponda.piantone[6].distanza_g2);
                
                data.set(tryckType + '_ganci_sponda_8_numero_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[7].numero_ganci);
                data.set(tryckType + '_ganci_sponda_8_passo_ganci', this.props.stepsData[tryckType].ganci_sponda.piantone[7].passo_ganci);
                data.set(tryckType + '_ganci_sponda_8_distanza_g1', this.props.stepsData[tryckType].ganci_sponda.piantone[7].distanza_g1);
                data.set(tryckType + '_ganci_sponda_8_distanza_g2', this.props.stepsData[tryckType].ganci_sponda.piantone[7].distanza_g2);
                
                data.set(tryckType + '_tipologia_carrelli', this.props.stepsData[tryckType].tipologia_carrelli.tipologia_carrelli);
                data.set(tryckType + '_tipologia_carrelli_esistenti', this.props.stepsData[tryckType].tipologia_carrelli.tipologia_carrelli_esistenti[0]);
                data.set(tryckType + '_tipologia_carrelli_esistenti_altro', this.props.stepsData[tryckType].tipologia_carrelli.tipologia_carrelli_esistenti[1]);
                data.set(tryckType + '_numero_carrelli_sx', this.props.stepsData[tryckType].tipologia_carrelli.numero_carrelli_sx);
                data.set(tryckType + '_numero_carrelli_dx', this.props.stepsData[tryckType].tipologia_carrelli.numero_carrelli_dx);
                data.set(tryckType + '_carrelli_particolari', this.props.stepsData[tryckType].tipologia_carrelli.carrelli_particolari);
                
                data.set(tryckType + '_tenditelo_anteriore', this.props.stepsData[tryckType].tenditelo.tenditelo_anteriore);
                data.set(tryckType + '_tenditelo_posteriore', this.props.stepsData[tryckType].tenditelo.tenditelo_posteriore);
                data.set(tryckType + '_tenditelo_tipologia_anteriore', this.props.stepsData[tryckType].tenditelo.tipologia_anteriore);
                data.set(tryckType + '_tenditelo_tipologia_posteriore', this.props.stepsData[tryckType].tenditelo.tipologia_posteriore);
                data.set(tryckType + '_tenditelo_anteriore_recupera', this.props.stepsData[tryckType].tenditelo.tenditelo_anteriore_recupera);
                data.set(tryckType + '_tenditelo_posteriore_recupera', this.props.stepsData[tryckType].tenditelo.tenditelo_posteriore_recupera);
                
                data.set(tryckType + '_rete_antivandalismo', this.props.stepsData[tryckType].rete_antivandalismo.rete_antivandalismo);
                data.set(tryckType + '_rete_antivandalismo_griglia', this.props.stepsData[tryckType].rete_antivandalismo.griglia);
                data.set(tryckType + '_rete_antivandalismo_altezza', this.props.stepsData[tryckType].rete_antivandalismo.altezza);
                
                data.set(tryckType + '_tipologia_tetto', this.props.stepsData[tryckType].tipologia_tetto.tipologia_tetto);
                data.set(tryckType + '_fasce_spalmate', this.props.stepsData[tryckType].tipologia_tetto.fasce_spalmate);
                
                data.set(tryckType + '_dimensioni_tetto_lunghezza', this.props.stepsData[tryckType].dimensioni_tetto.lunghezza);
                data.set(tryckType + '_dimensioni_tetto_larghezza', this.props.stepsData[tryckType].dimensioni_tetto.larghezza);
                
                data.set(tryckType + '_colore_tetto', this.props.stepsData[tryckType].colore_tetto.colore_tetto);
                data.set(tryckType + '_colore_tetto_colore', this.props.stepsData[tryckType].colore_tetto.colore_tetto_colore);
                
                data.set(tryckType + '_nodo', this.props.stepsData[tryckType].nodo.nodo);
                
                data.set(tryckType + '_numero_traversi', this.props.stepsData[tryckType].traversi.numero_traversi);
                data.set(tryckType + '_passo_traversi', this.props.stepsData[tryckType].traversi.passo_traversi.join());
                
                data.set(tryckType + '_numero_correnti', this.props.stepsData[tryckType].correnti.numero_correnti);
                data.set(tryckType + '_passo_correnti', this.props.stepsData[tryckType].correnti.passo_correnti.join());

                data.set(tryckType + '_numero_archi', this.props.stepsData[tryckType].archi.numero_archi);
                data.set(tryckType + '_passo_archi', this.props.stepsData[tryckType].archi.passo_archi);
                data.set(tryckType + '_archi_distanza_a1', this.props.stepsData[tryckType].archi.distanza_a1);
                data.set(tryckType + '_archi_distanza_a2', this.props.stepsData[tryckType].archi.distanza_a2);
                data.set(tryckType + '_archi_distanza_a3', this.props.stepsData[tryckType].archi.distanza_a3);
                data.set(tryckType + '_archi_distanza_a4', this.props.stepsData[tryckType].archi.distanza_a4);
                data.set(tryckType + '_archi_note_archi', this.props.stepsData[tryckType].archi.note_archi);
                data.set(tryckType + '_archi_rinforzo_ra', this.props.stepsData[tryckType].archi.rinforzo_ra);
                data.set(tryckType + '_archi_rinforzo_rp', this.props.stepsData[tryckType].archi.rinforzo_rp);
                
                data.set(tryckType + '_file_cinturini', this.props.stepsData[tryckType].cinturini.file_cinturini);
                
                data.set(tryckType + '_altezza_frontale', this.props.stepsData[tryckType].fronte_tetto.altezza_frontale);
                data.set(tryckType + '_scansi_anteriori_doppi', this.props.stepsData[tryckType].fronte_tetto.scansi_anteriori_doppi);
                
                data.set(tryckType + '_spalla_tetto_d', this.props.stepsData[tryckType].spalla_tetto.d);
                data.set(tryckType + '_spalla_tetto_e', this.props.stepsData[tryckType].spalla_tetto.e);
                data.set(tryckType + '_spalla_tetto_f', this.props.stepsData[tryckType].spalla_tetto.f);
                data.set(tryckType + '_spalla_tetto_g', this.props.stepsData[tryckType].spalla_tetto.g);
                data.set(tryckType + '_spalla_tetto_g1g2', this.props.stepsData[tryckType].spalla_tetto.g1g2);
                
                data.set(tryckType + '_occhielli_numero', this.props.stepsData[tryckType].occhielli.numero);
                data.set(tryckType + '_occhielli_disposizione', this.props.stepsData[tryckType].occhielli.disposizione);
                data.set(tryckType + '_occhielli_quota', this.props.stepsData[tryckType].occhielli.quota);
                data.set(tryckType + '_occhielli_interasse', this.props.stepsData[tryckType].occhielli.interasse);
                data.set(tryckType + '_occhielli_passo_ultimo', this.props.stepsData[tryckType].occhielli.passo_ultimo);
                
                data.set(tryckType + '_sgocciolatoio', this.props.stepsData[tryckType].sgocciolatoio.sgocciolatoio);

            }
            
            const serviceWorkerExists = navigator.serviceWorker
            
            // Creates quote with background sync only if the browser supports service workers.
            this.storeQuoteAndSendSignal(stringifyFormData(data, serviceWorkerExists))
        }

        return (
            <div className="riepilogo">
                <div className="container-fluid">
                    <div className="riepilogo__header riepilogo__header--desktop d-none d-md-block">
                        <div className="row">
                            <div className="col-22 offset-1 text-md-center">
                                <svg className="svg-icon svg-icon--logo" viewBox="0 0 598 223" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                    <use xlinkHref="#svg-icon__logo"></use>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="riepilogo__header riepilogo__header--mobile d-md-none">
                        <div className="row">
                            <div className="col-22 offset-1">
                                <svg className="svg-icon svg-icon--logo-symbol-monocolor" viewBox="0 0 35 43" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                    <use xlinkHref={"#svg-icon__logo-symbol-monocolor"}></use>
                                </svg>
                                <Navigation steps={this.props.steps} stepsData={this.props.stepsData} currentStep={this.props.currentStep} stepsValidation={this.props.stepsValidation} handleClick={handleClick} />
                            </div>
                        </div>
                    </div>

                    <div className="riepilogo__content">
                        <div className="row justify-content-center">
                            <div className="col-24 col-md-14 col-lg-12 col-xl-10">
                                <div className="ripilogo_preferenze">
                                    <h1>Ecco il riepilogo della  configurazione!</h1>
                                    <div className="d-none d-md-block">
                                        <Nav steps={this.props.steps} stepsData={this.props.stepsData} currentStep={this.props.currentStep} currentType={this.props.currentType} stepsValidation={this.props.stepsValidation} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                <div className="configurator__footer">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-6 col-lg-7 offset-1">
                                <PrevButton currentStep={this.props.currentStep} currentType={this.props.currentType} updateStep={this.props.updateStep} />
                            </div>
                            <div className="col-16 col-lg-8 text-right text-lg-center">
                                <Offline
                                    render={({ isOffline, isOnline }) => {
                                        if (isOffline) {
                                            /* if (navigator.serviceWorker) {
                                                return <button onClick={handleSubmit} className="btn btn-primary btn-lg btn-uppercase btn-apply" disabled={this.state.isLoading}>
                                                    Salva e inoltra configurazione
                                                </button>
                                            }
                                            else { */
                                                return <button onClick={handleSubmit} className="btn btn-primary btn-lg btn-uppercase btn-apply" disabled={true}>
                                                    Salva e inoltra configurazione
                                                </button>
                                            // }
                                        }
                                        else {
                                            return <button onClick={handleSubmit} className="btn btn-primary btn-lg btn-uppercase btn-apply" disabled={this.state.isLoading}>
                                                Salva e inoltra configurazione
                                            </button>
                                        }
                                    }}
                                />
                                {this.state.isLoading && (
                                    <div className="spinner-border" role="status" style={{ verticalAlign: 'middle', marginLeft: '15px' }}>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

const Navigation = (props) => {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        if(!show) {
            setShow(true)
        }
        else {
            setShow(false)
        }
    };

    return (
        <div className="riepilogo-navigation">
            <button className="riepilogo-navigation__toggle" onClick={handleShow}>
                <span>Riguarda preferenze</span>
                <svg className="svg-icon svg-icon--list" viewBox="0 0 23 15" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                    <use xlinkHref="#svg-icon__list"></use>
                </svg>
            </button>
            <div className={show ? "riepilogo-navigation__content _shown" : "riepilogo-navigation__content"} onClick={handleShow}>
                <div className="riepilogo-navigation__header">
                    <div className="row no-gutters">
                        <div className="col-22 offset-1">
                            <svg className="svg-icon svg-icon--logo-symbol" viewBox="0 0 35 43" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                <use xlinkHref={"#svg-icon__logo-symbol"}></use>
                            </svg>
                            <button className="riepilogo-navigation__toggle" onClick={handleShow}>
                                <span>Riguarda preferenze</span>
                                <svg className="svg-icon svg-icon--close" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                    <use xlinkHref="#svg-icon__close"></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="riepilogo-navigation__body">
                    <div className="row no-gutters">
                        <div className="col-20 offset-2">
                            <span className="riepilogo-navigation__title">Ecco le tue preferenze riassunte:</span>
                            <Nav steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return JSON.stringify(data, null, 2);
}

export default withRouter(Riepilogo);
