import React from 'react';

const Textarea = (props) => {
    const getLabel = () => {
        let label = props.label

        if(props.optional) {
            label += ' <span>(facoltativo)</span>'
        }

        return label
    }

    return (
        <div className={props.value ? 'form-input form-input--textarea form-input--filled' : 'form-input form-input--textarea' }>
            <label htmlFor={props.id} className="form-input__label" dangerouslySetInnerHTML={{__html: getLabel()}}/>
            <textarea
                className="form-input__control form-control"
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
                placeholder={props.placeholder}
            />
        </div>
    )
}

export default Textarea;
