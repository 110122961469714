import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';

class Correnti extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['correnti'];

        this.state = {
            nextStep: 'fronte_tetto'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    printImage = () => {
        if (this.props.stepsData[this.props.currentType].tipologia_tetto.tipologia_tetto==='Tetto fisso') {
            return (
                <Image directory="traversi_correnti" name="tipologia_tetto1" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} stepsData={this.props.stepsData} />
            )
        }
    }

    passoCorrenti = () => {
        let badgeList = []
        
        if (this.props.stepsData[this.props.currentType].correnti.numero_correnti>1 && this.props.stepsData[this.props.currentType].correnti.numero_correnti<7) {
            for (let i=0; i<this.props.stepsData[this.props.currentType].correnti.numero_correnti; i++) {
                badgeList.push(<div className="col-24 col-md-8 col-lg-6 col-xl-4 col-xxl-3" key={i}><FormInput type="number" name={"passo_correnti[" + i + "]"} id={"passo_" + i} value={this.props.stepsData[this.props.currentType].correnti.passo_correnti[i]} label={i+1} handleChange={this.handleInputChange} /></div>)
            }
        }

        return badgeList
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__correnti">
                    <div className="row">
                        <div className="col-20 offset-2 col-lg-14 offset-lg-5">
                            <div className="step__image">
                                {this.printImage()}
                            </div>
                        </div>
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__form">
                                <div className="row justify-content-center">
                                    <div className="col-24 col-lg-10 col-xl-8 offset-lg-0">
                                        <FormInput type="number" name="numero_correnti" id="numero_correnti" value={this.props.stepsData[this.props.currentType].correnti.numero_correnti} label="N° Correnti" handleChange={this.handleInputChange} />
                                        <br/>
                                    </div>
                                    <div className={this.props.stepsData[this.props.currentType].correnti.numero_correnti>1 && this.props.stepsData[this.props.currentType].correnti.numero_correnti<7 ? "col-24" : "d-none"}>
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-24 col-xl-auto text-md-center text-lg-center">
                                                <span className="form-label" style={ {display: 'inline-block', marginBottom: '15px'} }>Passo Correnti da davanti (mm)</span>
                                                <br className="d-none d-md-inline d-lg-inline d-xl-none"/>
                                            </div>
                                            {this.passoCorrenti()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateCorrenti = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].correnti

    if(!currentStep.numero_correnti) {
        isValid = false
    }
    else {
        for (let i=0; i<parseInt(currentStep.numero_correnti); i++) {
            if (!currentStep.passo_correnti[i]) {
                isValid = false
            }
        }
    }

    return isValid;
}

export default withRouter(Correnti)
