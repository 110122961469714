import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormRadio from '../Form/Radio';

class Sgocciolatoio extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['sgocciolatoio'];

        this.state = {
            nextStep: 'tipologia_posteriore'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateNextStep = (teli) => {
        let nextStep = 'tipologia_posteriore'

        if (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio' && this.props.currentType==='motrice') {
            nextStep = 'teli'
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__traversi-correnti">
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep()
                    }} />
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="sgocciolatoio" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-22 col-md-8 col-lg-6 offset-1 offset-md-0">
                            <FormRadio name="sgocciolatoio" id="sgocciolatoio1" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].sgocciolatoio.sgocciolatoio === 'Sì'} />
                        </div>
                        <div className="col-22 col-md-8 col-lg-6 offset-1">
                            <FormRadio name="sgocciolatoio" id="sgocciolatoio2" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].sgocciolatoio.sgocciolatoio === 'No'} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateSgocciolatoio = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].sgocciolatoio

    if(!currentStep.sgocciolatoio) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Sgocciolatoio)
