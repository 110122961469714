import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormRadio from '../Form/Radio';
import FormRadioColor from '../Form/RadioColor';

import ReactTooltip from "react-tooltip";

class Reflex extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['reflex'];

        this.state = {
            nextStep: 'maniglie'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        if (name==='reflex') {
            value = [value, target.getAttribute('data-hex')]
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__reflex">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="reflex" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center bottom-fields">
                        <div className="col-20 col-lg-auto text-center text-lg-right">
                            <span className="bottom-fields__fake-label d-block d-lg-inline">Colore Reflex</span>

                            <span data-tip data-for="tip_colore_rexlez-001">
                            <FormRadioColor name="reflex" id="reflex1" value="Silver" label="Silver" hex="#ECECEC" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].reflex.reflex[0] === 'Silver'} />
                            <ReactTooltip id="tip_colore_rexlez-001" place="top" effect="solid">
                                Silver
                            </ReactTooltip>
                            </span>

                            <span data-tip data-for="tip_colore_rexlez-002">
                            <FormRadioColor name="reflex" id="reflex2" value="Giallo" label="Giallo" hex="#ffb633" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].reflex.reflex[0] === 'Giallo'} />
                            <ReactTooltip id="tip_colore_rexlez-002" place="top" effect="solid">
                                Giallo
                            </ReactTooltip>
                            </span>

                        </div>
                        <div className="col-auto offset-lg-1 bottom-fields__separator d-none d-lg-flex">
                            <span></span>
                        </div>
                        <div className="col-20 col-md-8 col-lg-4 offset-lg-1">
                            <FormRadio name="reflex" id="reflex4" value="Senza Reflex" label="Senza Reflex" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].reflex.reflex[0] === 'Senza Reflex'} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateReflex = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].reflex

    if(!currentStep.reflex[0]) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Reflex)
